import React from 'react';
import { useAuthStore } from '../../store/authStore';
import { LegalPageLayout } from '../../components/legal/LegalPageLayout';

export function ForumRules() {
  const { user } = useAuthStore();
  const language = user?.preferredLanguage?.toLowerCase() || 'fr';

  const content = {
    fr: {
      title: "Règles du Forum",
      intro: "Bienvenue sur le forum du conseil de surveillance de la SCI Club Expo 1. Cet espace est réservé aux échanges privés entre les associés. Afin de garantir une ambiance conviviale et respectueuse, nous vous invitons à respecter les règles suivantes :",
      sections: [
        {
          title: "1. Nature Privée des Échanges",
          rules: [
            { title: "Confidentialité des Messages", content: "Tous les messages du forum sont privés. Ils sont accessibles uniquement aux associés autorisés." },
            { title: "Réponses en Messages Privés", content: "Toutes les réponses aux publications se font exclusivement via des messages privés entre les utilisateurs." }
          ]
        },
        {
          title: "2. Respect et Courtoisie",
          rules: [
            { title: "Politesse", content: "Adoptez un langage courtois et respectueux envers tous les membres." },
            { title: "Interdictions", content: "Les propos injurieux, offensants, discriminatoires ou diffamatoires sont strictement interdits." },
            { title: "Tolérance", content: "Respectez les opinions et les perspectives différentes des vôtres." }
          ]
        },
        {
          title: "3. Contenu des Messages",
          rules: [
            { title: "Pertinence", content: "Veillez à ce que vos messages soient en lien avec le sujet initial." },
            { title: "Clarté", content: "Rédigez des messages clairs et concis pour faciliter la compréhension." },
            { title: "Langage Approprié", content: "L'utilisation d'un langage vulgaire ou obscène est prohibée." }
          ]
        },
        {
          title: "4. Confidentialité et Données Personnelles",
          rules: [
            { title: "Respect de la Vie Privée", content: "Ne divulguez pas d'informations personnelles sur vous-même ou sur d'autres personnes sans leur consentement explicite." },
            { title: "Informations Confidentielles", content: "Il est interdit de partager des documents ou informations internes à la SCI sans autorisation préalable." }
          ]
        },
        {
          title: "5. Propriété Intellectuelle",
          rules: [
            { title: "Droits d'Auteur", content: "Ne publiez que du contenu dont vous êtes l'auteur ou pour lequel vous avez obtenu les droits nécessaires." },
            { title: "Citations", content: "Si vous partagez des extraits de textes ou des images, mentionnez systématiquement les sources." }
          ]
        },
        {
          title: "6. Modération",
          rules: [
            { title: "Respect des Modérateurs", content: "Les décisions des modérateurs doivent être respectées." },
            { title: "Droit de Non-Publication", content: "La modération se réserve le droit de ne pas publier un message s'il ne respecte pas les règles du forum." },
            { title: "Signalement", content: "Si vous constatez un contenu inapproprié, utilisez la fonction de signalement plutôt que de répondre directement." },
            { title: "Sanctions", content: "Le non-respect des règles peut entraîner des avertissements, la suppression de messages, voire la suspension du compte." }
          ]
        },
        {
          title: "7. Responsabilité",
          rules: [
            { title: "Contenu Publié", content: "Vous êtes responsable des messages que vous publiez et des conséquences juridiques potentielles." },
            { title: "Véracité des Informations", content: "Ne diffusez pas de fausses informations ou des rumeurs infondées." }
          ]
        },
        {
          title: "8. Utilisation des Fonctionnalités du Forum",
          rules: [
            { title: "Messages Privés", content: "Utilisez les messages privés pour les communications personnelles ou hors sujet." },
            { title: "Organisation", content: "Postez vos messages dans les sections appropriées du forum." }
          ]
        },
        {
          title: "9. Langue du Forum",
          rules: [
            { title: "Langue", content: "Le français, l'italien et l'anglais sont les langues officielles du forum. Veuillez rédiger vos messages en français, en italien ou en anglais pour une meilleure compréhension de tous." }
          ]
        },
        {
          title: "10. Sécurité",
          rules: [
            { title: "Liens Externes", content: "Soyez prudent avec les liens externes et assurez-vous qu'ils ne mènent pas à des sites malveillants ou inappropriés." },
            { title: "Protection des Accès", content: "Ne partagez pas vos identifiants de connexion et veillez à la sécurité de votre compte." }
          ]
        },
        {
          title: "11. Évolution des Règles",
          rules: [
            { title: "Mises à Jour", content: "Les présentes règles peuvent être modifiées. Il est de votre responsabilité de vous tenir informé des éventuels changements." },
            { title: "Acceptation", content: "L'utilisation du forum implique l'acceptation sans réserve de ces règles." }
          ]
        }
      ],
      conclusion: "En publiant un message sur ce forum, vous confirmez avoir lu et accepté les présentes règles et vous vous engagez à les respecter.",
      signature: {
        text: "Cordialement,",
        team: "L'équipe Kingstoring Consulting"
      }
    },
    en: {
      title: "Forum Rules",
      intro: "Welcome to the SCI Club Expo 1 supervisory board forum. This space is reserved for private exchanges between associates. To ensure a friendly and respectful atmosphere, we invite you to follow these rules:",
      sections: [
        {
          title: "1. Private Nature of Exchanges",
          rules: [
            { title: "Message Confidentiality", content: "All forum messages are private. They are accessible only to authorized associates." },
            { title: "Private Message Responses", content: "All responses to posts are made exclusively via private messages between users." }
          ]
        },
        {
          title: "2. Respect and Courtesy",
          rules: [
            { title: "Politeness", content: "Use courteous and respectful language towards all members." },
            { title: "Prohibitions", content: "Abusive, offensive, discriminatory, or defamatory comments are strictly prohibited." },
            { title: "Tolerance", content: "Respect opinions and perspectives different from your own." }
          ]
        },
        {
          title: "3. Message Content",
          rules: [
            { title: "Relevance", content: "Ensure your messages are related to the initial topic." },
            { title: "Clarity", content: "Write clear and concise messages to facilitate understanding." },
            { title: "Appropriate Language", content: "The use of vulgar or obscene language is prohibited." }
          ]
        },
        {
          title: "4. Confidentiality and Personal Data",
          rules: [
            { title: "Privacy Respect", content: "Do not disclose personal information about yourself or others without explicit consent." },
            { title: "Confidential Information", content: "Sharing internal SCI documents or information without prior authorization is prohibited." }
          ]
        },
        {
          title: "5. Intellectual Property",
          rules: [
            { title: "Copyright", content: "Only publish content that you own or for which you have obtained necessary rights." },
            { title: "Citations", content: "If you share text excerpts or images, always cite sources." }
          ]
        },
        {
          title: "6. Moderation",
          rules: [
            { title: "Respect for Moderators", content: "Moderators' decisions must be respected." },
            { title: "Right of Non-Publication", content: "Moderation reserves the right not to publish messages that do not comply with forum rules." },
            { title: "Reporting", content: "If you notice inappropriate content, use the reporting function rather than responding directly." },
            { title: "Sanctions", content: "Non-compliance with rules may result in warnings, message deletion, or account suspension." }
          ]
        },
        {
          title: "7. Responsibility",
          rules: [
            { title: "Published Content", content: "You are responsible for messages you publish and their potential legal consequences." },
            { title: "Information Accuracy", content: "Do not spread false information or unfounded rumors." }
          ]
        },
        {
          title: "8. Forum Features Usage",
          rules: [
            { title: "Private Messages", content: "Use private messages for personal or off-topic communications." },
            { title: "Organization", content: "Post your messages in appropriate forum sections." }
          ]
        },
        {
          title: "9. Forum Language",
          rules: [
            { title: "Language", content: "French, Italian, and English are the official forum languages. Please write your messages in French, Italian, or English for better understanding by all." }
          ]
        },
        {
          title: "10. Security",
          rules: [
            { title: "External Links", content: "Be cautious with external links and ensure they don't lead to malicious or inappropriate sites." },
            { title: "Access Protection", content: "Do not share your login credentials and ensure your account security." }
          ]
        },
        {
          title: "11. Rules Evolution",
          rules: [
            { title: "Updates", content: "These rules may be modified. It is your responsibility to stay informed of any changes." },
            { title: "Acceptance", content: "Using the forum implies unreserved acceptance of these rules." }
          ]
        }
      ],
      conclusion: "By posting a message on this forum, you confirm that you have read and accepted these rules and agree to comply with them.",
      signature: {
        text: "Best regards,",
        team: "The Kingstoring Consulting Team"
      }
    },
    it: {
      title: "Regole del Forum",
      intro: "Benvenuti nel forum del consiglio di sorveglianza di SCI Club Expo 1. Questo spazio è riservato agli scambi privati tra gli associati. Per garantire un'atmosfera cordiale e rispettosa, vi invitiamo a rispettare le seguenti regole:",
      sections: [
        {
          title: "1. Natura Privata degli Scambi",
          rules: [
            { title: "Riservatezza dei Messaggi", content: "Tutti i messaggi del forum sono privati. Sono accessibili solo agli associati autorizzati." },
            { title: "Risposte in Messaggi Privati", content: "Tutte le risposte ai post vengono effettuate esclusivamente tramite messaggi privati tra gli utenti." }
          ]
        },
        {
          title: "2. Rispetto e Cortesia",
          rules: [
            { title: "Educazione", content: "Utilizzare un linguaggio cortese e rispettoso verso tutti i membri." },
            { title: "Divieti", content: "I commenti offensivi, discriminatori o diffamatori sono severamente vietati." },
            { title: "Tolleranza", content: "Rispettare le opinioni e le prospettive diverse dalle proprie." }
          ]
        },
        {
          title: "3. Contenuto dei Messaggi",
          rules: [
            { title: "Pertinenza", content: "Assicurarsi che i messaggi siano correlati all'argomento iniziale." },
            { title: "Chiarezza", content: "Scrivere messaggi chiari e concisi per facilitare la comprensione." },
            { title: "Linguaggio Appropriato", content: "L'uso di linguaggio volgare o osceno è vietato." }
          ]
        },
        {
          title: "4. Riservatezza e Dati Personali",
          rules: [
            { title: "Rispetto della Privacy", content: "Non divulgare informazioni personali su se stessi o altri senza consenso esplicito." },
            { title: "Informazioni Riservate", content: "È vietato condividere documenti o informazioni interne della SCI senza previa autorizzazione." }
          ]
        },
        {
          title: "5. Proprietà Intellettuale",
          rules: [
            { title: "Diritti d'Autore", content: "Pubblicare solo contenuti di cui si è proprietari o per i quali si sono ottenuti i diritti necessari." },
            { title: "Citazioni", content: "Se si condividono estratti di testo o immagini, citare sempre le fonti." }
          ]
        },
        {
          title: "6. Moderazione",
          rules: [
            { title: "Rispetto dei Moderatori", content: "Le decisioni dei moderatori devono essere rispettate." },
            { title: "Diritto di Non Pubblicazione", content: "La moderazione si riserva il diritto di non pubblicare messaggi che non rispettano le regole del forum." },
            { title: "Segnalazione", content: "Se si nota contenuto inappropriato, utilizzare la funzione di segnalazione anziché rispondere direttamente." },
            { title: "Sanzioni", content: "Il mancato rispetto delle regole può comportare avvertimenti, cancellazione dei messaggi o sospensione dell'account." }
          ]
        },
        {
          title: "7. Responsabilità",
          rules: [
            { title: "Contenuto Pubblicato", content: "Sei responsabile dei messaggi che pubblichi e delle loro potenziali conseguenze legali." },
            { title: "Veridicità delle Informazioni", content: "Non diffondere informazioni false o voci infondate." }
          ]
        },
        {
          title: "8. Utilizzo delle Funzionalità del Forum",
          rules: [
            { title: "Messaggi Privati", content: "Utilizzare i messaggi privati per comunicazioni personali o fuori tema." },
            { title: "Organizzazione", content: "Pubblicare i messaggi nelle sezioni appropriate del forum." }
          ]
        },
        {
          title: "9. Lingua del Forum",
          rules: [
            { title: "Lingua", content: "Francese, italiano e inglese sono le lingue ufficiali del forum. Si prega di scrivere i messaggi in francese, italiano o inglese per una migliore comprensione da parte di tutti." }
          ]
        },
        {
          title: "10. Sicurezza",
          rules: [
            { title: "Link Esterni", content: "Prestare attenzione ai link esterni e assicurarsi che non conducano a siti dannosi o inappropriati." },
            { title: "Protezione degli Accessi", content: "Non condividere le credenziali di accesso e garantire la sicurezza del proprio account." }
          ]
        },
        {
          title: "11. Evoluzione delle Regole",
          rules: [
            { title: "Aggiornamenti", content: "Queste regole possono essere modificate. È tua responsabilità rimanere informato su eventuali modifiche." },
            { title: "Accettazione", content: "L'utilizzo del forum implica l'accettazione incondizionata di queste regole." }
          ]
        }
      ],
      conclusion: "Pubblicando un messaggio su questo forum, confermi di aver letto e accettato queste regole e accetti di rispettarle.",
      signature: {
        text: "Cordialmente,",
        team: "Il Team Kingstoring Consulting"
      }
    }
  };

  return (
    <LegalPageLayout>
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">
          {content[language].title}
        </h1>

        <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-8">
          <p className="text-blue-700">
            {content[language].intro}
          </p>
        </div>

        <div className="space-y-8">
          {content[language].sections.map((section, index) => (
            <section key={index} className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                {section.title}
              </h2>
              <div className="space-y-4">
                {section.rules.map((rule, ruleIndex) => (
                  <div key={ruleIndex} className="pl-4 border-l-4 border-blue-100">
                    <h3 className="font-medium text-gray-900 mb-1">
                      {rule.title}
                    </h3>
                    <p className="text-gray-600">
                      {rule.content}
                    </p>
                  </div>
                ))}
              </div>
            </section>
          ))}
        </div>

        <div className="mt-8 bg-blue-50 rounded-lg p-6">
          <p className="text-gray-700 mb-4">
            {content[language].conclusion}
          </p>
          <div className="text-gray-600">
            <p>{content[language].signature.text}</p>
            <p className="font-medium">{content[language].signature.team}</p>
          </div>
        </div>
      </div>
    </LegalPageLayout>
  );
}

export default ForumRules;