import { z } from 'zod';

export const registerSchema = z.object({
  firstName: z.string().min(2, 'Prénom trop court'),
  lastName: z.string().min(2, 'Nom trop court'),
  email: z.string().email('Email invalide'),
  password: z.string().min(6, 'Mot de passe trop court'),
  phone: z.string().min(10, 'Numéro de téléphone invalide'),
  address: z.string().min(5, 'Adresse requise'),
  postalCode: z.string().min(5, 'Code postal invalide'),
  city: z.string().min(2, 'Ville requise'),
  country: z.string().min(2, 'Pays requis'),
  preferredLanguage: z.enum(['FR', 'EN', 'IT']),
  inviteCode: z.string().min(1, 'Code d\'invitation requis'),
  acceptTerms: z.boolean().refine((val) => val === true, {
    message: 'Vous devez accepter les conditions'
  })
});

export type RegisterForm = z.infer<typeof registerSchema>;