import React, { useState } from 'react';
import { useAuthStore } from '../../store/authStore';
import { LegalPageLayout } from '../../components/legal/LegalPageLayout';
import { Shield, Mail, Lock, AlertTriangle, FileText, ChevronDown } from 'lucide-react';
import { clsx } from 'clsx';

export function PrivacyPolicy() {
  const { user } = useAuthStore();
  const language = user?.preferredLanguage?.toLowerCase() || 'fr';
  const [expandedSections, setExpandedSections] = useState<number[]>([]);

  const toggleSection = (index: number) => {
    if (expandedSections.includes(index)) {
      setExpandedSections(expandedSections.filter(i => i !== index));
    } else {
      setExpandedSections([...expandedSections, index]);
    }
  };

  const content = {
    fr: {
      title: "Protection et Confidentialité des Données",
      intro: "Chez SAS Kingstoring, nous prenons très au sérieux la protection de vos données personnelles. Cette page détaille nos engagements en matière de protection des données et notre politique de confidentialité conformément au Règlement Général sur la Protection des Données (RGPD).",
      sections: [
        {
          title: "1. Vos Droits et Comment les Exercer",
          icon: Shield,
          content: [
            {
              subtitle: "Droits Fondamentaux",
              items: [
                "Droit d'accès à vos données personnelles",
                "Droit de rectification des informations inexactes",
                "Droit à l'effacement de vos données",
                "Droit à la limitation du traitement",
                "Droit à la portabilité des données",
                "Droit d'opposition au traitement"
              ]
            },
            {
              subtitle: "Comment Exercer vos Droits",
              text: "Pour exercer l'un de ces droits, contactez-nous à contact@kingstoring.com. Nous traiterons votre demande dans un délai maximum d'un mois."
            }
          ]
        },
        {
          title: "2. Collecte et Utilisation des Données",
          icon: FileText,
          content: [
            {
              subtitle: "Données Collectées",
              items: [
                "Informations d'identification (nom, prénom, email)",
                "Données de connexion et navigation",
                "Préférences et paramètres utilisateur",
                "Communications avec notre service"
              ]
            },
            {
              subtitle: "Finalités du Traitement",
              items: [
                "Gestion de votre compte utilisateur",
                "Personnalisation de votre expérience",
                "Amélioration de nos services",
                "Respect des obligations légales"
              ]
            }
          ]
        },
        {
          title: "3. Sécurité et Protection",
          icon: Lock,
          content: [
            {
              subtitle: "Mesures de Sécurité",
              items: [
                "Chiffrement des données sensibles",
                "Protocoles de sécurité avancés",
                "Accès restreint aux données personnelles",
                "Surveillance continue des systèmes"
              ]
            }
          ]
        },
        {
          title: "4. Signalement d'Incidents",
          icon: AlertTriangle,
          content: [
            {
              text: "Si vous suspectez une violation de données, contactez immédiatement notre équipe à contact@kingstoring.com"
            }
          ]
        }
      ],
      contact: {
        title: "Contact DPO",
        name: "Responsable de la Protection des Données",
        company: "SAS Kingstoring",
        address: "20 Avenue Jean Medecin, 06000 Nice",
        email: "contact@kingstoring.com"
      }
    },
    en: {
      title: "Data Protection and Privacy",
      intro: "At SAS Kingstoring, we take the protection of your personal data very seriously. This page details our commitments regarding data protection and our privacy policy in accordance with the General Data Protection Regulation (GDPR).",
      sections: [
        {
          title: "1. Your Rights and How to Exercise Them",
          icon: Shield,
          content: [
            {
              subtitle: "Fundamental Rights",
              items: [
                "Right to access your personal data",
                "Right to rectify inaccurate information",
                "Right to erasure of your data",
                "Right to restriction of processing",
                "Right to data portability",
                "Right to object to processing"
              ]
            },
            {
              subtitle: "How to Exercise Your Rights",
              text: "To exercise any of these rights, contact us at contact@kingstoring.com. We will process your request within a maximum of one month."
            }
          ]
        },
        {
          title: "2. Data Collection and Use",
          icon: FileText,
          content: [
            {
              subtitle: "Collected Data",
              items: [
                "Identification information (name, email)",
                "Connection and navigation data",
                "User preferences and settings",
                "Communications with our service"
              ]
            },
            {
              subtitle: "Processing Purposes",
              items: [
                "User account management",
                "Experience personalization",
                "Service improvement",
                "Legal compliance"
              ]
            }
          ]
        },
        {
          title: "3. Security and Protection",
          icon: Lock,
          content: [
            {
              subtitle: "Security Measures",
              items: [
                "Sensitive data encryption",
                "Advanced security protocols",
                "Restricted access to personal data",
                "Continuous system monitoring"
              ]
            }
          ]
        },
        {
          title: "4. Incident Reporting",
          icon: AlertTriangle,
          content: [
            {
              text: "If you suspect a data breach, immediately contact our team at contact@kingstoring.com"
            }
          ]
        }
      ],
      contact: {
        title: "DPO Contact",
        name: "Data Protection Officer",
        company: "SAS Kingstoring",
        address: "20 Avenue Jean Medecin, 06000 Nice",
        email: "contact@kingstoring.com"
      }
    },
    it: {
      title: "Protezione e Privacy dei Dati",
      intro: "In SAS Kingstoring, prendiamo molto seriamente la protezione dei tuoi dati personali. Questa pagina descrive i nostri impegni in materia di protezione dei dati e la nostra politica sulla privacy in conformità con il Regolamento Generale sulla Protezione dei Dati (GDPR).",
      sections: [
        {
          title: "1. I Tuoi Diritti e Come Esercitarli",
          icon: Shield,
          content: [
            {
              subtitle: "Diritti Fondamentali",
              items: [
                "Diritto di accesso ai tuoi dati personali",
                "Diritto di rettifica delle informazioni inesatte",
                "Diritto alla cancellazione dei tuoi dati",
                "Diritto alla limitazione del trattamento",
                "Diritto alla portabilità dei dati",
                "Diritto di opposizione al trattamento"
              ]
            },
            {
              subtitle: "Come Esercitare i Tuoi Diritti",
              text: "Per esercitare uno di questi diritti, contattaci a contact@kingstoring.com. Elaboreremo la tua richiesta entro un massimo di un mese."
            }
          ]
        },
        {
          title: "2. Raccolta e Utilizzo dei Dati",
          icon: FileText,
          content: [
            {
              subtitle: "Dati Raccolti",
              items: [
                "Informazioni di identificazione (nome, email)",
                "Dati di connessione e navigazione",
                "Preferenze e impostazioni utente",
                "Comunicazioni con il nostro servizio"
              ]
            },
            {
              subtitle: "Finalità del Trattamento",
              items: [
                "Gestione dell'account utente",
                "Personalizzazione dell'esperienza",
                "Miglioramento dei servizi",
                "Conformità legale"
              ]
            }
          ]
        },
        {
          title: "3. Sicurezza e Protezione",
          icon: Lock,
          content: [
            {
              subtitle: "Misure di Sicurezza",
              items: [
                "Crittografia dei dati sensibili",
                "Protocolli di sicurezza avanzati",
                "Accesso limitato ai dati personali",
                "Monitoraggio continuo dei sistemi"
              ]
            }
          ]
        },
        {
          title: "4. Segnalazione Incidenti",
          icon: AlertTriangle,
          content: [
            {
              text: "Se sospetti una violazione dei dati, contatta immediatamente il nostro team a contact@kingstoring.com"
            }
          ]
        }
      ],
      contact: {
        title: "Contatto DPO",
        name: "Responsabile della Protezione dei Dati",
        company: "SAS Kingstoring",
        address: "20 Avenue Jean Medecin, 06000 Nice",
        email: "contact@kingstoring.com"
      }
    }
  };

  return (
    <LegalPageLayout>
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">
          {content[language].title}
        </h1>

        <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-8">
          <p className="text-blue-700">
            {content[language].intro}
          </p>
        </div>

        <div className="space-y-6">
          {content[language].sections.map((section, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-sm overflow-hidden"
            >
              <button
                onClick={() => toggleSection(index)}
                className="w-full px-6 py-4 flex items-center justify-between bg-gray-50 hover:bg-gray-100 transition-colors"
              >
                <div className="flex items-center">
                  <section.icon className="h-5 w-5 text-blue-500 mr-3" />
                  <h2 className="text-lg font-semibold text-gray-900">
                    {section.title}
                  </h2>
                </div>
                <ChevronDown
                  className={clsx(
                    "h-5 w-5 text-gray-500 transition-transform",
                    expandedSections.includes(index) && "transform rotate-180"
                  )}
                />
              </button>

              {expandedSections.includes(index) && (
                <div className="px-6 py-4">
                  {section.content.map((contentItem, contentIndex) => (
                    <div key={contentIndex} className="mb-4 last:mb-0">
                      {contentItem.subtitle && (
                        <h3 className="text-md font-medium text-gray-900 mb-2">
                          {contentItem.subtitle}
                        </h3>
                      )}
                      {contentItem.text && (
                        <p className="text-gray-600 mb-2">{contentItem.text}</p>
                      )}
                      {contentItem.items && (
                        <ul className="list-disc pl-5 space-y-1 text-gray-600">
                          {contentItem.items.map((item, itemIndex) => (
                            <li key={itemIndex}>{item}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mt-8 bg-gray-50 rounded-lg p-6">
          <div className="flex items-center mb-4">
            <Mail className="h-5 w-5 text-blue-500 mr-2" />
            <h2 className="text-lg font-medium text-gray-900">
              {content[language].contact.title}
            </h2>
          </div>
          <div className="space-y-2 text-gray-600">
            <p className="font-medium">{content[language].contact.name}</p>
            <p>{content[language].contact.company}</p>
            <p>{content[language].contact.address}</p>
            <p>Email: {content[language].contact.email}</p>
          </div>
        </div>
      </div>
    </LegalPageLayout>
  );
}

export default PrivacyPolicy;