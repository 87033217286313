import React from 'react';
import { Euro, MessageSquare, ArrowLeftRight } from 'lucide-react';
import { PostType } from '../../types/post';
import { useAuthStore } from '../../store/authStore';

interface PostTypeSelectorProps {
  onSelect: (type: PostType) => void;
  onClose: () => void;
}

const translations = {
  fr: {
    title: 'Choisir le type de publication',
    types: {
      vente_parts: 'Proposer ses parts',
      achat_parts: 'Acheter des parts',
      echange_semaines: 'Échanger des semaines',
      question: 'Question',
      discussion: 'Discussion générale'
    }
  },
  en: {
    title: 'Choose post type',
    types: {
      vente_parts: 'Sell shares',
      achat_parts: 'Buy shares',
      echange_semaines: 'Exchange weeks',
      question: 'Question',
      discussion: 'General discussion'
    }
  },
  it: {
    title: 'Scegli il tipo di pubblicazione',
    types: {
      vente_parts: 'Vendere quote',
      achat_parts: 'Acquistare quote',
      echange_semaines: 'Scambiare settimane',
      question: 'Domanda',
      discussion: 'Discussione generale'
    }
  }
};

export function PostTypeSelector({ onSelect, onClose }: PostTypeSelectorProps) {
  const { user } = useAuthStore();
  const userLanguage = user?.preferredLanguage?.toLowerCase() || 'fr';
  const t = translations[userLanguage];

  const postTypes = [
    {
      type: 'vente_parts' as PostType,
      label: t.types.vente_parts,
      icon: Euro,
      color: 'text-blue-500'
    },
    {
      type: 'achat_parts' as PostType,
      label: t.types.achat_parts,
      icon: Euro,
      color: 'text-green-500'
    },
    {
      type: 'echange_semaines' as PostType,
      label: t.types.echange_semaines,
      icon: ArrowLeftRight,
      color: 'text-purple-500'
    },
    {
      type: 'question' as PostType,
      label: t.types.question,
      icon: MessageSquare,
      color: 'text-yellow-500'
    },
    {
      type: 'discussion' as PostType,
      label: t.types.discussion,
      icon: MessageSquare,
      color: 'text-gray-500'
    }
  ];

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={onClose}>
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        <div 
          className="inline-block w-full max-w-sm p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
          onClick={e => e.stopPropagation()}
        >
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            {t.title}
          </h3>
          <div className="space-y-3">
            {postTypes.map(({ type, label, icon: Icon, color }) => (
              <button
                key={type}
                onClick={() => onSelect(type)}
                className="w-full flex items-center justify-between p-3 text-left rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="flex items-center">
                  <Icon className={`h-5 w-5 ${color} mr-3`} />
                  <span>{label}</span>
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}