export const councilTranslations = {
  fr: {
    title: "Conseil de surveillance",
    subtitle: "Les membres du conseil de surveillance de SCI Club Expo",
    contact: "Contacter",
    lastSeen: "Vu",
    roles: {
      president: "Président du conseil",
      vicePresident: "Vice-président",
      secretary: "Secrétaire",
      treasurer: "Trésorier",
      member: "Membre du conseil"
    },
    online: "En ligne",
    offline: "Hors ligne"
  },
  en: {
    title: "Supervisory Board",
    subtitle: "SCI Club Expo Supervisory Board Members",
    contact: "Contact",
    lastSeen: "Last seen",
    roles: {
      president: "Board President",
      vicePresident: "Vice President",
      secretary: "Secretary",
      treasurer: "Treasurer",
      member: "Board Member"
    },
    online: "Online",
    offline: "Offline"
  },
  it: {
    title: "Consiglio di Sorveglianza",
    subtitle: "Membri del Consiglio di Sorveglianza di SCI Club Expo",
    contact: "Contatta",
    lastSeen: "Visto",
    roles: {
      president: "Presidente del Consiglio",
      vicePresident: "Vice Presidente",
      secretary: "Segretario",
      treasurer: "Tesoriere",
      member: "Membro del Consiglio"
    },
    online: "Online",
    offline: "Offline"
  }
};