import React from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { FileText, Download } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface Document {
  id: string;
  name: string;
  category: string;
  uploadedBy: string;
  uploadedAt: Date;
  size: number;
}

interface RecentDocumentsProps {
  documents: Document[];
}

const formatFileSize = (bytes: number) => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

export function RecentDocuments({ documents }: RecentDocumentsProps) {
  const navigate = useNavigate();

  return (
    <div className="bg-white shadow rounded-lg">
      <div className="p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <FileText className="h-5 w-5 text-gray-400 mr-2" />
          Documents récents
        </h2>
        <div className="space-y-4">
          {documents.map((doc) => (
            <div key={doc.id} className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div className="flex-shrink-0">
                  <FileText className="h-8 w-8 text-gray-400" />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-900">
                    {doc.name}
                  </p>
                  <div className="flex items-center space-x-2 text-xs text-gray-500">
                    <span>{formatFileSize(doc.size)}</span>
                    <span>•</span>
                    <span>{format(doc.uploadedAt, 'dd MMM', { locale: fr })}</span>
                  </div>
                </div>
              </div>
              <button
                onClick={() => {}} // Handle download
                className="text-gray-400 hover:text-gray-500"
              >
                <Download className="h-5 w-5" />
              </button>
            </div>
          ))}
        </div>
        <div className="mt-6">
          <button
            onClick={() => navigate('/documents')}
            className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Voir tous les documents
          </button>
        </div>
      </div>
    </div>
  );
}