import React from 'react';
import { format } from 'date-fns';
import { MessageSquare, Eye } from 'lucide-react';
import { clsx } from 'clsx';
import { useTranslation } from '../../hooks/useTranslation';
import type { Post } from '../../types/post';

interface PostCardProps {
  post: Post;
  onView: (post: Post) => void;
  onContact: (authorId: string) => void;
  currentUserId: string;
  t: any;
  dateLocale: Locale;
}

export function PostCard({ post, onView, onContact, currentUserId, t, dateLocale }: PostCardProps) {
  const { getTranslatedContent } = useTranslation();
  const translatedContent = getTranslatedContent(post);

  const getTypeColor = (type: string) => {
    switch (type) {
      case 'vente_parts':
        return { bg: 'bg-green-100', text: 'text-green-800', icon: 'text-green-600' };
      case 'achat_parts':
        return { bg: 'bg-blue-100', text: 'text-blue-800', icon: 'text-blue-600' };
      case 'echange_semaines':
        return { bg: 'bg-purple-100', text: 'text-purple-800', icon: 'text-purple-600' };
      case 'question':
        return { bg: 'bg-yellow-100', text: 'text-yellow-800', icon: 'text-yellow-600' };
      default:
        return { bg: 'bg-gray-100', text: 'text-gray-800', icon: 'text-gray-600' };
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 overflow-hidden border border-gray-100">
      <div className="p-6 flex flex-col h-full">
        <div className="flex items-center justify-between mb-4">
          <span className={clsx(
            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
            getTypeColor(post.type).bg,
            getTypeColor(post.type).text
          )}>
            {t.types[post.type]}
          </span>
          <span className="text-sm text-gray-500">
            {format(post.createdAt, 'dd MMM yyyy', { locale: dateLocale })}
          </span>
        </div>

        <h3 className="text-lg font-medium text-gray-900 mb-2 line-clamp-2">
          {translatedContent.title}
        </h3>

        <p className="text-gray-600 line-clamp-3 flex-grow mb-4 whitespace-pre-line">
          {translatedContent.content}
        </p>

        <div className="pt-4 border-t border-gray-100">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img
                className="h-8 w-8 rounded-full"
                src={`https://ui-avatars.com/api/?name=${post.authorName}&background=random`}
                alt=""
              />
              <span className="ml-2 text-sm text-gray-500">
                {post.authorName}
              </span>
            </div>

            <div className="flex space-x-4">
              {post.authorId !== currentUserId && (
                <button
                  onClick={() => onContact(post.authorId)}
                  className="inline-flex items-center text-sm text-gray-500 hover:text-blue-600 transition-colors duration-200"
                >
                  <MessageSquare className="h-4 w-4 mr-1" />
                  <span className="hidden sm:inline">{t.contact}</span>
                </button>
              )}
              <button
                onClick={() => onView(post)}
                className="inline-flex items-center text-sm text-gray-500 hover:text-blue-600 transition-colors duration-200"
              >
                <Eye className="h-4 w-4 mr-1" />
                <span className="hidden sm:inline">{t.details}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}