import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, addDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { 
  Key,
  Search,
  Filter,
  Plus,
  Check,
  X,
  AlertTriangle,
  Copy,
  RefreshCw,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import { clsx } from 'clsx';

interface InviteCode {
  id: string;
  code: string;
  createdBy: string;
  createdAt: Date;
  expiryDate: Date;
  used: boolean;
  usedBy?: string;
  usedAt?: Date;
  disabled?: boolean;
}

export function InvitationCodes() {
  const [codes, setCodes] = useState<InviteCode[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState<'all' | 'active' | 'used' | 'expired'>('all');
  const [loading, setLoading] = useState(true);
  const [generatingCode, setGeneratingCode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const codesPerPage = 10;

  useEffect(() => {
    const q = query(
      collection(db, 'invitation_codes'),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const codesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        expiryDate: doc.data().expiryDate?.toDate(),
        usedAt: doc.data().usedAt?.toDate(),
      })) as InviteCode[];
      setCodes(codesData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const generateInviteCode = async () => {
    try {
      setGeneratingCode(true);
      const code = Math.random().toString(36).substring(2, 8).toUpperCase();
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 30); // Code valide 30 jours

      await addDoc(collection(db, 'invitation_codes'), {
        code,
        createdBy: 'ADMIN',
        createdAt: new Date(),
        expiryDate,
        used: false,
        disabled: false
      });
    } catch (error) {
      console.error('Erreur lors de la génération du code:', error);
    } finally {
      setGeneratingCode(false);
    }
  };

  const toggleCodeStatus = async (codeId: string, disabled: boolean) => {
    try {
      await updateDoc(doc(db, 'invitation_codes', codeId), {
        disabled
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut:', error);
    }
  };

  const copyToClipboard = (code: string) => {
    navigator.clipboard.writeText(code);
  };

  const filteredCodes = codes.filter(code => {
    const matchesSearch = code.code.toLowerCase().includes(searchQuery.toLowerCase());
    const now = new Date();

    switch (filter) {
      case 'active':
        return !code.used && !code.disabled && code.expiryDate > now && matchesSearch;
      case 'used':
        return code.used && matchesSearch;
      case 'expired':
        return (code.expiryDate < now || code.disabled) && matchesSearch;
      default:
        return matchesSearch;
    }
  });

  // Pagination
  const indexOfLastCode = currentPage * codesPerPage;
  const indexOfFirstCode = indexOfLastCode - codesPerPage;
  const currentCodes = filteredCodes.slice(indexOfFirstCode, indexOfLastCode);
  const totalPages = Math.ceil(filteredCodes.length / codesPerPage);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">Codes d'invitation</h2>
          <p className="mt-1 text-sm text-gray-500">
            Gérez les codes d'invitation pour les nouveaux membres
          </p>
        </div>
        <button
          onClick={generateInviteCode}
          disabled={generatingCode}
          className="mt-4 sm:mt-0 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
        >
          {generatingCode ? (
            <RefreshCw className="animate-spin h-5 w-5 mr-2" />
          ) : (
            <Key className="h-5 w-5 mr-2" />
          )}
          Générer un nouveau code
        </button>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Rechercher
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="Rechercher un code..."
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Filtrer par statut
            </label>
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value as any)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
              <option value="all">Tous les codes</option>
              <option value="active">Codes actifs</option>
              <option value="used">Codes utilisés</option>
              <option value="expired">Codes expirés/désactivés</option>
            </select>
          </div>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Code
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Statut
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Créé le
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Expire le
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Utilisé par
              </th>
              <th scope="col" className="relative px-6 py-3">
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentCodes.map((code) => {
              const isExpired = new Date() > code.expiryDate;
              return (
                <tr key={code.id} className={clsx(
                  "hover:bg-gray-50",
                  code.disabled && "bg-gray-50"
                )}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <span className="font-mono text-sm">{code.code}</span>
                      <button
                        onClick={() => copyToClipboard(code.code)}
                        className="ml-2 text-gray-400 hover:text-gray-600"
                      >
                        <Copy className="h-4 w-4" />
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={clsx(
                      "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
                      code.used
                        ? "bg-green-100 text-green-800"
                        : code.disabled
                        ? "bg-gray-100 text-gray-800"
                        : isExpired
                        ? "bg-red-100 text-red-800"
                        : "bg-blue-100 text-blue-800"
                    )}>
                      {code.used
                        ? "Utilisé"
                        : code.disabled
                        ? "Désactivé"
                        : isExpired
                        ? "Expiré"
                        : "Actif"}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {format(code.createdAt, 'dd/MM/yyyy HH:mm', { locale: fr })}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {format(code.expiryDate, 'dd/MM/yyyy HH:mm', { locale: fr })}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {code.usedBy || '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    {!code.used && (
                      <button
                        onClick={() => toggleCodeStatus(code.id, !code.disabled)}
                        className={clsx(
                          "inline-flex items-center px-3 py-1 rounded-md text-sm",
                          code.disabled
                            ? "text-green-700 bg-green-100 hover:bg-green-200"
                            : "text-red-700 bg-red-100 hover:bg-red-200"
                        )}
                      >
                        {code.disabled ? (
                          <>
                            <Check className="h-4 w-4 mr-1" />
                            Activer
                          </>
                        ) : (
                          <>
                            <X className="h-4 w-4 mr-1" />
                            Désactiver
                          </>
                        )}
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
              >
                Précédent
              </button>
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
              >
                Suivant
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Affichage de{' '}
                  <span className="font-medium">{indexOfFirstCode + 1}</span>
                  {' '}à{' '}
                  <span className="font-medium">
                    {Math.min(indexOfLastCode, filteredCodes.length)}
                  </span>
                  {' '}sur{' '}
                  <span className="font-medium">{filteredCodes.length}</span>
                  {' '}résultats
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                  <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                  >
                    <span className="sr-only">Précédent</span>
                    <ChevronLeft className="h-5 w-5" />
                  </button>
                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index}
                      onClick={() => paginate(index + 1)}
                      className={clsx(
                        "relative inline-flex items-center px-4 py-2 border text-sm font-medium",
                        currentPage === index + 1
                          ? "z-10 bg-blue-50 border-blue-500 text-blue-600"
                          : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                      )}
                    >
                      {index + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                  >
                    <span className="sr-only">Suivant</span>
                    <ChevronRight className="h-5 w-5" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default InvitationCodes;