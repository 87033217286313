export const dashboardTranslations = {
  fr: {
    welcome: {
      title: "Bienvenue",
      description: "Voici un aperçu de l'activité récente de la SCI Club Expo"
    },
    stats: {
      activeMembers: "Membres actifs",
      posts: "Publications",
      documents: "Documents",
      responseTime: "Temps de réponse",
      minutes: "min"
    },
    quickActions: {
      title: "Actions rapides",
      newPost: "Nouvelle publication",
      addDocument: "Ajouter un document",
      sendMessage: "Envoyer un message"
    },
    recentPosts: {
      title: "Publications récentes",
      viewAll: "Voir toutes les publications",
      noPost: "Aucune publication récente",
      categories: {
        general: "Général",
        technical: "Technique",
        administrative: "Administratif"
      }
    },
    recentDocuments: {
      title: "Documents récents",
      viewAll: "Voir tous les documents",
      noDocument: "Aucun document récent"
    },
    councilMembers: {
      title: "Conseil de surveillance",
      viewAll: "Voir tous les membres",
      noMember: "Aucun membre du conseil",
      contact: "Contacter"
    }
  },
  en: {
    welcome: {
      title: "Welcome",
      description: "Here's an overview of recent SCI Club Expo activity"
    },
    stats: {
      activeMembers: "Active Members",
      posts: "Posts",
      documents: "Documents",
      responseTime: "Response Time",
      minutes: "min"
    },
    quickActions: {
      title: "Quick Actions",
      newPost: "New Post",
      addDocument: "Add Document",
      sendMessage: "Send Message"
    },
    recentPosts: {
      title: "Recent Posts",
      viewAll: "View all posts",
      noPost: "No recent posts",
      categories: {
        general: "General",
        technical: "Technical",
        administrative: "Administrative"
      }
    },
    recentDocuments: {
      title: "Recent Documents",
      viewAll: "View all documents",
      noDocument: "No recent documents"
    },
    councilMembers: {
      title: "Supervisory Board",
      viewAll: "View all members",
      noMember: "No board members",
      contact: "Contact"
    }
  },
  it: {
    welcome: {
      title: "Benvenuto",
      description: "Ecco una panoramica dell'attività recente di SCI Club Expo"
    },
    stats: {
      activeMembers: "Membri Attivi",
      posts: "Pubblicazioni",
      documents: "Documenti",
      responseTime: "Tempo di Risposta",
      minutes: "min"
    },
    quickActions: {
      title: "Azioni Rapide",
      newPost: "Nuova Pubblicazione",
      addDocument: "Aggiungi Documento",
      sendMessage: "Invia Messaggio"
    },
    recentPosts: {
      title: "Pubblicazioni Recenti",
      viewAll: "Vedi tutte le pubblicazioni",
      noPost: "Nessuna pubblicazione recente",
      categories: {
        general: "Generale",
        technical: "Tecnico",
        administrative: "Amministrativo"
      }
    },
    recentDocuments: {
      title: "Documenti Recenti",
      viewAll: "Vedi tutti i documenti",
      noDocument: "Nessun documento recente"
    },
    councilMembers: {
      title: "Consiglio di Sorveglianza",
      viewAll: "Vedi tutti i membri",
      noMember: "Nessun membro del consiglio",
      contact: "Contatta"
    }
  }
};