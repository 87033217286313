import React from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuthStore } from '../../store/authStore';

interface LanguageSelectorProps {
  user: any;
}

export function LanguageSelector({ user }: LanguageSelectorProps) {
  const { setUser } = useAuthStore();

  const handleLanguageChange = async (language: string) => {
    try {
      if (!user?.id) return;

      const userRef = doc(db, 'users', user.id);
      await updateDoc(userRef, {
        preferredLanguage: language,
        updatedAt: new Date()
      });

      setUser({
        ...user,
        preferredLanguage: language as 'FR' | 'EN' | 'IT'
      });
    } catch (error) {
      console.error('Error updating language preference:', error);
    }
  };

  return (
    <div className="px-4 mt-4">
      <select
        value={user?.preferredLanguage || 'FR'}
        onChange={(e) => handleLanguageChange(e.target.value)}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
      >
        <option value="FR">🇫🇷 Français</option>
        <option value="EN">🇬🇧 English</option>
        <option value="IT">🇮🇹 Italiano</option>
      </select>
    </div>
  );
}