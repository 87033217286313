import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { collection, addDoc, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthStore } from '../store/authStore';
import { translationService } from '../utils/translationService';
import { 
  HelpCircle,
  Mail,
  Phone,
  MessageSquare,
  Calendar,
  Check,
  ChevronDown,
  ChevronUp,
  Globe,
  AlertCircle
} from 'lucide-react';

const contactSchema = z.object({
  subject: z.string().min(3, 'Le sujet doit contenir au moins 3 caractères'),
  message: z.string().min(10, 'Le message doit contenir au moins 10 caractères'),
  priority: z.enum(['low', 'medium', 'high']),
});

type ContactForm = z.infer<typeof contactSchema>;

interface SupportTicket {
  id: string;
  subject: string;
  message: string;
  priority: 'low' | 'medium' | 'high';
  status: 'pending' | 'in_progress' | 'resolved';
  userId: string;
  userName: string;
  userEmail: string;
  createdAt: Date;
  response?: string;
  respondedAt?: Date;
  respondedBy?: string;
  translations?: {
    [key: string]: {
      subject: string;
      message: string;
      response?: string;
    }
  };
}

const translations = {
  fr: {
    title: "Assistance",
    subtitle: "Trouvez des réponses à vos questions ou contactez-nous pour obtenir de l'aide",
    tabs: {
      faq: "FAQ",
      contact: "Nous contacter",
      tickets: "Mes demandes"
    },
    faq: {
      title: "Questions fréquentes",
      noQuestions: "Aucune question fréquente disponible"
    },
    contact: {
      title: "Nous contacter",
      subject: "Sujet",
      message: "Message",
      priority: {
        label: "Priorité",
        low: "Basse",
        medium: "Moyenne",
        high: "Haute"
      },
      submit: "Envoyer",
      success: "Message envoyé avec succès",
      error: "Erreur lors de l'envoi du message"
    },
    tickets: {
      title: "Mes demandes",
      noTickets: "Aucune demande d'assistance",
      status: {
        pending: "En attente",
        in_progress: "En cours",
        resolved: "Résolu"
      },
      priority: {
        low: "Basse",
        medium: "Moyenne",
        high: "Haute"
      }
    }
  },
  en: {
    title: "Support",
    subtitle: "Find answers to your questions or contact us for help",
    tabs: {
      faq: "FAQ",
      contact: "Contact Us",
      tickets: "My Tickets"
    },
    faq: {
      title: "Frequently Asked Questions",
      noQuestions: "No FAQ available"
    },
    contact: {
      title: "Contact Us",
      subject: "Subject",
      message: "Message",
      priority: {
        label: "Priority",
        low: "Low",
        medium: "Medium",
        high: "High"
      },
      submit: "Send",
      success: "Message sent successfully",
      error: "Error sending message"
    },
    tickets: {
      title: "My Tickets",
      noTickets: "No support tickets",
      status: {
        pending: "Pending",
        in_progress: "In Progress",
        resolved: "Resolved"
      },
      priority: {
        low: "Low",
        medium: "Medium",
        high: "High"
      }
    }
  },
  it: {
    title: "Assistenza",
    subtitle: "Trova risposte alle tue domande o contattaci per ricevere aiuto",
    tabs: {
      faq: "FAQ",
      contact: "Contattaci",
      tickets: "Le mie richieste"
    },
    faq: {
      title: "Domande frequenti",
      noQuestions: "Nessuna FAQ disponibile"
    },
    contact: {
      title: "Contattaci",
      subject: "Oggetto",
      message: "Messaggio",
      priority: {
        label: "Priorità",
        low: "Bassa",
        medium: "Media",
        high: "Alta"
      },
      submit: "Invia",
      success: "Messaggio inviato con successo",
      error: "Errore durante l'invio del messaggio"
    },
    tickets: {
      title: "Le mie richieste",
      noTickets: "Nessuna richiesta di assistenza",
      status: {
        pending: "In attesa",
        in_progress: "In corso",
        resolved: "Risolto"
      },
      priority: {
        low: "Bassa",
        medium: "Media",
        high: "Alta"
      }
    }
  }
};

export function Assistance() {
  const { user } = useAuthStore();
  const [openFaqIndex, setOpenFaqIndex] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState<'faq' | 'contact' | 'tickets'>('faq');
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [tickets, setTickets] = useState<SupportTicket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<SupportTicket | null>(null);
  const [faqItems, setFaqItems] = useState<any[]>([]);

  const userLanguage = user?.preferredLanguage?.toLowerCase() || 'fr';
  const t = translations[userLanguage];

  useEffect(() => {
    const fetchFaqItems = async () => {
      const q = query(collection(db, 'faq_items'), orderBy('order'));
      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const items = await Promise.all(snapshot.docs.map(async (doc) => {
          const data = doc.data();
          let question = data.question;
          let answer = data.answer;

          // Translate if needed
          if (!data.translations?.[userLanguage]) {
            question = await translationService.translateText(data.question, userLanguage);
            answer = await translationService.translateText(data.answer, userLanguage);
          } else {
            question = data.translations[userLanguage].question;
            answer = data.translations[userLanguage].answer;
          }

          return {
            id: doc.id,
            question,
            answer
          };
        }));
        setFaqItems(items);
      });

      return () => unsubscribe();
    };

    fetchFaqItems();
  }, [userLanguage]);

  useEffect(() => {
    if (!user?.id) return;

    const q = query(
      collection(db, 'support_tickets'),
      where('userId', '==', user.id),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const ticketsData = await Promise.all(snapshot.docs.map(async (doc) => {
        const data = doc.data();
        let subject = data.subject;
        let message = data.message;
        let response = data.response;

        // Translate if needed
        if (!data.translations?.[userLanguage]) {
          subject = await translationService.translateText(data.subject, userLanguage);
          message = await translationService.translateText(data.message, userLanguage);
          if (response) {
            response = await translationService.translateText(response, userLanguage);
          }
        } else {
          subject = data.translations[userLanguage].subject;
          message = data.translations[userLanguage].message;
          response = data.translations[userLanguage].response;
        }

        return {
          id: doc.id,
          ...data,
          subject,
          message,
          response,
          createdAt: data.createdAt?.toDate(),
          respondedAt: data.respondedAt?.toDate()
        };
      }));

      setTickets(ticketsData);
    });

    return () => unsubscribe();
  }, [user?.id, userLanguage]);

  const { register, handleSubmit, reset, formState: { errors } } = useForm<ContactForm>({
    resolver: zodResolver(contactSchema),
    defaultValues: {
      priority: 'medium'
    }
  });

  const onSubmit = async (data: ContactForm) => {
    if (!user) return;

    try {
      setSubmitStatus('loading');
      
      // Create translations for all supported languages
      const translations = {};
      const languages = ['fr', 'en', 'it'];
      
      await Promise.all(
        languages.map(async (lang) => {
          translations[lang] = {
            subject: await translationService.translateText(data.subject, lang),
            message: await translationService.translateText(data.message, lang)
          };
        })
      );

      await addDoc(collection(db, 'support_tickets'), {
        ...data,
        userId: user.id,
        userName: `${user.firstName} ${user.lastName}`,
        userEmail: user.email,
        status: 'pending',
        createdAt: new Date(),
        translations
      });

      setSubmitStatus('success');
      reset();
      setActiveTab('tickets');
      setTimeout(() => setSubmitStatus('idle'), 3000);
    } catch (error) {
      console.error('Error submitting support ticket:', error);
      setSubmitStatus('error');
      setTimeout(() => setSubmitStatus('idle'), 3000);
    }
  };

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      <div>
        <h1 className="text-2xl font-semibold text-gray-900">{t.title}</h1>
        <p className="mt-1 text-sm text-gray-600">
          {t.subtitle}
        </p>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8">
          <button
            onClick={() => setActiveTab('faq')}
            className={`${
              activeTab === 'faq'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } flex items-center whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            <HelpCircle className="h-5 w-5 mr-2" />
            {t.tabs.faq}
          </button>
          <button
            onClick={() => setActiveTab('contact')}
            className={`${
              activeTab === 'contact'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } flex items-center whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            <MessageSquare className="h-5 w-5 mr-2" />
            {t.tabs.contact}
          </button>
          <button
            onClick={() => setActiveTab('tickets')}
            className={`${
              activeTab === 'tickets'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } flex items-center whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            <Mail className="h-5 w-5 mr-2" />
            {t.tabs.tickets}
            {tickets.length > 0 && (
              <span className="ml-2 px-2 py-0.5 text-xs font-medium rounded-full bg-blue-100 text-blue-600">
                {tickets.length}
              </span>
            )}
          </button>
        </nav>
      </div>

      {/* FAQ */}
      {activeTab === 'faq' && (
        <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
          {faqItems.length === 0 ? (
            <div className="px-4 py-6 text-center text-sm text-gray-500">
              {t.faq.noQuestions}
            </div>
          ) : (
            faqItems.map((item, index) => (
              <div key={index} className="p-4">
                <button
                  onClick={() => setOpenFaqIndex(openFaqIndex === index ? null : index)}
                  className="flex justify-between items-center w-full text-left"
                >
                  <span className="text-sm font-medium text-gray-900">{item.question}</span>
                  {openFaqIndex === index ? (
                    <ChevronUp className="h-5 w-5 text-gray-500" />
                  ) : (
                    <ChevronDown className="h-5 w-5 text-gray-500" />
                  )}
                </button>
                {openFaqIndex === index && (
                  <p className="mt-2 text-sm text-gray-500">{item.answer}</p>
                )}
              </div>
            ))
          )}
        </div>
      )}

      {/* Contact Form */}
      {activeTab === 'contact' && (
        <div className="bg-white shadow rounded-lg p-6">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t.contact.subject}
              </label>
              <input
                type="text"
                {...register('subject')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {errors.subject && (
                <p className="mt-1 text-sm text-red-600">{errors.subject.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t.contact.priority.label}
              </label>
              <select
                {...register('priority')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="low">{t.contact.priority.low}</option>
                <option value="medium">{t.contact.priority.medium}</option>
                <option value="high">{t.contact.priority.high}</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t.contact.message}
              </label>
              <textarea
                {...register('message')}
                rows={4}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {errors.message && (
                <p className="mt-1 text-sm text-red-600">{errors.message.message}</p>
              )}
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={submitStatus === 'loading'}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {submitStatus === 'loading' ? (
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                ) : (
                  <>
                    <Mail className="h-5 w-5 mr-2" />
                    {t.contact.submit}
                  </>
                )}
              </button>
            </div>
          </form>

          {submitStatus === 'success' && (
            <div className="mt-4 rounded-md bg-green-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <Check className="h-5 w-5 text-green-400" />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-green-800">
                    {t.contact.success}
                  </p>
                </div>
              </div>
            </div>
          )}

          {submitStatus === 'error' && (
            <div className="mt-4 rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-red-800">
                    {t.contact.error}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Tickets List */}
      {activeTab === 'tickets' && (
        <div className="space-y-4">
          {tickets.length === 0 ? (
            <div className="text-center py-12 bg-white shadow rounded-lg">
              <Mail className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                {t.tickets.noTickets}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {t.tickets.noTickets}
              </p>
              <button
                onClick={() => setActiveTab('contact')}
                className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                <MessageSquare className="h-5 w-5 mr-2" />
                {t.tabs.contact}
              </button>
            </div>
          ) : (
            <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
              {tickets.map((ticket) => (
                <div key={ticket.id} className="p-6">
                  <div className="flex flex-col space-y-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <h3 className="text-lg font-medium text-gray-900">
                          {ticket.subject}
                        </h3>
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          ticket.status === 'resolved' ? 'bg-green-100 text-green-800' :
                          ticket.status === 'in_progress' ? 'bg-yellow-100 text-yellow-800' :
                          'bg-gray-100 text-gray-800'
                        }`}>
                          {t.tickets.status[ticket.status]}
                        </span>
                      </div>
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        ticket.priority === 'high' ? 'bg-red-100 text-red-800' :
                        ticket.priority === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-green-100 text-green-800'
                      }`}>
                        {t.tickets.priority[ticket.priority]}
                      </span>
                    </div>

                    <p className="text-gray-600 whitespace-pre-line">{ticket.message}</p>

                    {ticket.status === 'resolved' && ticket.response && (
                      <div className="mt-4 bg-green-50 rounded-lg p-4">
                        <div className="flex items-center mb-2">
                          <Check className="h-5 w-5 text-green-400 mr-2" />
                          <h4 className="text-sm font-medium text-green-800">
                            {t.tickets.status.resolved}
                          </h4>
                        </div>
                        <p className="text-sm text-green-700 whitespace-pre-wrap">
                          {ticket.response}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Assistance;