import { create } from 'zustand';
import { User } from '../types/user';

interface AuthState {
  user: User | null;
  isLoading: boolean;
  setUser: (user: User | null) => void;
  setLoading: (loading: boolean) => void;
  isAdmin: () => boolean;
  isSuperAdmin: () => boolean;
  isCouncilMember: () => boolean;
}

export const useAuthStore = create<AuthState>((set, get) => ({
  user: null,
  isLoading: true,
  setUser: (user) => set({ user }),
  setLoading: (loading) => set({ isLoading: loading }),
  isAdmin: () => {
    const { user } = get();
    return user?.role === 'admin' || user?.role === 'superadmin';
  },
  isSuperAdmin: () => {
    const { user } = get();
    return user?.role === 'superadmin';
  },
  isCouncilMember: () => {
    const { user } = get();
    return user?.role === 'council' || get().isAdmin();
  },
}));