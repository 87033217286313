import React, { useState, useEffect, useRef } from 'react';
import { useAuthStore } from '../store/authStore';
import { collection, query, where, orderBy, onSnapshot, addDoc, doc, updateDoc, serverTimestamp, deleteDoc, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { MessageSquare, Send, ArrowLeft, Plus } from 'lucide-react';
import { clsx } from 'clsx';
import { Menu, Transition } from '@headlessui/react';
import { NewConversationModal } from '../components/NewConversationModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { translationService } from '../utils/translationService';
import { MessageBubble } from '../components/MessageBubble';

interface Message {
  id: string;
  content: string;
  senderId: string;
  senderName: string;
  createdAt: Date;
  isRead: boolean;
  translations?: {
    [key: string]: string;
  };
}

interface ParticipantInfo {
  name: string;
  isOnline: boolean;
  role: string;
}

interface Conversation {
  id: string;
  participants: string[];
  participantsInfo: {
    [key: string]: ParticipantInfo;
  };
  lastMessageDate: Date;
  lastMessage?: string;
}

export function Messages() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);
  const [isNewConversationModalOpen, setIsNewConversationModalOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messageInputRef = useRef<HTMLInputElement>(null);

  // Get conversation ID from URL
  const searchParams = new URLSearchParams(location.search);
  const conversationId = searchParams.get('id');
  const newUserId = searchParams.get('new');

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!user?.id) return;

    const q = query(
      collection(db, 'conversations'),
      where('participants', 'array-contains', user.id),
      orderBy('lastMessageDate', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const conversationsData = snapshot.docs.map(doc => {
        const data = doc.data();
        const lastMessageDate = data.lastMessageDate?.toDate();
        return {
          id: doc.id,
          ...data,
          lastMessageDate: lastMessageDate instanceof Date && !isNaN(lastMessageDate.getTime()) 
            ? lastMessageDate 
            : null
        };
      }) as Conversation[];
      setConversations(conversationsData);

      if (conversationId) {
        const selectedConv = conversationsData.find(conv => conv.id === conversationId);
        if (selectedConv) {
          setSelectedConversation(selectedConv);
        }
      }
    });

    return () => unsubscribe();
  }, [user?.id, conversationId]);

  useEffect(() => {
    if (!selectedConversation?.id) return;

    const q = query(
      collection(db, 'messages'),
      where('conversationId', '==', selectedConversation.id),
      orderBy('createdAt', 'asc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messagesData = snapshot.docs.map(doc => {
        const data = doc.data();
        const createdAt = data.createdAt?.toDate();
        return {
          id: doc.id,
          ...data,
          createdAt: createdAt instanceof Date && !isNaN(createdAt.getTime()) 
            ? createdAt 
            : new Date()
        };
      }) as Message[];
      setMessages(messagesData);

      // Mark messages as read
      messagesData.forEach(async (message) => {
        if (message.senderId !== user?.id && !message.isRead) {
          await updateDoc(doc(db, 'messages', message.id), {
            isRead: true
          });
        }
      });
    });

    return () => unsubscribe();
  }, [selectedConversation?.id, user?.id]);

  useEffect(() => {
    if (newUserId && !conversationId) {
      setIsNewConversationModalOpen(true);
    }
  }, [newUserId, conversationId]);

  // Scroll to bottom on new messages
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const sendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newMessage.trim() || !selectedConversation || !user) return;

    try {
      // Translate message to all supported languages
      const translations = {};
      const languages = ['fr', 'en', 'it'];
      
      await Promise.all(
        languages
          .filter(lang => lang !== user.preferredLanguage?.toLowerCase())
          .map(async (lang) => {
            const translatedText = await translationService.translateText(newMessage, lang);
            translations[lang] = translatedText;
          })
      );

      translations[user.preferredLanguage?.toLowerCase() || 'fr'] = newMessage;

      // Add message to Firestore
      await addDoc(collection(db, 'messages'), {
        conversationId: selectedConversation.id,
        senderId: user.id,
        senderName: `${user.firstName} ${user.lastName}`,
        content: newMessage,
        translations,
        createdAt: serverTimestamp(),
        isRead: false
      });

      // Update conversation
      await updateDoc(doc(db, 'conversations', selectedConversation.id), {
        lastMessageDate: serverTimestamp(),
        lastMessage: newMessage
      });

      setNewMessage('');
      messageInputRef.current?.focus();
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleDeleteMessage = async (messageId: string) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce message ?')) return;
    
    try {
      await deleteDoc(doc(db, 'messages', messageId));
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const handleDeleteConversation = async (conversationId: string) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette conversation ?')) return;
    
    try {
      // Delete all messages in the conversation
      const messagesQuery = query(
        collection(db, 'messages'),
        where('conversationId', '==', conversationId)
      );
      const messagesSnapshot = await getDocs(messagesQuery);
      const deletePromises = messagesSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePromises);

      // Delete the conversation
      await deleteDoc(doc(db, 'conversations', conversationId));
      
      // Navigate back to messages
      navigate('/messages');
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  const getOtherParticipant = (conversation: Conversation) => {
    if (!conversation?.participantsInfo || !user) return null;
    const otherParticipantId = conversation.participants.find(id => id !== user.id);
    return otherParticipantId ? conversation.participantsInfo[otherParticipantId] : null;
  };

  const ConversationsList = () => (
    <div className={clsx(
      "w-full md:w-80 bg-white border-r border-gray-200 flex flex-col flex-shrink-0",
      isMobileView && selectedConversation && "hidden",
      "md:flex"
    )}>
      <div className="p-3 border-b border-gray-200 flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900">Messages</h2>
        <button
          onClick={() => setIsNewConversationModalOpen(true)}
          className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100"
        >
          <Plus className="h-5 w-5" />
        </button>
      </div>

      <div className="flex-1 overflow-y-auto conversation-list">
        {conversations.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full text-center p-4">
            <MessageSquare className="h-12 w-12 text-gray-400 mb-4" />
            <p className="text-gray-500">Aucune conversation</p>
            <button
              onClick={() => setIsNewConversationModalOpen(true)}
              className="mt-4 text-blue-600 hover:text-blue-500"
            >
              Démarrer une conversation
            </button>
          </div>
        ) : (
          conversations.map((conversation) => {
            const otherParticipant = getOtherParticipant(conversation);
            return (
              <button
                key={conversation.id}
                onClick={() => {
                  setSelectedConversation(conversation);
                  navigate(`/messages?id=${conversation.id}`);
                }}
                className={clsx(
                  "w-full p-4 flex items-center hover:bg-gray-50 text-left",
                  selectedConversation?.id === conversation.id && "bg-blue-50"
                )}
              >
                <div className="relative">
                  <img
                    className="h-12 w-12 rounded-full"
                    src={`https://ui-avatars.com/api/?name=${otherParticipant?.name || 'U'}&background=random`}
                    alt=""
                  />
                  <div className={clsx(
                    "absolute bottom-0 right-0 h-3 w-3 rounded-full ring-2 ring-white",
                    otherParticipant?.isOnline ? "bg-green-400" : "bg-gray-300"
                  )} />
                </div>
                <div className="ml-4 flex-1 min-w-0">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {otherParticipant?.name || 'Utilisateur'}
                    </p>
                    {conversation.lastMessageDate && (
                      <p className="text-xs text-gray-500">
                        {format(conversation.lastMessageDate, 'HH:mm', { locale: fr })}
                      </p>
                    )}
                  </div>
                  <div className="flex items-center">
                    <p className="text-sm text-gray-500 truncate">
                      {conversation.lastMessage}
                    </p>
                    <span className={clsx(
                      "ml-2 text-xs px-1.5 py-0.5 rounded-full",
                      otherParticipant?.role === 'admin' ? "bg-red-100 text-red-800" :
                      otherParticipant?.role === 'council' ? "bg-blue-100 text-blue-800" :
                      "bg-gray-100 text-gray-800"
                    )}>
                      {otherParticipant?.role === 'admin' ? 'Admin' :
                       otherParticipant?.role === 'council' ? 'Conseil' :
                       'Membre'}
                    </span>
                  </div>
                </div>
              </button>
            );
          })
        )}
      </div>
    </div>
  );

  return (
    <div className="chat-container flex">
      <ConversationsList />

      {(selectedConversation || !isMobileView) && (
        <div className="flex-1 flex flex-col bg-gray-50 min-w-0">
          {selectedConversation && (
            <>
              <div className="p-3 bg-white border-b border-gray-200">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    {isMobileView && (
                      <button
                        onClick={() => {
                          setSelectedConversation(null);
                          navigate('/messages');
                        }}
                        className="mr-4 p-2 -ml-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100"
                      >
                        <ArrowLeft className="h-5 w-5" />
                      </button>
                    )}
                    <div className="relative">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={`https://ui-avatars.com/api/?name=${getOtherParticipant(selectedConversation)?.name || 'U'}&background=random`}
                        alt=""
                      />
                      <div className={clsx(
                        "absolute bottom-0 right-0 h-3 w-3 rounded-full ring-2 ring-white",
                        getOtherParticipant(selectedConversation)?.isOnline ? "bg-green-400" : "bg-gray-300"
                      )} />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-lg font-medium text-gray-900">
                        {getOtherParticipant(selectedConversation)?.name || 'Utilisateur'}
                      </h3>
                      <p className="text-sm text-gray-500">
                        {getOtherParticipant(selectedConversation)?.isOnline ? 'En ligne' : 'Hors ligne'}
                      </p>
                    </div>
                  </div>
                  <Menu as="div" className="relative">
                    <Menu.Button className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100">
                      <span className="sr-only">Options</span>
                      <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                      </svg>
                    </Menu.Button>
                    <Transition
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => handleDeleteConversation(selectedConversation.id)}
                              className={clsx(
                                active ? 'bg-red-50 text-red-700' : 'text-red-600',
                                'flex w-full items-center px-4 py-2 text-sm'
                              )}
                            >
                              Supprimer la conversation
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>

              <div className="flex-1 overflow-y-auto p-4 space-y-3 messages-container">
                {messages.map((message) => (
                  <MessageBubble
                    key={message.id}
                    message={message}
                    isOwnMessage={message.senderId === user?.id}
                    onDelete={handleDeleteMessage}
                    userLanguage={user?.preferredLanguage?.toLowerCase() || 'fr'}
                  />
                ))}
                <div ref={messagesEndRef} />
              </div>

              <div className="p-3 bg-white border-t border-gray-200">
                <form onSubmit={sendMessage} className="flex space-x-3">
                  <input
                    ref={messageInputRef}
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Écrivez votre message..."
                    className="flex-1 rounded-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 px-4"
                  />
                  <button
                    type="submit"
                    disabled={!newMessage.trim()}
                    className="inline-flex items-center p-2 border border-transparent rounded-full text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                  >
                    <Send className="h-5 w-5" />
                  </button>
                </form>
              </div>
            </>
          )}
        </div>
      )}

      <NewConversationModal
        isOpen={isNewConversationModalOpen}
        onClose={() => setIsNewConversationModalOpen(false)}
        onConversationCreated={(id) => {
          setIsNewConversationModalOpen(false);
          navigate(`/messages?id=${id}`);
        }}
        preselectedUserId={newUserId}
      />
    </div>
  );
}

export default Messages;