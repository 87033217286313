import React from 'react';
import { AlertTriangle, Edit } from 'lucide-react';
import { useAuthStore } from '../../store/authStore';
import { useTranslation } from '../../hooks/useTranslation';
import { sanitizeText } from '../../utils/textUtils';

interface AlertBannerProps {
  message: string;
  onEdit?: () => void;
}

export function AlertBanner({ message, onEdit }: AlertBannerProps) {
  const { isAdmin, user } = useAuthStore();
  const [translatedMessage, setTranslatedMessage] = React.useState('');
  const { translate } = useTranslation();

  React.useEffect(() => {
    const translateMessage = async () => {
      if (message) {
        // Get message from translations if available
        const userLang = user?.preferredLanguage?.toLowerCase() || 'fr';
        if (message.translations?.[userLang]) {
          setTranslatedMessage(sanitizeText(message.translations[userLang]));
        } else {
          const translated = await translate(message);
          setTranslatedMessage(sanitizeText(translated));
        }
      }
    };
    translateMessage();
  }, [message, translate, user?.preferredLanguage]);

  // Split message into lines and preserve line breaks
  const messageLines = translatedMessage.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {sanitizeText(line)}
      {index < translatedMessage.split('\n').length - 1 && <br />}
    </React.Fragment>
  ));

  return (
    <div className="rounded-lg bg-yellow-50 p-6">
      <div className="flex items-start justify-between">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">
            <AlertTriangle className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-yellow-800 whitespace-pre-line leading-relaxed">
              {messageLines}
            </p>
          </div>
        </div>
        {isAdmin() && onEdit && (
          <button
            onClick={onEdit}
            className="ml-3 flex-shrink-0 text-yellow-500 hover:text-yellow-600"
          >
            <Edit className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  );
}