import React from 'react';
import { useAuthStore } from '../../store/authStore';
import { LegalPageLayout } from '../../components/legal/LegalPageLayout';

export function CookiePolicy() {
  const { user } = useAuthStore();
  const language = user?.preferredLanguage?.toLowerCase() || 'fr';

  const content = {
    fr: {
      title: "Politique d'Utilisation des Cookies",
      intro: "La présente politique d'utilisation des cookies (ci-après la \"Politique\") explique comment SAS Kingstoring utilise des cookies et des technologies similaires sur notre site internet (ci-après le \"Site\"). En utilisant le Site, vous consentez à l'utilisation de cookies conformément à cette Politique.",
      sections: [
        {
          title: "1. Qu'est-ce qu'un cookie ?",
          content: "Un cookie est un petit fichier texte placé sur votre appareil (ordinateur, smartphone, tablette) lorsque vous visitez un site internet. Les cookies sont largement utilisés pour faire fonctionner les sites internet, améliorer leur efficacité et fournir des informations aux propriétaires du site."
        },
        {
          title: "2. Types de Cookies Utilisés",
          subsections: [
            {
              title: "Cookies Essentiels",
              content: "Ces cookies sont indispensables pour vous permettre de naviguer sur le Site et d'utiliser ses fonctionnalités. Sans ces cookies, certains services ne peuvent pas être fournis."
            },
            {
              title: "Cookies de Performance",
              content: "Ces cookies collectent des informations sur la manière dont les visiteurs utilisent le Site, par exemple, les pages les plus souvent visitées et les messages d'erreur éventuels. Ils nous aident à améliorer le fonctionnement du Site."
            },
            {
              title: "Cookies de Fonctionnalité",
              content: "Ces cookies permettent au Site de se souvenir des choix que vous faites (comme votre nom d'utilisateur, la langue ou la région où vous vous trouvez) et fournissent des fonctionnalités améliorées et plus personnelles."
            }
          ]
        },
        {
          title: "3. Finalités des Cookies Utilisés",
          content: "Les cookies que nous utilisons sur notre Site servent aux finalités suivantes :",
          list: [
            "Assurer le bon fonctionnement du Site",
            "Améliorer la performance du Site",
            "Personnaliser votre expérience utilisateur",
            "Analyser l'utilisation du Site et l'audience"
          ]
        },
        {
          title: "4. Gestion des Cookies",
          content: "Vous pouvez gérer ou désactiver les cookies via les paramètres de votre navigateur. Voici comment procéder pour les principaux navigateurs :",
          browsers: [
            {
              name: "Google Chrome",
              steps: [
                "Allez dans \"Paramètres\"",
                "Cliquez sur \"Paramètres avancés\"",
                "Sous \"Confidentialité et sécurité\", cliquez sur \"Paramètres de contenu\"",
                "Cliquez sur \"Cookies\""
              ]
            },
            {
              name: "Mozilla Firefox",
              steps: [
                "Allez dans \"Options\"",
                "Cliquez sur \"Vie privée et sécurité\"",
                "Sous \"Cookies et données de sites\", sélectionnez les options appropriées"
              ]
            },
            {
              name: "Safari",
              steps: [
                "Allez dans \"Préférences\"",
                "Cliquez sur \"Confidentialité\"",
                "Sélectionnez vos options relatives aux cookies"
              ]
            }
          ],
          note: "Notez que la désactivation des cookies peut affecter votre expérience sur le Site et limiter certaines fonctionnalités."
        },
        {
          title: "5. Cookies Tiers",
          content: "Nous pouvons permettre à des tiers de placer des cookies sur votre appareil par le biais de notre Site. Ces tiers peuvent inclure des réseaux publicitaires, des fournisseurs de services d'analyse web et des plateformes de réseaux sociaux. Nous n'avons pas de contrôle direct sur ces cookies tiers."
        },
        {
          title: "6. Mise à Jour de la Politique",
          content: "Nous nous réservons le droit de modifier cette Politique à tout moment. Toute modification sera publiée sur cette page avec une date de mise à jour. Nous vous encourageons à consulter régulièrement cette page pour rester informé des éventuelles modifications."
        }
      ],
      contact: {
        title: "7. Contact",
        content: "Pour toute question ou préoccupation concernant cette Politique, veuillez nous contacter à :",
        company: "SAS Kingstoring",
        address: "20 Avenue Jean Medecin, 06000 Nice",
        email: "contact@kingstoring.com"
      }
    },
    en: {
      title: "Cookie Policy",
      intro: "This cookie policy (hereinafter the \"Policy\") explains how SAS Kingstoring uses cookies and similar technologies on our website (hereinafter the \"Site\"). By using the Site, you consent to the use of cookies in accordance with this Policy.",
      sections: [
        {
          title: "1. What is a cookie?",
          content: "A cookie is a small text file placed on your device (computer, smartphone, tablet) when you visit a website. Cookies are widely used to make websites work, improve their efficiency, and provide information to website owners."
        },
        {
          title: "2. Types of Cookies Used",
          subsections: [
            {
              title: "Essential Cookies",
              content: "These cookies are essential for enabling you to navigate the Site and use its features. Without these cookies, certain services cannot be provided."
            },
            {
              title: "Performance Cookies",
              content: "These cookies collect information about how visitors use the Site, such as which pages are visited most often and any error messages. They help us improve how the Site works."
            },
            {
              title: "Functionality Cookies",
              content: "These cookies allow the Site to remember choices you make (such as your username, language, or region) and provide enhanced, more personal features."
            }
          ]
        },
        {
          title: "3. Purposes of Cookies Used",
          content: "The cookies we use on our Site serve the following purposes:",
          list: [
            "Ensure proper Site functionality",
            "Improve Site performance",
            "Personalize your user experience",
            "Analyze Site usage and audience"
          ]
        },
        {
          title: "4. Cookie Management",
          content: "You can manage or disable cookies through your browser settings. Here's how to proceed for the main browsers:",
          browsers: [
            {
              name: "Google Chrome",
              steps: [
                "Go to \"Settings\"",
                "Click on \"Advanced settings\"",
                "Under \"Privacy and security\", click on \"Content settings\"",
                "Click on \"Cookies\""
              ]
            },
            {
              name: "Mozilla Firefox",
              steps: [
                "Go to \"Options\"",
                "Click on \"Privacy & Security\"",
                "Under \"Cookies and Site Data\", select appropriate options"
              ]
            },
            {
              name: "Safari",
              steps: [
                "Go to \"Preferences\"",
                "Click on \"Privacy\"",
                "Select your cookie options"
              ]
            }
          ],
          note: "Please note that disabling cookies may affect your experience on the Site and limit certain functionalities."
        },
        {
          title: "5. Third-Party Cookies",
          content: "We may allow third parties to place cookies on your device through our Site. These third parties may include advertising networks, web analytics providers, and social media platforms. We have no direct control over these third-party cookies."
        },
        {
          title: "6. Policy Updates",
          content: "We reserve the right to modify this Policy at any time. Any changes will be posted on this page with an update date. We encourage you to regularly check this page to stay informed of any changes."
        }
      ],
      contact: {
        title: "7. Contact",
        content: "For any questions or concerns regarding this Policy, please contact us at:",
        company: "SAS Kingstoring",
        address: "20 Avenue Jean Medecin, 06000 Nice",
        email: "contact@kingstoring.com"
      }
    },
    it: {
      title: "Politica dei Cookie",
      intro: "Questa politica dei cookie (di seguito la \"Politica\") spiega come SAS Kingstoring utilizza i cookie e tecnologie simili sul nostro sito web (di seguito il \"Sito\"). Utilizzando il Sito, acconsenti all'uso dei cookie in conformità con questa Politica.",
      sections: [
        {
          title: "1. Che cos'è un cookie?",
          content: "Un cookie è un piccolo file di testo inserito sul tuo dispositivo (computer, smartphone, tablet) quando visiti un sito web. I cookie sono ampiamente utilizzati per far funzionare i siti web, migliorarne l'efficienza e fornire informazioni ai proprietari del sito."
        },
        {
          title: "2. Tipi di Cookie Utilizzati",
          subsections: [
            {
              title: "Cookie Essenziali",
              content: "Questi cookie sono indispensabili per permetterti di navigare nel Sito e utilizzare le sue funzionalità. Senza questi cookie, alcuni servizi non possono essere forniti."
            },
            {
              title: "Cookie di Performance",
              content: "Questi cookie raccolgono informazioni su come i visitatori utilizzano il Sito, ad esempio quali pagine sono visitate più spesso e eventuali messaggi di errore. Ci aiutano a migliorare il funzionamento del Sito."
            },
            {
              title: "Cookie di Funzionalità",
              content: "Questi cookie permettono al Sito di ricordare le scelte che fai (come il tuo nome utente, la lingua o la regione) e fornire funzionalità migliorate e più personalizzate."
            }
          ]
        },
        {
          title: "3. Finalità dei Cookie Utilizzati",
          content: "I cookie che utilizziamo sul nostro Sito servono alle seguenti finalità:",
          list: [
            "Garantire il corretto funzionamento del Sito",
            "Migliorare le prestazioni del Sito",
            "Personalizzare la tua esperienza utente",
            "Analizzare l'utilizzo del Sito e il pubblico"
          ]
        },
        {
          title: "4. Gestione dei Cookie",
          content: "Puoi gestire o disattivare i cookie attraverso le impostazioni del tuo browser. Ecco come procedere per i principali browser:",
          browsers: [
            {
              name: "Google Chrome",
              steps: [
                "Vai in \"Impostazioni\"",
                "Clicca su \"Impostazioni avanzate\"",
                "Sotto \"Privacy e sicurezza\", clicca su \"Impostazioni contenuti\"",
                "Clicca su \"Cookie\""
              ]
            },
            {
              name: "Mozilla Firefox",
              steps: [
                "Vai in \"Opzioni\"",
                "Clicca su \"Privacy e sicurezza\"",
                "Sotto \"Cookie e dati dei siti\", seleziona le opzioni appropriate"
              ]
            },
            {
              name: "Safari",
              steps: [
                "Vai in \"Preferenze\"",
                "Clicca su \"Privacy\"",
                "Seleziona le tue opzioni relative ai cookie"
              ]
            }
          ],
          note: "Nota che la disattivazione dei cookie potrebbe influire sulla tua esperienza sul Sito e limitare alcune funzionalità."
        },
        {
          title: "5. Cookie di Terze Parti",
          content: "Potremmo consentire a terze parti di inserire cookie sul tuo dispositivo attraverso il nostro Sito. Queste terze parti possono includere reti pubblicitarie, fornitori di servizi di analisi web e piattaforme di social media. Non abbiamo un controllo diretto su questi cookie di terze parti."
        },
        {
          title: "6. Aggiornamento della Politica",
          content: "Ci riserviamo il diritto di modificare questa Politica in qualsiasi momento. Qualsiasi modifica sarà pubblicata su questa pagina con una data di aggiornamento. Ti invitiamo a consultare regolarmente questa pagina per rimanere informato su eventuali modifiche."
        }
      ],
      contact: {
        title: "7. Contatto",
        content: "Per qualsiasi domanda o dubbio riguardo questa Politica, contattaci a:",
        company: "SAS Kingstoring",
        address: "20 Avenue Jean Medecin, 06000 Nice",
        email: "contact@kingstoring.com"
      }
    }
  };

  return (
    <LegalPageLayout>
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">
          {content[language].title}
        </h1>

        <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-8">
          <p className="text-blue-700">
            {content[language].intro}
          </p>
        </div>

        <div className="space-y-8">
          {content[language].sections.map((section, index) => (
            <section key={index} className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                {section.title}
              </h2>
              
              {section.content && (
                <p className="text-gray-600 mb-4">{section.content}</p>
              )}

              {section.list && (
                <ul className="list-disc pl-6 space-y-2 text-gray-600">
                  {section.list.map((item, itemIndex) => (
                    <li key={itemIndex}>{item}</li>
                  ))}
                </ul>
              )}

              {section.subsections && (
                <div className="space-y-4">
                  {section.subsections.map((subsection, subIndex) => (
                    <div key={subIndex} className="pl-4 border-l-4 border-gray-100">
                      <h3 className="font-medium text-gray-900 mb-2">
                        {subsection.title}
                      </h3>
                      <p className="text-gray-600">
                        {subsection.content}
                      </p>
                    </div>
                  ))}
                </div>
              )}

              {section.browsers && (
                <div className="space-y-4 mt-4">
                  {section.browsers.map((browser, browserIndex) => (
                    <div key={browserIndex} className="pl-4 border-l-4 border-gray-100">
                      <h3 className="font-medium text-gray-900 mb-2">
                        {browser.name}
                      </h3>
                      <ul className="list-disc pl-6 space-y-1 text-gray-600">
                        {browser.steps.map((step, stepIndex) => (
                          <li key={stepIndex}>{step}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                  {section.note && (
                    <p className="text-sm text-gray-500 mt-4 italic">
                      {section.note}
                    </p>
                  )}
                </div>
              )}
            </section>
          ))}
        </div>

        <div className="mt-8 bg-gray-50 rounded-lg p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">
            {content[language].contact.title}
          </h2>
          <div className="space-y-2 text-gray-600">
            <p>{content[language].contact.content}</p>
            <p className="font-medium">{content[language].contact.company}</p>
            <p>{content[language].contact.address}</p>
            <p>Email: {content[language].contact.email}</p>
          </div>
        </div>

        <p className="text-sm text-gray-500 mt-8 text-right">
          {content[language].lastUpdate}
        </p>
      </div>
    </LegalPageLayout>
  );
}

export default CookiePolicy;