import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { useNavigate, Link } from 'react-router-dom';
import { User, Mail, Lock, Phone, MapPin, Globe, Shield, Building } from 'lucide-react';
import { clsx } from 'clsx';
import { registerSchema } from '../utils/validation';
import { verifyInviteCode } from '../utils/inviteCode';
import { TermsCheckbox } from '../components/TermsCheckbox';
import type { RegisterForm } from '../utils/validation';

const translations = {
  FR: {
    title: 'Créer un compte',
    subtitle: 'SCI Club Expo 1',
    description: 'Rejoignez notre communauté de propriétaires',
    steps: {
      identity: 'Identité',
      contact: 'Contact',
      address: 'Adresse',
      validation: 'Validation'
    },
    firstName: 'Prénom',
    lastName: 'Nom',
    email: 'Email',
    password: 'Mot de passe',
    phone: 'Téléphone',
    address: 'Adresse',
    postalCode: 'Code postal',
    city: 'Ville',
    country: 'Pays',
    inviteCode: 'Code d\'invitation',
    inviteCodeHelp: 'Le code d\'invitation vous a été envoyé par courrier',
    register: 'S\'inscrire',
    loading: 'Inscription en cours...',
    backToLogin: 'Retour à la connexion',
    legalLinks: {
      terms: "Conditions générales",
      privacy: "Politique de confidentialité",
      mentions: "Mentions légales",
      cookies: "Politique des cookies"
    }
  },
  EN: {
    title: 'Create account',
    subtitle: 'SCI Club Expo 1',
    description: 'Join our owners community',
    steps: {
      identity: 'Identity',
      contact: 'Contact',
      address: 'Address',
      validation: 'Validation'
    },
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    password: 'Password',
    phone: 'Phone',
    address: 'Address',
    postalCode: 'Postal code',
    city: 'City',
    country: 'Country',
    inviteCode: 'Invitation code',
    inviteCodeHelp: 'The invitation code was sent to you by mail',
    register: 'Sign up',
    loading: 'Signing up...',
    backToLogin: 'Back to login',
    legalLinks: {
      terms: "Terms of Service",
      privacy: "Privacy Policy",
      mentions: "Legal Notice",
      cookies: "Cookie Policy"
    }
  },
  IT: {
    title: 'Crea account',
    subtitle: 'SCI Club Expo 1',
    description: 'Unisciti alla nostra comunità di proprietari',
    steps: {
      identity: 'Identità',
      contact: 'Contatto',
      address: 'Indirizzo',
      validation: 'Convalida'
    },
    firstName: 'Nome',
    lastName: 'Cognome',
    email: 'Email',
    password: 'Password',
    phone: 'Telefono',
    address: 'Indirizzo',
    postalCode: 'Codice postale',
    city: 'Città',
    country: 'Paese',
    inviteCode: 'Codice invito',
    inviteCodeHelp: 'Il codice di invito ti è stato inviato per posta',
    register: 'Registrati',
    loading: 'Registrazione in corso...',
    backToLogin: 'Torna al login',
    legalLinks: {
      terms: "Termini di servizio",
      privacy: "Politica sulla privacy",
      mentions: "Note legali",
      cookies: "Politica dei cookie"
    }
  }
};

export function Register() {
  const navigate = useNavigate();
  const [language, setLanguage] = useState('FR');
  const [error, setError] = useState<string | null>(null);
  const t = translations[language];

  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<RegisterForm>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      country: 'France',
      preferredLanguage: language as 'FR' | 'EN' | 'IT'
    }
  });

  const onSubmit = async (data: RegisterForm) => {
    try {
      setError(null);

      // Verify invitation code
      const codeVerification = await verifyInviteCode(data.inviteCode);
      if (!codeVerification.valid) {
        setError(codeVerification.error);
        return;
      }

      // Create user
      const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
      
      // Create user document
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        address: data.address,
        postalCode: data.postalCode,
        city: data.city,
        country: data.country,
        preferredLanguage: data.preferredLanguage,
        role: 'member',
        createdAt: new Date(),
        updatedAt: new Date(),
        isOnline: true,
        lastSeen: new Date()
      });

      navigate('/');
    } catch (error: any) {
      console.error('Registration error:', error);
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="absolute top-4 right-4">
        <div className="relative">
          <select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            className="appearance-none pl-10 pr-8 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
          >
            <option value="FR">🇫🇷 Français</option>
            <option value="EN">🇬🇧 English</option>
            <option value="IT">🇮🇹 Italiano</option>
          </select>
          <Globe className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
        </div>
      </div>

      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-8">
          <Shield className="mx-auto h-12 w-12 text-blue-600" />
          <h1 className="mt-6 text-3xl font-bold text-gray-900">{t.title}</h1>
          <h2 className="mt-2 text-xl font-semibold text-blue-600">{t.subtitle}</h2>
          <p className="mt-3 text-gray-600">{t.description}</p>
        </div>

        <div className="bg-white shadow rounded-lg p-8">
          {error && (
            <div className="mb-6 rounded-md bg-red-50 p-4">
              <p className="text-sm font-medium text-red-800">{error}</p>
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
            {/* Identity Section */}
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">{t.steps.identity}</h3>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700">{t.firstName}</label>
                  <div className="mt-1 relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <User className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      {...register('firstName')}
                      className={clsx(
                        "pl-10 block w-full rounded-md shadow-sm sm:text-sm",
                        errors.firstName ? "border-red-300" : "border-gray-300"
                      )}
                    />
                  </div>
                  {errors.firstName && (
                    <p className="mt-2 text-sm text-red-600">{errors.firstName.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t.lastName}</label>
                  <div className="mt-1 relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <User className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      {...register('lastName')}
                      className={clsx(
                        "pl-10 block w-full rounded-md shadow-sm sm:text-sm",
                        errors.lastName ? "border-red-300" : "border-gray-300"
                      )}
                    />
                  </div>
                  {errors.lastName && (
                    <p className="mt-2 text-sm text-red-600">{errors.lastName.message}</p>
                  )}
                </div>
              </div>
            </div>

            {/* Contact Section */}
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">{t.steps.contact}</h3>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700">{t.email}</label>
                  <div className="mt-1 relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Mail className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="email"
                      {...register('email')}
                      className={clsx(
                        "pl-10 block w-full rounded-md shadow-sm sm:text-sm",
                        errors.email ? "border-red-300" : "border-gray-300"
                      )}
                    />
                  </div>
                  {errors.email && (
                    <p className="mt-2 text-sm text-red-600">{errors.email.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t.password}</label>
                  <div className="mt-1 relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Lock className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="password"
                      {...register('password')}
                      className={clsx(
                        "pl-10 block w-full rounded-md shadow-sm sm:text-sm",
                        errors.password ? "border-red-300" : "border-gray-300"
                      )}
                    />
                  </div>
                  {errors.password && (
                    <p className="mt-2 text-sm text-red-600">{errors.password.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t.phone}</label>
                  <div className="mt-1 relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Phone className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="tel"
                      {...register('phone')}
                      className={clsx(
                        "pl-10 block w-full rounded-md shadow-sm sm:text-sm",
                        errors.phone ? "border-red-300" : "border-gray-300"
                      )}
                    />
                  </div>
                  {errors.phone && (
                    <p className="mt-2 text-sm text-red-600">{errors.phone.message}</p>
                  )}
                </div>
              </div>
            </div>

            {/* Address Section */}
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">{t.steps.address}</h3>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">{t.address}</label>
                  <div className="mt-1 relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <MapPin className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      {...register('address')}
                      className={clsx(
                        "pl-10 block w-full rounded-md shadow-sm sm:text-sm",
                        errors.address ? "border-red-300" : "border-gray-300"
                      )}
                    />
                  </div>
                  {errors.address && (
                    <p className="mt-2 text-sm text-red-600">{errors.address.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t.postalCode}</label>
                  <input
                    type="text"
                    {...register('postalCode')}
                    className={clsx(
                      "mt-1 block w-full rounded-md shadow-sm sm:text-sm",
                      errors.postalCode ? "border-red-300" : "border-gray-300"
                    )}
                  />
                  {errors.postalCode && (
                    <p className="mt-2 text-sm text-red-600">{errors.postalCode.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t.city}</label>
                  <input
                    type="text"
                    {...register('city')}
                    className={clsx(
                      "mt-1 block w-full rounded-md shadow-sm sm:text-sm",
                      errors.city ? "border-red-300" : "border-gray-300"
                    )}
                  />
                  {errors.city && (
                    <p className="mt-2 text-sm text-red-600">{errors.city.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t.country}</label>
                  <input
                    type="text"
                    {...register('country')}
                    className={clsx(
                      "mt-1 block w-full rounded-md shadow-sm sm:text-sm",
                      errors.country ? "border-red-300" : "border-gray-300"
                    )}
                  />
                  {errors.country && (
                    <p className="mt-2 text-sm text-red-600">{errors.country.message}</p>
                  )}
                </div>
              </div>
            </div>

            {/* Validation Section */}
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">{t.steps.validation}</h3>
              
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">{t.inviteCode}</label>
                  <input
                    type="text"
                    {...register('inviteCode')}
                    className={clsx(
                      "mt-1 block w-full rounded-md shadow-sm sm:text-sm",
                      errors.inviteCode ? "border-red-300" : "border-gray-300"
                    )}
                  />
                  {errors.inviteCode ? (
                    <p className="mt-2 text-sm text-red-600">{errors.inviteCode.message}</p>
                  ) : (
                    <p className="mt-2 text-sm text-gray-500">{t.inviteCodeHelp}</p>
                  )}
                </div>

                <TermsCheckbox
                  checked={false}
                  onChange={() => {}}
                  language={language}
                  error={errors.acceptTerms?.message}
                />
              </div>
            </div>

            <div className="flex items-center justify-between pt-6">
              <Link
                to="/auth"
                className="text-sm font-medium text-blue-600 hover:text-blue-500"
              >
                {t.backToLogin}
              </Link>

              <button
                type="submit"
                disabled={isSubmitting}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isSubmitting ? (
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                ) : (
                  t.register
                )}
              </button>
            </div>
          </form>
        </div>

        {/* Legal Links */}
        <div className="mt-8 grid grid-cols-2 gap-4 text-sm text-center">
          <Link to="/legal/mentions" className="text-gray-500 hover:text-gray-900">
            {t.legalLinks.mentions}
          </Link>
          <Link to="/legal/cgu" className="text-gray-500 hover:text-gray-900">
            {t.legalLinks.terms}
          </Link>
          <Link to="/legal/privacy" className="text-gray-500 hover:text-gray-900">
            {t.legalLinks.privacy}
          </Link>
          <Link to="/legal/cookies" className="text-gray-500 hover:text-gray-900">
            {t.legalLinks.cookies}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Register;