import { collection, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { DriveFile, DriveFolder } from '../types/drive';

const CLOUDINARY_CONFIG = {
  cloudName: 'dhzmsvb9j',
  apiKey: '828373917479716',
  apiSecret: 'GYD_BGzKXJd6J4Kd1LaV1NfZRdI',
  uploadPreset: 'sci-club-drive'
};

export const cloudinaryService = {
  async uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', CLOUDINARY_CONFIG.uploadPreset);
    formData.append('resource_type', 'auto');

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${CLOUDINARY_CONFIG.cloudName}/auto/upload`,
        {
          method: 'POST',
          body: formData
        }
      );

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error uploading to Cloudinary:', error);
      throw error;
    }
  },

  getDownloadUrl(publicId: string, filename: string) {
    if (!publicId) throw new Error('Public ID is required');
    
    // For PDFs, use fl_attachment to force download
    if (filename.toLowerCase().endsWith('.pdf')) {
      return `https://res.cloudinary.com/${CLOUDINARY_CONFIG.cloudName}/image/upload/fl_attachment/${publicId}.pdf`;
    }

    // For other files, use raw upload with attachment flag
    return `https://res.cloudinary.com/${CLOUDINARY_CONFIG.cloudName}/raw/upload/fl_attachment:${encodeURIComponent(filename)}/${publicId}`;
  },

  async getFileUrl(fileId: string) {
    try {
      const fileDoc = await doc(db, 'drive_files', fileId).get();
      if (!fileDoc.exists()) throw new Error('File not found');
      
      const data = fileDoc.data();
      return data.url;
    } catch (error) {
      console.error('Error getting file URL:', error);
      throw error;
    }
  },

  async addFile(file: Omit<DriveFile, 'id'>) {
    try {
      const docRef = await addDoc(collection(db, 'drive_files'), {
        ...file,
        uploadedAt: new Date()
      });
      return docRef.id;
    } catch (error) {
      console.error('Error adding file:', error);
      throw error;
    }
  },

  async createFolder(folder: Omit<DriveFolder, 'id'>) {
    try {
      const docRef = await addDoc(collection(db, 'drive_folders'), {
        ...folder,
        createdAt: new Date()
      });
      return docRef.id;
    } catch (error) {
      console.error('Error creating folder:', error);
      throw error;
    }
  },

  async updateFile(id: string, updates: Partial<DriveFile>) {
    try {
      const docRef = doc(db, 'drive_files', id);
      await updateDoc(docRef, {
        ...updates,
        lastModified: new Date()
      });
    } catch (error) {
      console.error('Error updating file:', error);
      throw error;
    }
  },

  async deleteFile(id: string) {
    try {
      await deleteDoc(doc(db, 'drive_files', id));
    } catch (error) {
      console.error('Error deleting file:', error);
      throw error;
    }
  },

  async deleteFolder(id: string) {
    try {
      await deleteDoc(doc(db, 'drive_folders', id));
    } catch (error) {
      console.error('Error deleting folder:', error);
      throw error;
    }
  }
};