import React from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { MessageSquare, Eye } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/authStore';
import { dashboardTranslations } from '../../translations/dashboard';

interface Post {
  id: string;
  title: string;
  content: string;
  authorName: string;
  createdAt: Date;
  type: string;
  category: string;
}

interface RecentPostsProps {
  posts: Post[];
}

export function RecentPosts({ posts }: RecentPostsProps) {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const userLanguage = user?.preferredLanguage?.toLowerCase() || 'fr';
  const t = dashboardTranslations[userLanguage];

  return (
    <div className="bg-white shadow rounded-lg">
      <div className="p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <MessageSquare className="h-5 w-5 text-gray-400 mr-2" />
          {t.recentPosts.title}
        </h2>
        <div className="space-y-4">
          {posts.length === 0 ? (
            <p className="text-center text-gray-500 py-4">
              {t.recentPosts.noPost}
            </p>
          ) : (
            posts.map((post) => (
              <div key={post.id} className="flex items-start space-x-3">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={`https://ui-avatars.com/api/?name=${post.authorName}&background=random`}
                    alt=""
                  />
                </div>
                <div className="min-w-0 flex-1">
                  <p className="text-sm font-medium text-gray-900">
                    {post.title}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    {post.content}
                  </p>
                  <div className="mt-1 flex items-center space-x-2 text-xs text-gray-500">
                    <span>{post.authorName}</span>
                    <span>•</span>
                    <span>{format(post.createdAt, 'dd MMM HH:mm', { locale: fr })}</span>
                    <span>•</span>
                    <span>{t.recentPosts.categories[post.category] || post.category}</span>
                  </div>
                </div>
                <button
                  onClick={() => navigate(`/forum?post=${post.id}`)}
                  className="flex-shrink-0 text-gray-400 hover:text-gray-500"
                >
                  <Eye className="h-5 w-5" />
                </button>
              </div>
            ))
          )}
        </div>
        <div className="mt-6">
          <button
            onClick={() => navigate('/forum')}
            className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            {t.recentPosts.viewAll}
          </button>
        </div>
      </div>
    </div>
  );
}