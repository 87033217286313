import React, { useState } from 'react';
import { X, Download, FileText } from 'lucide-react';
import type { DriveFile } from '../../types/drive';
import { storageService } from '../../utils/storageService';

interface ViewDocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  file: DriveFile;
}

export function ViewDocumentModal({ isOpen, onClose, file }: ViewDocumentModalProps) {
  const [loading, setLoading] = useState(false);
  const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(file.format.toLowerCase());
  const isPdf = file.format.toLowerCase() === 'pdf';

  const handleDownload = async () => {
    try {
      setLoading(true);
      const url = await storageService.getFileUrl(file.id);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-hidden">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-gray-900 bg-opacity-75" onClick={onClose} />

        <div className="relative inline-block w-full max-w-6xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 focus:outline-none"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="mt-8">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-medium text-gray-900">{file.name}</h3>
              <button
                onClick={handleDownload}
                disabled={loading}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 disabled:opacity-50"
              >
                <Download className="h-5 w-5 mr-2" />
                {loading ? 'Chargement...' : 'Télécharger'}
              </button>
            </div>

            <div className="aspect-[16/9] w-full bg-gray-100 rounded-lg overflow-hidden">
              {isImage && (
                <img
                  src={file.url}
                  alt={file.name}
                  className="w-full h-full object-contain"
                />
              )}
              {isPdf && (
                <iframe
                  src={file.url}
                  className="w-full h-full border-0"
                  title={file.name}
                >
                  <div className="flex flex-col items-center justify-center h-full p-8 text-center">
                    <FileText className="h-12 w-12 text-gray-400 mb-4" />
                    <p className="text-gray-600 mb-4">Le PDF ne peut pas être affiché directement.</p>
                    <button
                      onClick={handleDownload}
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
                    >
                      <Download className="h-5 w-5 mr-2" />
                      Télécharger le PDF
                    </button>
                  </div>
                </iframe>
              )}
              {!isImage && !isPdf && (
                <div className="flex flex-col items-center justify-center h-full p-8 text-center">
                  <FileText className="h-12 w-12 text-gray-400 mb-4" />
                  <p className="text-gray-600 mb-4">
                    L'aperçu n'est pas disponible pour ce type de fichier
                  </p>
                  <button
                    onClick={handleDownload}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
                  >
                    <Download className="h-5 w-5 mr-2" />
                    Télécharger le fichier
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewDocumentModal;