import React from 'react';
import { Scale, ScrollText, Lock, BookOpen, Cookie, ChevronRight, Home } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useAuthStore } from '../../store/authStore';
import { clsx } from 'clsx';

interface LegalPageLayoutProps {
  children: React.ReactNode;
}

export function LegalPageLayout({ children }: LegalPageLayoutProps) {
  const location = useLocation();
  const { user } = useAuthStore();
  const language = user?.preferredLanguage?.toLowerCase() || 'fr';

  const legalLinks = {
    fr: [
      { name: 'Mentions Légales', href: '/legal/mentions', icon: Scale },
      { name: 'Conditions générales d\'utilisation', href: '/legal/cgu', icon: ScrollText },
      { name: 'Politique de confidentialité', href: '/legal/privacy', icon: Lock },
      { name: 'Règlement du forum', href: '/legal/forum-rules', icon: BookOpen },
      { name: 'Politique des cookies', href: '/legal/cookies', icon: Cookie }
    ],
    en: [
      { name: 'Legal Notice', href: '/legal/mentions', icon: Scale },
      { name: 'Terms of Service', href: '/legal/cgu', icon: ScrollText },
      { name: 'Privacy Policy', href: '/legal/privacy', icon: Lock },
      { name: 'Forum Rules', href: '/legal/forum-rules', icon: BookOpen },
      { name: 'Cookie Policy', href: '/legal/cookies', icon: Cookie }
    ],
    it: [
      { name: 'Note Legali', href: '/legal/mentions', icon: Scale },
      { name: 'Termini di Servizio', href: '/legal/cgu', icon: ScrollText },
      { name: 'Politica sulla Privacy', href: '/legal/privacy', icon: Lock },
      { name: 'Regole del Forum', href: '/legal/forum-rules', icon: BookOpen },
      { name: 'Politica dei Cookie', href: '/legal/cookies', icon: Cookie }
    ]
  };

  const getCurrentPageTitle = () => {
    const currentLink = legalLinks[language].find(link => link.href === location.pathname);
    return currentLink?.name || '';
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Breadcrumb */}
      <nav className="sticky top-0 z-10 bg-white border-b border-gray-200 backdrop-blur-sm bg-opacity-90">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center space-x-2 text-sm text-gray-500">
              <Link 
                to="/" 
                className="flex items-center hover:text-gray-700 transition-colors"
              >
                <Home className="h-4 w-4 mr-1" />
                Accueil
              </Link>
              <ChevronRight className="h-4 w-4" />
              <span className="text-gray-900 font-medium">
                {getCurrentPageTitle()}
              </span>
            </div>
          </div>
        </div>
      </nav>

      <div className="py-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            {/* Sidebar */}
            <aside className="hidden lg:block lg:col-span-3">
              <div className="sticky top-24">
                <nav className="space-y-1 rounded-lg bg-white shadow-sm border border-gray-100">
                  {legalLinks[language].map((item) => {
                    const isActive = location.pathname === item.href;
                    return (
                      <Link
                        key={item.href}
                        to={item.href}
                        className={clsx(
                          isActive
                            ? 'bg-blue-50 text-blue-700 border-blue-500'
                            : 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group flex items-center px-4 py-3 text-sm font-medium border-l-4 transition-all duration-200'
                        )}
                      >
                        <item.icon
                          className={clsx(
                            isActive ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                            'flex-shrink-0 -ml-1 mr-3 h-5 w-5 transition-colors'
                          )}
                        />
                        <span className="truncate">{item.name}</span>
                      </Link>
                    );
                  })}
                </nav>
              </div>
            </aside>

            {/* Mobile navigation */}
            <div className="lg:hidden mb-6">
              <select
                value={location.pathname}
                onChange={(e) => window.location.href = e.target.value}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                {legalLinks[language].map((item) => (
                  <option key={item.href} value={item.href}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Main content */}
            <main className="lg:col-span-9">
              <div className="bg-white shadow-sm rounded-lg border border-gray-100">
                <div className="px-4 py-5 sm:p-6">
                  <div className="prose prose-blue max-w-none">
                    {children}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LegalPageLayout;