import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthStore } from '../store/authStore';
import { X, Search } from 'lucide-react';

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isOnline: boolean;
  role: string;
}

interface NewConversationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConversationCreated: (id: string) => void;
  preselectedUserId?: string | null;
}

export function NewConversationModal({ 
  isOpen, 
  onClose, 
  onConversationCreated, 
  preselectedUserId = null 
}: NewConversationModalProps) {
  const [users, setUsers] = useState<User[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { user: currentUser } = useAuthStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!isOpen || !currentUser?.id) return;

      try {
        setLoading(true);
        const usersRef = collection(db, 'users');
        let usersQuery;
        
        if (preselectedUserId) {
          usersQuery = query(usersRef, where('__name__', '==', preselectedUserId));
        } else if (currentUser.role === 'member') {
          usersQuery = query(usersRef, where('role', 'in', ['council', 'admin', 'superadmin']));
        } else {
          usersQuery = query(usersRef);
        }

        const querySnapshot = await getDocs(usersQuery);
        const usersData = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(user => user.id !== currentUser.id) as User[];
        
        setUsers(usersData);

        if (preselectedUserId && usersData.length > 0) {
          const selectedUser = usersData[0];
          startConversation(selectedUser);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [isOpen, currentUser?.id, preselectedUserId]);

  const startConversation = async (otherUser: User) => {
    if (!currentUser) return;

    try {
      // Check if conversation exists
      const conversationsRef = collection(db, 'conversations');
      const q = query(
        conversationsRef,
        where('participants', 'array-contains', currentUser.id)
      );
      
      const querySnapshot = await getDocs(q);
      let existingConversation = null;
      
      querySnapshot.forEach(doc => {
        const data = doc.data();
        if (data.participants.includes(otherUser.id)) {
          existingConversation = { id: doc.id, ...data };
        }
      });

      if (existingConversation) {
        onConversationCreated(existingConversation.id);
      } else {
        // Create new conversation
        const newConversation = await addDoc(collection(db, 'conversations'), {
          participants: [currentUser.id, otherUser.id],
          participantsInfo: {
            [currentUser.id]: {
              name: `${currentUser.firstName} ${currentUser.lastName}`,
              isOnline: true,
              role: currentUser.role
            },
            [otherUser.id]: {
              name: `${otherUser.firstName} ${otherUser.lastName}`,
              isOnline: otherUser.isOnline || false,
              role: otherUser.role
            }
          },
          createdAt: serverTimestamp(),
          lastMessageDate: serverTimestamp(),
          lastMessage: ''
        });

        onConversationCreated(newConversation.id);
      }

      onClose();
    } catch (error) {
      console.error('Error creating conversation:', error);
    }
  };

  const filteredUsers = users.filter(user =>
    `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" onClick={onClose} />

        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900">
              Nouvelle conversation
            </h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="w-6 h-6" />
            </button>
          </div>

          {!preselectedUserId && (
            <div className="mb-4">
              <div className="relative">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Rechercher un membre..."
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
            </div>
          )}

          {loading ? (
            <div className="flex justify-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
          ) : (
            <div className="mt-4 max-h-60 overflow-y-auto">
              {filteredUsers.length === 0 ? (
                <p className="text-center text-gray-500 py-4">
                  Aucun utilisateur trouvé
                </p>
              ) : (
                <ul className="divide-y divide-gray-200">
                  {filteredUsers.map((user) => (
                    <li key={user.id}>
                      <button
                        onClick={() => startConversation(user)}
                        className="w-full px-4 py-3 flex items-center hover:bg-gray-50"
                      >
                        <img
                          className="h-10 w-10 rounded-full"
                          src={`https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}&background=random`}
                          alt=""
                        />
                        <div className="ml-3 text-left">
                          <p className="text-sm font-medium text-gray-900">
                            {user.firstName} {user.lastName}
                          </p>
                          <p className="text-sm text-gray-500">
                            {user.role === 'council' ? 'Membre du conseil' : 
                             user.role === 'admin' ? 'Administrateur' : 
                             user.role === 'superadmin' ? 'Super administrateur' : 'Membre'}
                          </p>
                        </div>
                        <div className={`ml-auto w-2 h-2 rounded-full ${user.isOnline ? 'bg-green-400' : 'bg-gray-300'}`} />
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewConversationModal;