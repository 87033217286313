import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuthStore } from '../../store/authStore';
import { translationService } from '../../utils/translationService';
import { notifyNewPost, notifyPostStatus } from '../../utils/notifications';
import { X, AlertCircle, MessageSquare } from 'lucide-react';
import { Link } from 'react-router-dom';
import type { PostType } from '../../types/post';

const postSchema = z.object({
  title: z.string().min(3, 'Le titre doit contenir au moins 3 caractères'),
  content: z.string().min(10, 'Le contenu doit contenir au moins 10 caractères'),
  category: z.string().min(1, 'La catégorie est requise'),
  acceptRules: z.boolean().refine(val => val === true, {
    message: 'Vous devez accepter les règles du forum'
  })
});

type PostForm = z.infer<typeof postSchema>;

interface CreatePostModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: PostType;
}

export function CreatePostModal({ isOpen, onClose, type }: CreatePostModalProps) {
  const { user } = useAuthStore();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { register, handleSubmit, reset, formState: { errors } } = useForm<PostForm>({
    resolver: zodResolver(postSchema),
    defaultValues: {
      category: 'general'
    }
  });

  const onSubmit = async (data: PostForm) => {
    if (!user) return;

    try {
      setIsSubmitting(true);
      
      // Create post
      const docRef = await addDoc(collection(db, 'forum_posts'), {
        ...data,
        type,
        authorId: user.id,
        authorName: `${user.firstName} ${user.lastName}`,
        status: 'pending',
        createdAt: new Date(),
        updatedAt: new Date()
      });

      // Translate post content
      await translationService.translatePost(docRef.id, data.title, data.content);

      // Notify all users about new post
      await notifyNewPost(
        user.id,
        `${user.firstName} ${user.lastName}`,
        data.title,
        docRef.id
      );

      // Notify author that post is pending
      await notifyPostStatus(
        user.id,
        data.title,
        docRef.id,
        false,
        'Votre publication est en attente de modération'
      );

      reset();
      onClose();
    } catch (error) {
      console.error('Error creating post:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" onClick={onClose} />

        <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
          <div className="flex items-center justify-between mb-6">
            <div>
              <h3 className="text-xl font-semibold text-gray-900">
                Nouvelle publication
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Créez une nouvelle publication
              </p>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="w-6 h-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Titre
              </label>
              <input
                type="text"
                {...register('title')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
              {errors.title && (
                <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Catégorie
              </label>
              <select
                {...register('category')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="general">Général</option>
                <option value="technique">Technique</option>
                <option value="administratif">Administratif</option>
              </select>
              {errors.category && (
                <p className="mt-1 text-sm text-red-600">{errors.category.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Contenu
              </label>
              <textarea
                {...register('content')}
                rows={6}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
              {errors.content && (
                <p className="mt-1 text-sm text-red-600">{errors.content.message}</p>
              )}
            </div>

            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  type="checkbox"
                  {...register('acceptRules')}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">
                  J'accepte les règles du forum{' '}
                  <Link to="/legal/forum-rules" className="text-blue-600 hover:text-blue-500" target="_blank">
                    (voir les règles)
                  </Link>
                </label>
                {errors.acceptRules && (
                  <p className="mt-1 text-sm text-red-600">{errors.acceptRules.message}</p>
                )}
              </div>
            </div>

            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AlertCircle className="h-5 w-5 text-blue-400" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-blue-700">
                    Votre publication sera examinée par un modérateur avant d'être visible sur le forum.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Annuler
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isSubmitting ? (
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                ) : (
                  <>
                    <MessageSquare className="h-5 w-5 mr-2" />
                    Publier
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreatePostModal;