import React from 'react';
import { Link } from 'react-router-dom';

interface TermsCheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  error?: string;
  language: string;
}

const translations = {
  FR: {
    accept: "J'accepte",
    terms: "les conditions générales d'utilisation",
    privacy: "la politique de confidentialité",
    cookies: "la politique des cookies",
    and: "et",
    details: "En créant un compte, vous acceptez nos conditions générales d'utilisation, notre politique de confidentialité et notre politique des cookies qui détaillent comment nous utilisons et protégeons vos données."
  },
  EN: {
    accept: "I accept",
    terms: "the terms of service",
    privacy: "the privacy policy",
    cookies: "the cookie policy",
    and: "and",
    details: "By creating an account, you agree to our terms of service, privacy policy and cookie policy which detail how we use and protect your data."
  },
  IT: {
    accept: "Accetto",
    terms: "i termini di servizio",
    privacy: "la politica sulla privacy",
    cookies: "la politica dei cookie",
    and: "e",
    details: "Creando un account, accetti i nostri termini di servizio, la politica sulla privacy e la politica dei cookie che descrivono come utilizziamo e proteggiamo i tuoi dati."
  }
};

export function TermsCheckbox({ checked, onChange, error, language }: TermsCheckboxProps) {
  const t = translations[language];

  return (
    <div className="space-y-4">
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id="terms"
            type="checkbox"
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3">
          <label htmlFor="terms" className="text-sm text-gray-700">
            {t.accept}{' '}
            <Link 
              to="/legal/cgu" 
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              {t.terms}
            </Link>
            {', '}
            <Link 
              to="/legal/privacy" 
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              {t.privacy}
            </Link>
            {' '}{t.and}{' '}
            <Link 
              to="/legal/cookies" 
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              {t.cookies}
            </Link>
          </label>
        </div>
      </div>

      <p className="text-sm text-gray-500">
        {t.details}
      </p>

      {error && (
        <p className="text-sm text-red-600">{error}</p>
      )}
    </div>
  );
}