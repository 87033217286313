export const categoryLabels = {
  fr: {
    all: 'Tous les documents',
    administrative: 'Documents administratifs',
    financial: 'Documents financiers',
    legal: 'Documents juridiques',
    technical: 'Documents techniques',
    other: 'Autres'
  },
  en: {
    all: 'All documents',
    administrative: 'Administrative documents',
    financial: 'Financial documents',
    legal: 'Legal documents',
    technical: 'Technical documents',
    other: 'Others'
  },
  it: {
    all: 'Tutti i documenti',
    administrative: 'Documenti amministrativi',
    financial: 'Documenti finanziari',
    legal: 'Documenti legali',
    technical: 'Documenti tecnici',
    other: 'Altri'
  }
};

export const searchLabels = {
  fr: 'Rechercher',
  en: 'Search',
  it: 'Cerca'
};

export const searchPlaceholders = {
  fr: 'Rechercher par titre ou description...',
  en: 'Search by title or description...',
  it: 'Cerca per titolo o descrizione...'
};

export const documentLabels = {
  fr: {
    title: 'Documents',
    subtitle: 'Gérez et partagez vos documents importants',
    newFolder: 'Nouveau dossier',
    addDocument: 'Ajouter un document',
    noDocuments: 'Aucun document',
    startAdding: 'Commencez par ajouter un document',
    download: 'Télécharger',
    rename: 'Renommer',
    delete: 'Supprimer',
    view: 'Voir'
  },
  en: {
    title: 'Documents',
    subtitle: 'Manage and share your important documents',
    newFolder: 'New folder',
    addDocument: 'Add document',
    noDocuments: 'No documents',
    startAdding: 'Start by adding a document',
    download: 'Download',
    rename: 'Rename',
    delete: 'Delete',
    view: 'View'
  },
  it: {
    title: 'Documenti',
    subtitle: 'Gestisci e condividi i tuoi documenti importanti',
    newFolder: 'Nuova cartella',
    addDocument: 'Aggiungi documento',
    noDocuments: 'Nessun documento',
    startAdding: 'Inizia aggiungendo un documento',
    download: 'Scarica',
    rename: 'Rinomina',
    delete: 'Elimina',
    view: 'Visualizza'
  }
};