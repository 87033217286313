import React from 'react';
import { ListFilter } from 'lucide-react';
import { clsx } from 'clsx';
import { PostType } from '../../types/post';

interface PostFiltersProps {
  selectedType: string;
  setSelectedType: (type: string) => void;
  postCounts: Record<PostType, number>;
  totalPosts: number;
  t: any;
}

export function PostFilters({ selectedType, setSelectedType, postCounts, totalPosts, t }: PostFiltersProps) {
  const types = [
    { id: 'vente_parts', icon: 'Euro' },
    { id: 'achat_parts', icon: 'Euro' },
    { id: 'echange_semaines', icon: 'ArrowLeftRight' },
    { id: 'question', icon: 'MessageSquare' },
    { id: 'discussion', icon: 'MessageSquare' }
  ];

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-sm font-medium text-gray-700 flex items-center">
          <ListFilter className="h-5 w-5 mr-2 text-gray-400" />
          {t.filterByType}
        </h2>
        <span className="text-sm text-gray-500">
          {totalPosts} {t.postsAvailable}
        </span>
      </div>

      <div className="flex flex-wrap gap-3">
        <button
          onClick={() => setSelectedType('')}
          className={clsx(
            "inline-flex items-center px-4 py-2 rounded-full text-sm font-medium transition-colors",
            !selectedType 
              ? "bg-blue-100 text-blue-800" 
              : "bg-gray-100 text-gray-700 hover:bg-gray-200"
          )}
        >
          {t.showAll} ({totalPosts})
        </button>
        {types.map((type) => (
          <button
            key={type.id}
            onClick={() => setSelectedType(type.id)}
            className={clsx(
              "inline-flex items-center px-4 py-2 rounded-full text-sm font-medium transition-colors",
              selectedType === type.id
                ? "bg-blue-100 text-blue-800"
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            )}
          >
            {t.types[type.id]} ({postCounts[type.id] || 0})
          </button>
        ))}
      </div>
    </div>
  );
}