export const forumTranslations = {
  fr: {
    title: "Forum",
    subtitle: "Échangez avec les autres membres de la SCI",
    newPost: "Nouvelle publication",
    filterByType: "Filtrer par type",
    showAll: "Tout afficher",
    noPost: "Aucune publication",
    beFirst: "Soyez le premier à créer une publication",
    contact: "Contacter",
    details: "Détails",
    postsAvailable: "publications disponibles",
    showing: "Affichage de",
    to: "à",
    of: "sur",
    results: "résultats",
    previous: "Précédent",
    next: "Suivant",
    types: {
      vente_parts: "Vente de parts",
      achat_parts: "Achat de parts",
      echange_semaines: "Échange de semaines",
      question: "Question",
      discussion: "Discussion générale"
    },
    categories: {
      general: "Général",
      technique: "Technique",
      administratif: "Administratif"
    }
  },
  en: {
    title: "Forum",
    subtitle: "Exchange with other SCI members",
    newPost: "New post",
    filterByType: "Filter by type",
    showAll: "Show all",
    noPost: "No posts",
    beFirst: "Be the first to create a post",
    contact: "Contact",
    details: "Details",
    postsAvailable: "posts available",
    showing: "Showing",
    to: "to",
    of: "of",
    results: "results",
    previous: "Previous",
    next: "Next",
    types: {
      vente_parts: "Share sale",
      achat_parts: "Share purchase",
      echange_semaines: "Week exchange",
      question: "Question",
      discussion: "General discussion"
    },
    categories: {
      general: "General",
      technique: "Technical",
      administratif: "Administrative"
    }
  },
  it: {
    title: "Forum",
    subtitle: "Scambia con gli altri membri della SCI",
    newPost: "Nuovo post",
    filterByType: "Filtra per tipo",
    showAll: "Mostra tutto",
    noPost: "Nessun post",
    beFirst: "Sii il primo a creare un post",
    contact: "Contatta",
    details: "Dettagli",
    postsAvailable: "post disponibili",
    showing: "Visualizzazione da",
    to: "a",
    of: "di",
    results: "risultati",
    previous: "Precedente",
    next: "Successivo",
    types: {
      vente_parts: "Vendita quote",
      achat_parts: "Acquisto quote",
      echange_semaines: "Scambio settimane",
      question: "Domanda",
      discussion: "Discussione generale"
    },
    categories: {
      general: "Generale",
      technique: "Tecnico",
      administratif: "Amministrativo"
    }
  }
};