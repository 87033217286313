import { useState, useEffect } from 'react';
import { useAuthStore } from '../store/authStore';
import { translationService } from '../utils/translationService';
import { decodeHtmlEntities } from '../utils/textUtils';

export function useTranslation() {
  const { user } = useAuthStore();
  const [userLanguage, setUserLanguage] = useState(user?.preferredLanguage?.toLowerCase() || 'fr');

  useEffect(() => {
    if (user?.preferredLanguage) {
      setUserLanguage(user.preferredLanguage.toLowerCase());
    }
  }, [user?.preferredLanguage]);

  const translate = async (text: string) => {
    if (!text) return '';
    return await translationService.translateText(text, userLanguage);
  };

  const getTranslatedContent = (item: any) => {
    if (!item?.translations || !item.translations[userLanguage]) {
      return {
        title: decodeHtmlEntities(item.title || ''),
        content: decodeHtmlEntities(item.content || ''),
        name: decodeHtmlEntities(item.name || ''),
        description: decodeHtmlEntities(item.description || ''),
        preventionMessage: decodeHtmlEntities(item.preventionMessage || ''),
        lastMessage: decodeHtmlEntities(item.lastMessage || '')
      };
    }

    if (typeof item.translations[userLanguage] === 'string') {
      return {
        content: decodeHtmlEntities(item.translations[userLanguage]),
        title: decodeHtmlEntities(item.title || ''),
        name: decodeHtmlEntities(item.name || ''),
        description: decodeHtmlEntities(item.description || ''),
        preventionMessage: decodeHtmlEntities(item.preventionMessage || ''),
        lastMessage: decodeHtmlEntities(item.lastMessage || '')
      };
    }

    return {
      ...item.translations[userLanguage],
      title: decodeHtmlEntities(item.translations[userLanguage].title || item.title || ''),
      content: decodeHtmlEntities(item.translations[userLanguage].content || item.content || ''),
      name: decodeHtmlEntities(item.translations[userLanguage].name || item.name || ''),
      description: decodeHtmlEntities(item.translations[userLanguage].description || item.description || ''),
      preventionMessage: decodeHtmlEntities(item.translations[userLanguage].preventionMessage || item.preventionMessage || ''),
      lastMessage: decodeHtmlEntities(item.translations[userLanguage].lastMessage || item.lastMessage || '')
    };
  };

  return {
    translate,
    getTranslatedContent,
    userLanguage
  };
}