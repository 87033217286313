import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { CheckCircle, XCircle, AlertCircle } from 'lucide-react';
import { notifyPostStatus } from '../../utils/notifications';

interface Post {
  id: string;
  title: string;
  content: string;
  authorId: string;
  authorName: string;
  category: string;
  type: string;
  status: 'pending' | 'approved' | 'rejected';
  createdAt: Date;
}

export function ForumModeration() {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(
      collection(db, 'forum_posts'),
      where('status', '==', 'pending'),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const postsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      })) as Post[];
      setPosts(postsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleModeration = async (post: Post, decision: 'approved' | 'rejected', reason?: string) => {
    try {
      const postRef = doc(db, 'forum_posts', post.id);
      await updateDoc(postRef, {
        status: decision,
        ...(reason && { rejectionReason: reason }),
        moderatedAt: new Date()
      });

      // Send notification to post author
      await notifyPostStatus(
        post.authorId,
        post.title,
        post.id,
        decision === 'approved',
        reason
      );
    } catch (error) {
      console.error('Erreur lors de la modération:', error);
      alert('Erreur lors de la modération de la publication');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (posts.length === 0) {
    return (
      <div className="text-center py-12">
        <AlertCircle className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          Aucune publication en attente
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Toutes les publications ont été modérées.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-2xl font-bold text-gray-900">Modération du forum</h2>
        <p className="mt-1 text-sm text-gray-500">
          {posts.length} publication(s) en attente de modération
        </p>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <ul className="divide-y divide-gray-200">
          {posts.map((post) => (
            <li key={post.id} className="p-6">
              <div className="space-y-4">
                <div>
                  <h3 className="text-lg font-medium text-gray-900">{post.title}</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Par {post.authorName} • {format(post.createdAt, 'dd MMMM yyyy à HH:mm', { locale: fr })}
                  </p>
                </div>

                <div className="text-gray-700">{post.content}</div>

                <div className="flex items-center space-x-4 text-sm">
                  <span className="text-gray-500">Type: {post.type}</span>
                  <span className="text-gray-500">Catégorie: {post.category}</span>
                </div>

                <div className="flex space-x-4">
                  <button
                    onClick={() => handleModeration(post, 'approved')}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    <CheckCircle className="h-5 w-5 mr-2" />
                    Approuver
                  </button>
                  <button
                    onClick={() => {
                      const reason = prompt('Motif du rejet:');
                      if (reason) {
                        handleModeration(post, 'rejected', reason);
                      }
                    }}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    <XCircle className="h-5 w-5 mr-2" />
                    Rejeter
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}