import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Settings as SettingsIcon, Save, AlertTriangle, Globe } from 'lucide-react';
import { translationService } from '../../utils/translationService';
import { useAuthStore } from '../../store/authStore';

interface Settings {
  preventionMessage: string;
  translations?: {
    [key: string]: {
      preventionMessage: string;
    }
  };
}

export function Settings() {
  const { user } = useAuthStore();
  const [preventionMessage, setPreventionMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [translating, setTranslating] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsRef = doc(db, 'settings', 'general');
        const settingsDoc = await getDoc(settingsRef);
        
        if (settingsDoc.exists()) {
          setPreventionMessage(settingsDoc.data().preventionMessage || '');
        } else {
          // Create settings document if it doesn't exist
          await setDoc(settingsRef, {
            preventionMessage: '',
            updatedAt: new Date()
          });
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);
    try {
      const settingsRef = doc(db, 'settings', 'general');
      
      // Translate prevention message
      const translations = {};
      const languages = ['fr', 'en', 'it'];
      
      await Promise.all(
        languages.map(async (lang) => {
          translations[lang] = {
            preventionMessage: await translationService.translateText(preventionMessage, lang)
          };
        })
      );

      await updateDoc(settingsRef, {
        preventionMessage: preventionMessage.trim(),
        translations,
        updatedAt: new Date()
      });
      
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
    } catch (error) {
      console.error('Error updating settings:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleTranslateAll = async () => {
    try {
      setTranslating(true);
      await translationService.translateAllExistingContent();
      alert('Tout le contenu a été traduit avec succès !');
    } catch (error) {
      console.error('Error translating content:', error);
      alert('Une erreur est survenue lors de la traduction');
    } finally {
      setTranslating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-2xl font-bold text-gray-900">Paramètres</h2>
        <p className="mt-1 text-sm text-gray-500">
          Gérez les paramètres généraux de la plateforme
        </p>
      </div>

      <div className="bg-white shadow rounded-lg">
        <div className="p-6">
          <h3 className="text-lg font-medium text-gray-900 flex items-center mb-6">
            <SettingsIcon className="h-5 w-5 text-gray-400 mr-2" />
            Message de prévention
          </h3>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Message affiché sur le tableau de bord
              </label>
              <div className="mt-1">
                <textarea
                  value={preventionMessage}
                  onChange={(e) => setPreventionMessage(e.target.value)}
                  rows={4}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Entrez votre message de prévention..."
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Ce message sera affiché en haut du tableau de bord pour tous les utilisateurs.
              </p>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <AlertTriangle className="h-5 w-5 text-yellow-500 mr-2" />
                <span className="text-sm text-gray-500">
                  Le message sera mis à jour immédiatement pour tous les utilisateurs.
                </span>
              </div>
              <button
                type="submit"
                disabled={saving}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {saving ? (
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                ) : (
                  <>
                    <Save className="h-5 w-5 mr-2" />
                    Enregistrer
                  </>
                )}
              </button>
            </div>

            {success && (
              <div className="rounded-md bg-green-50 p-4">
                <div className="flex">
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">
                      Message de prévention mis à jour avec succès
                    </p>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 flex items-center mb-6">
          <Globe className="h-5 w-5 text-gray-400 mr-2" />
          Traductions
        </h3>

        <button
          onClick={handleTranslateAll}
          disabled={translating}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
        >
          {translating ? (
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
          ) : (
            <>
              <Globe className="h-5 w-5 mr-2" />
              Traduire tout le contenu existant
            </>
          )}
        </button>
        <p className="mt-2 text-sm text-gray-500">
          Cette action traduira automatiquement tout le contenu existant (publications, documents, messages) dans toutes les langues supportées.
        </p>
      </div>
    </div>
  );
}

export default Settings;