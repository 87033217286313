import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuthStore } from '../../store/authStore';
import { format } from 'date-fns';
import { fr, enGB, it } from 'date-fns/locale';
import { 
  Users, 
  Search,
  Download,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import { clsx } from 'clsx';

const translations = {
  fr: {
    title: "Gestion des utilisateurs",
    subtitle: "utilisateurs enregistrés",
    search: {
      label: "Rechercher",
      placeholder: "Rechercher par nom, email..."
    },
    filter: {
      label: "Filtrer par rôle",
      all: "Tous les rôles",
      member: "Membre",
      council: "Conseil",
      admin: "Admin",
      superadmin: "Super Admin"
    },
    status: {
      online: "En ligne",
      offline: "Hors ligne"
    },
    export: "Exporter les données",
    pagination: {
      showing: "Affichage de",
      to: "à",
      of: "sur",
      results: "résultats",
      previous: "Précédent",
      next: "Suivant"
    }
  },
  en: {
    title: "User Management",
    subtitle: "registered users",
    search: {
      label: "Search",
      placeholder: "Search by name, email..."
    },
    filter: {
      label: "Filter by role",
      all: "All roles",
      member: "Member",
      council: "Council",
      admin: "Admin",
      superadmin: "Super Admin"
    },
    status: {
      online: "Online",
      offline: "Offline"
    },
    export: "Export data",
    pagination: {
      showing: "Showing",
      to: "to",
      of: "of",
      results: "results",
      previous: "Previous",
      next: "Next"
    }
  },
  it: {
    title: "Gestione utenti",
    subtitle: "utenti registrati",
    search: {
      label: "Cerca",
      placeholder: "Cerca per nome, email..."
    },
    filter: {
      label: "Filtra per ruolo",
      all: "Tutti i ruoli",
      member: "Membro",
      council: "Consiglio",
      admin: "Admin",
      superadmin: "Super Admin"
    },
    status: {
      online: "Online",
      offline: "Offline"
    },
    export: "Esporta dati",
    pagination: {
      showing: "Visualizzazione da",
      to: "a",
      of: "di",
      results: "risultati",
      previous: "Precedente",
      next: "Successivo"
    }
  }
};

const getDateLocale = (language: string) => {
  switch (language) {
    case 'en': return enGB;
    case 'it': return it;
    default: return fr;
  }
};

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  createdAt: Date | null;
  lastSeen: Date | null;
  isOnline: boolean;
  phone: string;
  address: string;
  city: string;
  postalCode: string;
}

export function UserManagement() {
  const { user, isAdmin } = useAuthStore();
  const [users, setUsers] = useState<User[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [roleFilter, setRoleFilter] = useState('all');
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;

  const userLanguage = user?.preferredLanguage?.toLowerCase() || 'fr';
  const t = translations[userLanguage];
  const dateLocale = getDateLocale(userLanguage);

  useEffect(() => {
    const q = query(
      collection(db, 'users'),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const usersData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || null,
        lastSeen: doc.data().lastSeen?.toDate() || null
      })) as User[];
      setUsers(usersData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const updateUserRole = async (userId: string, newRole: string) => {
    try {
      await updateDoc(doc(db, 'users', userId), {
        role: newRole,
        updatedAt: new Date()
      });
    } catch (error) {
      console.error('Error updating user role:', error);
    }
  };

  const filteredUsers = users.filter(user => {
    const matchesSearch = 
      user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase());
    
    const matchesRole = roleFilter === 'all' || user.role === roleFilter;
    
    return matchesSearch && matchesRole;
  });

  // Pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const exportUsers = () => {
    const csv = [
      ['ID', 'Prénom', 'Nom', 'Email', 'Rôle', 'Téléphone', 'Adresse', 'Code Postal', 'Ville', 'Inscrit le'],
      ...filteredUsers.map(user => [
        user.id,
        user.firstName,
        user.lastName,
        user.email,
        user.role,
        user.phone || '',
        user.address || '',
        user.postalCode || '',
        user.city || '',
        user.createdAt ? format(user.createdAt, 'dd/MM/yyyy', { locale: dateLocale }) : ''
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `users_export_${format(new Date(), 'yyyy-MM-dd')}.csv`;
    link.click();
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">{t.title}</h2>
          <p className="mt-1 text-sm text-gray-500">
            {users.length} {t.subtitle}
          </p>
        </div>
        {isAdmin() && (
          <div className="mt-4 sm:mt-0 sm:flex sm:space-x-3">
            <button
              onClick={exportUsers}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Download className="h-5 w-5 mr-2" />
              {t.export}
            </button>
          </div>
        )}
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t.search.label}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder={t.search.placeholder}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t.filter.label}
            </label>
            <select
              value={roleFilter}
              onChange={(e) => setRoleFilter(e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
              <option value="all">{t.filter.all}</option>
              <option value="member">{t.filter.member}</option>
              <option value="council">{t.filter.council}</option>
              <option value="admin">{t.filter.admin}</option>
              <option value="superadmin">{t.filter.superadmin}</option>
            </select>
          </div>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t.search.label}
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t.filter.label}
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentUsers.map((userData) => (
              <tr key={userData.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={`https://ui-avatars.com/api/?name=${userData.firstName}+${userData.lastName}&background=random`}
                        alt=""
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {userData.firstName} {userData.lastName}
                      </div>
                      <div className="text-sm text-gray-500">
                        {userData.email}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {isAdmin() ? (
                    <select
                      value={userData.role}
                      onChange={(e) => updateUserRole(userData.id, e.target.value)}
                      className="text-sm border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="member">{t.filter.member}</option>
                      <option value="council">{t.filter.council}</option>
                      <option value="admin">{t.filter.admin}</option>
                      <option value="superadmin">{t.filter.superadmin}</option>
                    </select>
                  ) : (
                    <span className="text-sm text-gray-900">
                      {t.filter[userData.role]}
                    </span>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={clsx(
                    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
                    userData.isOnline
                      ? "bg-green-100 text-green-800"
                      : "bg-gray-100 text-gray-800"
                  )}>
                    {userData.isOnline ? t.status.online : t.status.offline}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {userData.createdAt ? format(userData.createdAt, 'dd/MM/yyyy', { locale: dateLocale }) : 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
              >
                {t.pagination.previous}
              </button>
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
              >
                {t.pagination.next}
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  {t.pagination.showing}{' '}
                  <span className="font-medium">{indexOfFirstUser + 1}</span>
                  {' '}{t.pagination.to}{' '}
                  <span className="font-medium">
                    {Math.min(indexOfLastUser, filteredUsers.length)}
                  </span>
                  {' '}{t.pagination.of}{' '}
                  <span className="font-medium">{filteredUsers.length}</span>
                  {' '}{t.pagination.results}
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                  <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                  >
                    <span className="sr-only">{t.pagination.previous}</span>
                    <ChevronLeft className="h-5 w-5" />
                  </button>
                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index}
                      onClick={() => paginate(index + 1)}
                      className={clsx(
                        "relative inline-flex items-center px-4 py-2 border text-sm font-medium",
                        currentPage === index + 1
                          ? "z-10 bg-blue-50 border-blue-500 text-blue-600"
                          : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                      )}
                    >
                      {index + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                  >
                    <span className="sr-only">{t.pagination.next}</span>
                    <ChevronRight className="h-5 w-5" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserManagement;
