import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuthStore } from '../../store/authStore';
import { format } from 'date-fns';
import { fr, enGB, it } from 'date-fns/locale';
import { translationService } from '../../utils/translationService';
import { MessageSquare, Mail, AlertCircle, Check, X } from 'lucide-react';
import { clsx } from 'clsx';

const translations = {
  fr: {
    title: "Messages d'assistance",
    subtitle: "Gérez les demandes d'assistance des utilisateurs",
    noTickets: "Aucun ticket d'assistance",
    status: {
      pending: "En attente",
      in_progress: "En cours",
      resolved: "Résolu"
    },
    priority: {
      low: "Basse",
      medium: "Moyenne",
      high: "Haute"
    },
    response: {
      title: "Réponse du support",
      placeholder: "Écrivez votre réponse...",
      submit: "Répondre",
      success: "Réponse envoyée avec succès"
    }
  },
  en: {
    title: "Support Messages",
    subtitle: "Manage user support requests",
    noTickets: "No support tickets",
    status: {
      pending: "Pending",
      in_progress: "In Progress",
      resolved: "Resolved"
    },
    priority: {
      low: "Low",
      medium: "Medium",
      high: "High"
    },
    response: {
      title: "Support Response",
      placeholder: "Write your response...",
      submit: "Respond",
      success: "Response sent successfully"
    }
  },
  it: {
    title: "Messaggi di Assistenza",
    subtitle: "Gestisci le richieste di assistenza degli utenti",
    noTickets: "Nessun ticket di assistenza",
    status: {
      pending: "In attesa",
      in_progress: "In corso",
      resolved: "Risolto"
    },
    priority: {
      low: "Bassa",
      medium: "Media",
      high: "Alta"
    },
    response: {
      title: "Risposta del supporto",
      placeholder: "Scrivi la tua risposta...",
      submit: "Rispondi",
      success: "Risposta inviata con successo"
    }
  }
};

const getDateLocale = (language: string) => {
  switch (language) {
    case 'en': return enGB;
    case 'it': return it;
    default: return fr;
  }
};

export function SupportTickets() {
  const { user } = useAuthStore();
  const [tickets, setTickets] = useState<any[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userLanguage = user?.preferredLanguage?.toLowerCase() || 'fr';
  const t = translations[userLanguage];
  const dateLocale = getDateLocale(userLanguage);

  useEffect(() => {
    const q = query(
      collection(db, 'support_tickets'),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const ticketsData = await Promise.all(snapshot.docs.map(async (doc) => {
        const data = doc.data();
        let subject = data.subject;
        let message = data.message;
        let response = data.response;

        // Translate if needed
        if (!data.translations?.[userLanguage]) {
          subject = await translationService.translateText(data.subject, userLanguage);
          message = await translationService.translateText(data.message, userLanguage);
          if (response) {
            response = await translationService.translateText(response, userLanguage);
          }
        } else {
          subject = data.translations[userLanguage].subject;
          message = data.translations[userLanguage].message;
          response = data.translations[userLanguage].response;
        }

        return {
          id: doc.id,
          ...data,
          subject,
          message,
          response,
          createdAt: data.createdAt?.toDate(),
          respondedAt: data.respondedAt?.toDate()
        };
      }));

      setTickets(ticketsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [userLanguage]);

  const handleRespond = async () => {
    if (!selectedTicket || !response.trim()) return;

    try {
      setIsSubmitting(true);

      // Create translations for all supported languages
      const translations = {};
      const languages = ['fr', 'en', 'it'];
      
      await Promise.all(
        languages.map(async (lang) => {
          translations[lang] = {
            subject: selectedTicket.translations?.[lang]?.subject || selectedTicket.subject,
            message: selectedTicket.translations?.[lang]?.message || selectedTicket.message,
            response: await translationService.translateText(response, lang)
          };
        })
      );

      await updateDoc(doc(db, 'support_tickets', selectedTicket.id), {
        status: 'resolved',
        response,
        translations,
        respondedAt: new Date(),
        respondedBy: user?.id
      });

      setSelectedTicket(null);
      setResponse('');
    } catch (error) {
      console.error('Error responding to ticket:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-2xl font-bold text-gray-900">{t.title}</h2>
        <p className="mt-1 text-sm text-gray-500">{t.subtitle}</p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Tickets List */}
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-4 border-b border-gray-200">
            <h3 className="text-lg font-medium text-gray-900">
              {tickets.length} {tickets.length === 1 ? 'ticket' : 'tickets'}
            </h3>
          </div>

          <div className="divide-y divide-gray-200 max-h-[600px] overflow-y-auto">
            {tickets.length === 0 ? (
              <div className="p-4 text-center text-gray-500">
                {t.noTickets}
              </div>
            ) : (
              tickets.map((ticket) => (
                <button
                  key={ticket.id}
                  onClick={() => setSelectedTicket(ticket)}
                  className={clsx(
                    "w-full p-4 text-left hover:bg-gray-50 transition-colors",
                    selectedTicket?.id === ticket.id && "bg-blue-50"
                  )}
                >
                  <div className="flex justify-between items-start">
                    <div className="flex-1">
                      <h4 className="text-sm font-medium text-gray-900">
                        {ticket.subject}
                      </h4>
                      <p className="mt-1 text-sm text-gray-500 line-clamp-2">
                        {ticket.message}
                      </p>
                      <div className="mt-2 flex items-center space-x-2 text-xs text-gray-500">
                        <span>{ticket.userName}</span>
                        <span>•</span>
                        <span>
                          {format(ticket.createdAt, 'dd MMMM yyyy HH:mm', { locale: dateLocale })}
                        </span>
                      </div>
                    </div>
                    <div className="ml-4 flex flex-col items-end space-y-2">
                      <span className={clsx(
                        "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                        ticket.status === 'resolved' ? "bg-green-100 text-green-800" :
                        ticket.status === 'in_progress' ? "bg-yellow-100 text-yellow-800" :
                        "bg-gray-100 text-gray-800"
                      )}>
                        {t.status[ticket.status]}
                      </span>
                      <span className={clsx(
                        "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                        ticket.priority === 'high' ? "bg-red-100 text-red-800" :
                        ticket.priority === 'medium' ? "bg-yellow-100 text-yellow-800" :
                        "bg-green-100 text-green-800"
                      )}>
                        {t.priority[ticket.priority]}
                      </span>
                    </div>
                  </div>
                </button>
              ))
            )}
          </div>
        </div>

        {/* Ticket Details */}
        <div className="bg-white shadow rounded-lg">
          {selectedTicket ? (
            <div className="p-6 flex flex-col h-full">
              <div className="mb-6">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-medium text-gray-900">
                    {selectedTicket.subject}
                  </h3>
                  <div className="flex space-x-2">
                    <span className={clsx(
                      "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                      selectedTicket.priority === 'high' ? "bg-red-100 text-red-800" :
                      selectedTicket.priority === 'medium' ? "bg-yellow-100 text-yellow-800" :
                      "bg-green-100 text-green-800"
                    )}>
                      {t.priority[selectedTicket.priority]}
                    </span>
                    <span className={clsx(
                      "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                      selectedTicket.status === 'resolved' ? "bg-green-100 text-green-800" :
                      selectedTicket.status === 'in_progress' ? "bg-yellow-100 text-yellow-800" :
                      "bg-gray-100 text-gray-800"
                    )}>
                      {t.status[selectedTicket.status]}
                    </span>
                  </div>
                </div>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <Mail className="h-4 w-4 mr-1" />
                  {selectedTicket.userEmail}
                </div>
              </div>

              <div className="bg-gray-50 rounded-lg p-4 mb-6">
                <p className="text-gray-700 whitespace-pre-wrap">
                  {selectedTicket.message}
                </p>
              </div>

              {selectedTicket.status !== 'resolved' ? (
                <div className="mt-auto">
                  <label className="block text-sm font-medium text-gray-700">
                    {t.response.title}
                  </label>
                  <textarea
                    value={response}
                    onChange={(e) => setResponse(e.target.value)}
                    rows={4}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder={t.response.placeholder}
                  />
                  <div className="mt-4 flex justify-end">
                    <button
                      onClick={handleRespond}
                      disabled={!response.trim() || isSubmitting}
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                    >
                      {isSubmitting ? (
                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                      ) : (
                        <>
                          <MessageSquare className="h-5 w-5 mr-2" />
                          {t.response.submit}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mt-6 bg-green-50 rounded-lg p-4">
                  <div className="flex items-center">
                    <Check className="h-5 w-5 text-green-400" />
                    <h4 className="ml-2 text-sm font-medium text-green-800">
                      {t.status.resolved}
                    </h4>
                  </div>
                  {selectedTicket.response && (
                    <div className="mt-2">
                      <p className="text-sm text-green-700 whitespace-pre-wrap">
                        {selectedTicket.response}
                      </p>
                      <p className="mt-2 text-xs text-green-600">
                        {format(selectedTicket.respondedAt, 'dd MMMM yyyy HH:mm', { locale: dateLocale })}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="p-6 text-center">
              <MessageSquare className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                {t.noTickets}
              </h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SupportTickets;