import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { X, Edit } from 'lucide-react';
import { cloudinaryService } from '../../utils/cloudinaryService';
import type { DriveItem } from '../../types/drive';

const renameSchema = z.object({
  name: z.string().min(1, 'Le nom est requis'),
});

type RenameForm = z.infer<typeof renameSchema>;

interface RenameItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  item: DriveItem;
}

export function RenameItemModal({ isOpen, onClose, item }: RenameItemModalProps) {
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<RenameForm>({
    resolver: zodResolver(renameSchema),
    defaultValues: {
      name: item.name,
    },
  });

  const onSubmit = async (data: RenameForm) => {
    try {
      if (item.type === 'file') {
        await cloudinaryService.updateFile(item.id, {
          name: data.name,
        });
      } else {
        // Mettre à jour le nom du dossier
        await cloudinaryService.updateFolder(item.id, {
          name: data.name,
        });
      }
      onClose();
    } catch (error) {
      console.error('Error renaming item:', error);
      alert('Erreur lors du renommage');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" onClick={onClose} />

        <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900">
              Renommer {item.type === 'file' ? 'le fichier' : 'le dossier'}
            </h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="w-6 h-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Nouveau nom
              </label>
              <input
                type="text"
                {...register('name')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
              )}
            </div>

            <div className="mt-5 sm:mt-6 flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Annuler
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                <Edit className="w-5 h-5 mr-2" />
                Renommer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RenameItemModal;