import { useState, useEffect } from 'react';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  onSnapshot,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  limit as firestoreLimit,
  startAfter,
  QueryDocumentSnapshot,
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthStore } from '../store/authStore';
import OpenAI from 'openai';
import { SYSTEM_PROMPT } from '../constants/aiPrompts';
import type { Message } from '../types/chat';

const MESSAGES_PER_PAGE = 5;

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export function useAIAssistant() {
  const { user } = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const [messageHistory, setMessageHistory] = useState<Message[]>([]);
  const [lastMessageDoc, setLastMessageDoc] = useState<QueryDocumentSnapshot | null>(null);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (!user?.id) return;

    const q = query(
      collection(db, 'chat_messages'),
      where('userId', '==', user.id),
      orderBy('timestamp', 'desc'),
      firestoreLimit(MESSAGES_PER_PAGE)
    );
    
    const unsubscribe = onSnapshot(q, 
      (snapshot) => {
        if (!snapshot.empty) {
          setLastMessageDoc(snapshot.docs[snapshot.docs.length - 1]);
        }

        const messages = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp?.toDate() || new Date()
          }))
          .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()) as Message[];
        
        setMessageHistory(messages);
        setHasMore(snapshot.docs.length === MESSAGES_PER_PAGE);
      },
      (error) => {
        console.error('Error loading chat history:', error);
      }
    );

    return () => unsubscribe();
  }, [user?.id]);

  const loadMoreMessages = async () => {
    if (!user?.id || !lastMessageDoc || !hasMore) return;

    try {
      const q = query(
        collection(db, 'chat_messages'),
        where('userId', '==', user.id),
        orderBy('timestamp', 'desc'),
        startAfter(lastMessageDoc),
        firestoreLimit(MESSAGES_PER_PAGE)
      );

      const snapshot = await getDocs(q);
      
      if (!snapshot.empty) {
        setLastMessageDoc(snapshot.docs[snapshot.docs.length - 1]);
        
        const newMessages = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp?.toDate() || new Date()
          })) as Message[];

        setMessageHistory(prev => [...prev, ...newMessages].sort(
          (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
        ));
      }

      setHasMore(snapshot.docs.length === MESSAGES_PER_PAGE);
    } catch (error) {
      console.error('Error loading more messages:', error);
    }
  };

  const sendMessage = async (message: string) => {
    if (!user?.id) return;

    try {
      setIsLoading(true);

      // Save user message
      const userMessage: Omit<Message, 'id'> = {
        role: 'user',
        content: message,
        timestamp: new Date(),
        userId: user.id
      };

      const userMessageRef = await addDoc(collection(db, 'chat_messages'), {
        ...userMessage,
        timestamp: serverTimestamp()
      });

      // Get AI response using last 5 messages for context
      const completion = await openai.chat.completions.create({
        messages: [
          { role: 'system', content: SYSTEM_PROMPT },
          ...messageHistory.slice(-5).map(msg => ({
            role: msg.role,
            content: msg.content
          })),
          { role: 'user', content: message }
        ],
        model: 'gpt-4',
        temperature: 0.7,
        max_tokens: 150
      });

      const response = completion.choices[0]?.message?.content || 
        'Désolé, je ne peux pas répondre pour le moment.';

      // Save assistant message
      const assistantMessage: Omit<Message, 'id'> = {
        role: 'assistant',
        content: response,
        timestamp: new Date(),
        userId: user.id
      };

      await addDoc(collection(db, 'chat_messages'), {
        ...assistantMessage,
        timestamp: serverTimestamp()
      });

      return response;
    } catch (error) {
      console.error('Error in AI conversation:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const clearHistory = async () => {
    if (!user?.id) return;
    
    try {
      const q = query(
        collection(db, 'chat_messages'),
        where('userId', '==', user.id)
      );
      
      const snapshot = await getDocs(q);
      const deletePromises = snapshot.docs.map(doc => 
        deleteDoc(doc.ref)
      );
      
      await Promise.all(deletePromises);
      setMessageHistory([]);
      setLastMessageDoc(null);
      setHasMore(true);
    } catch (error) {
      console.error('Error clearing chat history:', error);
    }
  };

  return {
    sendMessage,
    isLoading,
    messageHistory,
    clearHistory,
    loadMoreMessages,
    hasMore
  };
}