import React from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { X, Calendar, Tag, User } from 'lucide-react';

interface Post {
  id: string;
  title: string;
  content: string;
  type: string;
  category: string;
  authorId: string;
  authorName: string;
  createdAt: Date;
  status: string;
}

interface ViewPostModalProps {
  isOpen: boolean;
  onClose: () => void;
  post: Post;
}

const getTypeLabel = (type: string) => {
  switch (type) {
    case 'vente_parts': return 'Vente de parts';
    case 'achat_parts': return 'Achat de parts';
    case 'echange_semaines': return 'Échange de semaines';
    case 'question': return 'Question';
    case 'discussion': return 'Discussion générale';
    default: return type;
  }
};

export function AdminViewPostModal({ isOpen, onClose, post }: ViewPostModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" onClick={onClose} />

        <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900">
              Détails de la publication
            </h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="space-y-4">
            <div>
              <h4 className="text-xl font-semibold text-gray-900">{post.title}</h4>
              <div className="flex items-center space-x-4 text-sm text-gray-500 mt-2">
                <div className="flex items-center">
                  <Calendar className="h-4 w-4 mr-1" />
                  {format(post.createdAt, 'dd MMMM yyyy à HH:mm', { locale: fr })}
                </div>
                <div className="flex items-center">
                  <Tag className="h-4 w-4 mr-1" />
                  {getTypeLabel(post.type)}
                </div>
                <div className="flex items-center">
                  <User className="h-4 w-4 mr-1" />
                  {post.authorName}
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="bg-gray-50 rounded-lg p-4">
                <p className="text-gray-700 whitespace-pre-wrap">
                  {post.content}
                </p>
              </div>
            </div>

            <div className="mt-4 pt-4 border-t border-gray-200">
              <h5 className="text-sm font-medium text-gray-700 mb-2">Informations supplémentaires</h5>
              <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                <div>
                  <dt className="text-sm font-medium text-gray-500">ID de la publication</dt>
                  <dd className="mt-1 text-sm text-gray-900">{post.id}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">ID de l'auteur</dt>
                  <dd className="mt-1 text-sm text-gray-900">{post.authorId}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Catégorie</dt>
                  <dd className="mt-1 text-sm text-gray-900">{post.category}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Statut</dt>
                  <dd className="mt-1 text-sm text-gray-900">{post.status}</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminViewPostModal;