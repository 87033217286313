import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthStore } from '../store/authStore';
import { translationService } from '../utils/translationService';
import { X, AlertCircle, MessageCircle, Euro, ArrowLeftRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const marketplacePostSchema = z.object({
  title: z.string().min(3, 'Le titre doit contenir au moins 3 caractères'),
  content: z.string().min(10, 'Le contenu doit contenir au moins 10 caractères'),
  acceptRules: z.boolean().refine(val => val === true, {
    message: 'Vous devez accepter les règles du forum'
  })
});

type MarketplacePostForm = z.infer<typeof marketplacePostSchema>;

interface MarketplacePostModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: 'vente_parts' | 'achat_parts' | 'echange_semaines' | 'question' | 'discussion';
}

const translations = {
  fr: {
    types: {
      vente_parts: {
        title: 'Proposer ses parts',
        description: 'Décrivez les parts que vous souhaitez vendre'
      },
      achat_parts: {
        title: 'Acheter des parts',
        description: 'Décrivez les parts que vous recherchez'
      },
      echange_semaines: {
        title: 'Échanger ses semaines',
        description: 'Décrivez les semaines que vous souhaitez échanger'
      },
      question: {
        title: 'Poser une question',
        description: 'Posez votre question à la communauté'
      },
      discussion: {
        title: 'Nouvelle discussion',
        description: 'Démarrez une discussion avec la communauté'
      }
    },
    form: {
      title: 'Titre',
      content: 'Description détaillée',
      submit: 'Publier',
      cancel: 'Annuler',
      moderationNotice: 'Votre publication sera examinée par un modérateur avant d\'être visible sur le forum.',
      rulesAcceptance: 'J\'accepte les règles du forum et la politique d\'utilisation des données',
      rulesError: 'Vous devez accepter les règles du forum pour continuer'
    }
  },
  en: {
    types: {
      vente_parts: {
        title: 'Sell shares',
        description: 'Describe the shares you want to sell'
      },
      achat_parts: {
        title: 'Buy shares',
        description: 'Describe the shares you are looking for'
      },
      echange_semaines: {
        title: 'Exchange weeks',
        description: 'Describe the weeks you want to exchange'
      },
      question: {
        title: 'Ask a question',
        description: 'Ask your question to the community'
      },
      discussion: {
        title: 'New discussion',
        description: 'Start a discussion with the community'
      }
    },
    form: {
      title: 'Title',
      content: 'Detailed description',
      submit: 'Publish',
      cancel: 'Cancel',
      moderationNotice: 'Your post will be reviewed by a moderator before being visible on the forum.',
      rulesAcceptance: 'I accept the forum rules and data usage policy',
      rulesError: 'You must accept the forum rules to continue'
    }
  },
  it: {
    types: {
      vente_parts: {
        title: 'Vendere quote',
        description: 'Descrivi le quote che vuoi vendere'
      },
      achat_parts: {
        title: 'Acquistare quote',
        description: 'Descrivi le quote che stai cercando'
      },
      echange_semaines: {
        title: 'Scambiare settimane',
        description: 'Descrivi le settimane che vuoi scambiare'
      },
      question: {
        title: 'Fare una domanda',
        description: 'Fai la tua domanda alla comunità'
      },
      discussion: {
        title: 'Nuova discussione',
        description: 'Avvia una discussione con la comunità'
      }
    },
    form: {
      title: 'Titolo',
      content: 'Descrizione dettagliata',
      submit: 'Pubblica',
      cancel: 'Annulla',
      moderationNotice: 'Il tuo post sarà esaminato da un moderatore prima di essere visibile sul forum.',
      rulesAcceptance: 'Accetto le regole del forum e la politica di utilizzo dei dati',
      rulesError: 'Devi accettare le regole del forum per continuare'
    }
  }
};

export function MarketplacePostModal({ isOpen, onClose, type }: MarketplacePostModalProps) {
  const { user } = useAuthStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userLanguage = user?.preferredLanguage?.toLowerCase() || 'fr';
  const t = translations[userLanguage];

  const { register, handleSubmit, reset, formState: { errors } } = useForm<MarketplacePostForm>({
    resolver: zodResolver(marketplacePostSchema),
  });

  const onSubmit = async (data: MarketplacePostForm) => {
    if (!user) return;

    try {
      setIsSubmitting(true);
      
      // Create post first
      const docRef = await addDoc(collection(db, 'forum_posts'), {
        ...data,
        type,
        authorId: user.id,
        authorName: `${user.firstName} ${user.lastName}`,
        status: 'pending',
        createdAt: new Date(),
        updatedAt: new Date()
      });

      // Then translate title and content
      await translationService.translatePost(docRef.id, data.title, data.content);

      reset();
      onClose();
    } catch (error) {
      console.error('Error creating post:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" onClick={onClose} />

        <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
          <div className="flex items-center justify-between mb-6">
            <div>
              <h3 className="text-xl font-semibold text-gray-900">
                {t.types[type].title}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {t.types[type].description}
              </p>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="w-6 h-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t.form.title}
              </label>
              <input
                type="text"
                {...register('title')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
              {errors.title && (
                <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t.form.content}
              </label>
              <textarea
                {...register('content')}
                rows={6}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
              {errors.content && (
                <p className="mt-1 text-sm text-red-600">{errors.content.message}</p>
              )}
            </div>

            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  type="checkbox"
                  {...register('acceptRules')}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">
                  {t.form.rulesAcceptance}{' '}
                  <Link to="/legal/forum-rules" className="text-blue-600 hover:text-blue-500" target="_blank">
                    (voir les règles)
                  </Link>
                </label>
                {errors.acceptRules && (
                  <p className="mt-1 text-sm text-red-600">{errors.acceptRules.message}</p>
                )}
              </div>
            </div>

            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AlertCircle className="h-5 w-5 text-blue-400" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-blue-700">
                    {t.form.moderationNotice}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                {t.form.cancel}
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isSubmitting ? (
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                ) : (
                  t.form.submit
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default MarketplacePostModal;