import React from 'react';
import { useAuthStore } from '../../store/authStore';
import { LegalPageLayout } from '../../components/legal/LegalPageLayout';
import { Mail, Building, Shield, Book, Scale } from 'lucide-react';

export function TermsOfService() {
  const { user } = useAuthStore();
  const language = user?.preferredLanguage?.toLowerCase() || 'fr';

  const content = {
    fr: {
      title: "Conditions d'Utilisation du Site",
      intro: "Les présentes conditions d'utilisation (ci-après les \"Conditions\") régissent votre utilisation du site internet de SAS Kingstoring (ci-après \"le Site\"). En accédant et en utilisant le Site, vous acceptez d'être lié par ces Conditions. Si vous n'acceptez pas ces Conditions, veuillez ne pas utiliser le Site.",
      sections: [
        {
          title: "1. Acceptation des Conditions",
          content: "En utilisant le Site, vous acceptez les présentes Conditions d'utilisation dans leur intégralité. Nous nous réservons le droit de modifier ces Conditions à tout moment et sans préavis. Les modifications seront publiées sur cette page et prendront effet dès leur publication. Il vous incombe de consulter régulièrement cette page pour être informé des éventuelles modifications."
        },
        {
          title: "2. Utilisation du Site",
          subsections: [
            {
              subtitle: "Accès et Utilisation",
              content: "Vous devez avoir au moins 18 ans et être associé de la SCI Club Expo 1 ou représenter légalement un associé pour utiliser ce Site. Vous vous engagez à utiliser le Site conformément à la loi et aux présentes Conditions. Vous acceptez de ne pas utiliser le Site à des fins illégales ou non autorisées."
            },
            {
              subtitle: "Compte Utilisateur",
              content: "Pour accéder à certaines fonctionnalités du Site, vous devez créer un compte utilisateur. Vous êtes responsable de la confidentialité de vos identifiants de connexion et de toutes les activités effectuées sous votre compte."
            }
          ]
        }
      ],
      contact: {
        title: "Contact",
        content: "Pour toute question ou préoccupation concernant ces conditions, veuillez nous contacter à :",
        company: "SAS Kingstoring",
        address: "20 Avenue Jean Medecin, 06000 Nice",
        email: "contact@kingstoring.com"
      },
      lastUpdate: "Date de dernière mise à jour : 26 novembre 2024"
    },
    en: {
      title: "Terms of Service",
      intro: "These terms of service (hereinafter the \"Terms\") govern your use of the SAS Kingstoring website (hereinafter the \"Site\"). By accessing and using the Site, you agree to be bound by these Terms. If you do not accept these Terms, please do not use the Site.",
      sections: [
        {
          title: "1. Acceptance of Terms",
          content: "By using the Site, you accept these Terms of Service in their entirety. We reserve the right to modify these Terms at any time without notice. Changes will be posted on this page and will take effect immediately upon posting. It is your responsibility to regularly check this page to stay informed of any changes."
        },
        {
          title: "2. Use of the Site",
          subsections: [
            {
              subtitle: "Access and Use",
              content: "You must be at least 18 years old and be an associate of SCI Club Expo 1 or legally represent an associate to use this Site. You agree to use the Site in accordance with the law and these Terms. You agree not to use the Site for illegal or unauthorized purposes."
            },
            {
              subtitle: "User Account",
              content: "To access certain features of the Site, you must create a user account. You are responsible for maintaining the confidentiality of your login credentials and for all activities carried out under your account."
            }
          ]
        }
      ],
      contact: {
        title: "Contact",
        content: "For any questions or concerns regarding these terms, please contact us at:",
        company: "SAS Kingstoring",
        address: "20 Avenue Jean Medecin, 06000 Nice",
        email: "contact@kingstoring.com"
      },
      lastUpdate: "Last updated: November 26, 2024"
    },
    it: {
      title: "Termini di Servizio",
      intro: "I presenti termini di servizio (di seguito i \"Termini\") regolano l'utilizzo del sito web di SAS Kingstoring (di seguito il \"Sito\"). Accedendo e utilizzando il Sito, accetti di essere vincolato da questi Termini. Se non accetti questi Termini, ti preghiamo di non utilizzare il Sito.",
      sections: [
        {
          title: "1. Accettazione dei Termini",
          content: "Utilizzando il Sito, accetti i presenti Termini di Servizio nella loro interezza. Ci riserviamo il diritto di modificare questi Termini in qualsiasi momento senza preavviso. Le modifiche saranno pubblicate su questa pagina e avranno effetto immediato dalla pubblicazione. È tua responsabilità controllare regolarmente questa pagina per rimanere informato su eventuali modifiche."
        },
        {
          title: "2. Utilizzo del Sito",
          subsections: [
            {
              subtitle: "Accesso e Utilizzo",
              content: "Devi avere almeno 18 anni ed essere un associato di SCI Club Expo 1 o rappresentare legalmente un associato per utilizzare questo Sito. Ti impegni a utilizzare il Sito in conformità con la legge e i presenti Termini. Accetti di non utilizzare il Sito per scopi illegali o non autorizzati."
            },
            {
              subtitle: "Account Utente",
              content: "Per accedere a determinate funzionalità del Sito, devi creare un account utente. Sei responsabile della riservatezza delle tue credenziali di accesso e di tutte le attività svolte con il tuo account."
            }
          ]
        }
      ],
      contact: {
        title: "Contatto",
        content: "Per qualsiasi domanda o dubbio riguardo questi termini, contattaci a:",
        company: "SAS Kingstoring",
        address: "20 Avenue Jean Medecin, 06000 Nice",
        email: "contact@kingstoring.com"
      },
      lastUpdate: "Ultimo aggiornamento: 26 novembre 2024"
    }
  };

  return (
    <LegalPageLayout>
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">
          {content[language].title}
        </h1>

        <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-8">
          <p className="text-blue-700">
            {content[language].intro}
          </p>
        </div>

        <div className="space-y-8">
          {content[language].sections.map((section, index) => (
            <section key={index} className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                {section.title}
              </h2>
              
              {section.content && (
                <p className="text-gray-600 mb-4">{section.content}</p>
              )}

              {section.subsections && (
                <div className="space-y-4">
                  {section.subsections.map((subsection, subIndex) => (
                    <div key={subIndex} className="pl-4 border-l-4 border-gray-100">
                      <h3 className="font-medium text-gray-900 mb-2">
                        {subsection.subtitle}
                      </h3>
                      <p className="text-gray-600">
                        {subsection.content}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </section>
          ))}
        </div>

        <div className="mt-8 bg-gray-50 rounded-lg p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">
            {content[language].contact.title}
          </h2>
          <div className="space-y-2 text-gray-600">
            <p>{content[language].contact.content}</p>
            <p className="font-medium">{content[language].contact.company}</p>
            <p>{content[language].contact.address}</p>
            <p>Email: {content[language].contact.email}</p>
          </div>
        </div>

        <p className="text-sm text-gray-500 mt-8 text-right">
          {content[language].lastUpdate}
        </p>
      </div>
    </LegalPageLayout>
  );
}

export default TermsOfService;