import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { useAuthStore } from '../store/authStore';

export function useAuth() {
  const { setUser, setLoading } = useAuthStore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (firebaseUser) {
          const userDocRef = doc(db, 'users', firebaseUser.uid);
          const userDoc = await getDoc(userDocRef);
          
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUser({
              id: firebaseUser.uid,
              ...userData,
              createdAt: userData.createdAt?.toDate(),
              updatedAt: userData.updatedAt?.toDate(),
              lastSeen: userData.lastSeen?.toDate()
            });

            // Mettre à jour le statut en ligne
            await setDoc(userDocRef, {
              isOnline: true,
              lastSeen: new Date()
            }, { merge: true });
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error in auth state change:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
      if (auth.currentUser) {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        setDoc(userDocRef, {
          isOnline: false,
          lastSeen: new Date()
        }, { merge: true });
      }
    };
  }, [setUser, setLoading]);
}