import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { 
  MessageCircle, 
  FileText, 
  Users, 
  Shield, 
  HelpCircle, 
  Settings,
  Menu,
  User,
  Bell,
  Home,
  LogOut,
  ChevronDown
} from 'lucide-react';
import { translations } from '../translations/layout';
import { MobileMenu } from './layout/MobileMenu';
import { DesktopSidebar } from './layout/DesktopSidebar';
import { NotificationDropdown } from './NotificationDropdown';
import { useNotifications } from '../hooks/useNotifications';

export function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, setUser } = useAuthStore();
  const { unreadCount } = useNotifications();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLegalMenuOpen, setIsLegalMenuOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const userLanguage = user?.preferredLanguage?.toLowerCase() || 'fr';
  const t = translations[userLanguage];

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.pathname]);

  const navigation = [
    { path: '/', name: t.dashboard, icon: Home, description: t.dashboardDesc },
    { path: '/forum', name: t.forum, icon: MessageCircle, description: t.forumDesc },
    { path: '/messages', name: t.messages, icon: MessageCircle, description: t.messagesDesc },
    { path: '/documents', name: t.documents, icon: FileText, description: t.documentsDesc },
    { path: '/council', name: t.council, icon: Shield, description: t.councilDesc },
    { path: '/assistance', name: t.assistance, icon: HelpCircle, description: t.assistanceDesc },
    { path: '/my-posts', name: t.myPosts, icon: FileText, description: t.myPostsDesc }
  ];

  if (user?.role === 'admin' || user?.role === 'council') {
    navigation.push({
      path: '/admin',
      name: t.admin,
      icon: Settings,
      description: t.adminDesc
    });
  }

  const handleLogout = async () => {
    try {
      if (user?.id) {
        const userRef = doc(db, 'users', user.id);
        await updateDoc(userRef, {
          isOnline: false,
          lastSeen: new Date()
        });
      }
      setUser(null);
      navigate('/auth');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Desktop Header */}
      <div className="fixed top-0 left-0 right-0 z-40 bg-gradient-to-r from-blue-600 to-indigo-600 hidden md:block">
        <div className="h-16 px-4 flex items-center justify-between max-w-7xl mx-auto">
          <div className="flex items-center space-x-2">
            <Shield className="h-8 w-8 text-white" />
            <div className="flex flex-col">
              <span className="text-lg font-semibold text-white">Conseil de Surveillance</span>
              <span className="text-sm text-blue-100">Expo 1</span>
            </div>
          </div>

          <div className="flex items-center space-x-6">
            {/* Language Selector */}
            <select
              value={user?.preferredLanguage || 'FR'}
              onChange={(e) => {
                // Handle language change
              }}
              className="bg-transparent text-white border border-blue-400 rounded-md px-2 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              <option value="FR" className="text-gray-900">🇫🇷 FR</option>
              <option value="EN" className="text-gray-900">🇬🇧 EN</option>
              <option value="IT" className="text-gray-900">🇮🇹 IT</option>
            </select>

            {/* Notifications */}
            <div className="relative">
              <button
                onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
                className="p-2 rounded-full hover:bg-blue-500 relative text-white"
              >
                <Bell className="h-6 w-6" />
                {unreadCount > 0 && (
                  <span className="absolute top-0 right-0 -mt-1 -mr-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white">
                    {unreadCount}
                  </span>
                )}
              </button>

              {isNotificationsOpen && (
                <div className="absolute right-0 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                  <NotificationDropdown onClose={() => setIsNotificationsOpen(false)} />
                </div>
              )}
            </div>

            {/* Profile Menu */}
            <div className="relative">
              <button
                onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                className="flex items-center space-x-3 text-white hover:bg-blue-500 px-3 py-2 rounded-md transition-colors"
              >
                <img
                  className="h-8 w-8 rounded-full border-2 border-white"
                  src={`https://ui-avatars.com/api/?name=${user?.firstName}+${user?.lastName}&background=random`}
                  alt=""
                />
                <div className="flex items-center">
                  <span className="text-sm font-medium">{user?.firstName}</span>
                  <ChevronDown className="h-4 w-4 ml-1" />
                </div>
              </button>

              {isProfileMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50 py-1">
                  <Link
                    to="/profile"
                    onClick={() => setIsProfileMenuOpen(false)}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    {t.profile}
                  </Link>
                  <button
                    onClick={() => {
                      handleLogout();
                      setIsProfileMenuOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                  >
                    {t.logout}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Header */}
      <div className="fixed top-0 left-0 right-0 z-40 bg-gradient-to-r from-blue-600 to-indigo-600 md:hidden">
        <div className="px-4 h-16 flex items-center justify-between">
          <button
            onClick={() => setIsMobileMenuOpen(true)}
            className="p-2 rounded-md text-white hover:bg-blue-500"
          >
            <Menu className="h-6 w-6" />
          </button>

          <div className="flex items-center">
            <Shield className="h-8 w-8 text-white" />
            <span className="ml-2 text-lg font-semibold text-white">Expo 1</span>
          </div>

          <div className="flex items-center space-x-2">
            {/* Notifications */}
            <div className="relative">
              <button
                onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
                className="p-2 rounded-full hover:bg-blue-500 relative text-white"
              >
                <Bell className="h-6 w-6" />
                {unreadCount > 0 && (
                  <span className="absolute top-0 right-0 -mt-1 -mr-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white">
                    {unreadCount}
                  </span>
                )}
              </button>

              {isNotificationsOpen && (
                <div className="absolute right-0 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                  <NotificationDropdown onClose={() => setIsNotificationsOpen(false)} />
                </div>
              )}
            </div>

            {/* Profile menu */}
            <div className="relative">
              <button
                onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                className="p-2 rounded-full hover:bg-blue-500 text-white"
              >
                <User className="h-6 w-6" />
              </button>

              {isProfileMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                  <div className="py-1">
                    <Link
                      to="/profile"
                      onClick={() => setIsProfileMenuOpen(false)}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      {t.profile}
                    </Link>
                    <button
                      onClick={() => {
                        handleLogout();
                        setIsProfileMenuOpen(false);
                      }}
                      className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                    >
                      {t.logout}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <MobileMenu
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        navigation={navigation}
        user={user}
        isLegalMenuOpen={isLegalMenuOpen}
        setIsLegalMenuOpen={setIsLegalMenuOpen}
        handleLogout={handleLogout}
        t={t}
      />

      {/* Desktop Sidebar */}
      <DesktopSidebar
        navigation={navigation}
        user={user}
        isLegalMenuOpen={isLegalMenuOpen}
        setIsLegalMenuOpen={setIsLegalMenuOpen}
        handleLogout={handleLogout}
        t={t}
      />

      {/* Main Content */}
      <div className="flex-1 md:ml-64">
        <main className="py-6 md:py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-16">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}

export default Layout;