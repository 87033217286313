import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAppzgzORvaj5SYjEv4sS04fGGKv5p63Rg",
  authDomain: "test-ab6c5.firebaseapp.com",
  projectId: "test-ab6c5",
  storageBucket: "test-ab6c5.firebasestorage.app",
  messagingSenderId: "445898757603",
  appId: "1:445898757603:web:83c82f33df21c7c831bcd6",
  measurementId: "G-GCMPF2V8CH"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

export default app;