export const translations = {
  fr: {
    dashboard: 'Tableau de bord',
    forum: 'Forum',
    messages: 'Messages',
    documents: 'Documents',
    council: 'Conseil',
    assistance: 'Assistance',
    admin: 'Administration',
    profile: 'Mon profil',
    settings: 'Paramètres',
    myPosts: 'Mes publications',
    logout: 'Déconnexion',
    welcome: 'Bienvenue',
    legal: 'Informations légales',
    terms: 'Conditions générales',
    privacy: 'Politique de confidentialité',
    rules: 'Règlement du forum',
    cookies: 'Politique des cookies',
    dashboardDesc: 'Accédez à votre tableau de bord',
    forumDesc: 'Consultez les discussions',
    messagesDesc: 'Messagerie privée',
    documentsDesc: 'Accédez aux documents',
    councilDesc: 'Membres du conseil',
    assistanceDesc: "Besoin d'aide ?",
    myPostsDesc: 'Gérez vos publications',
    adminDesc: 'Gérer la plateforme'
  },
  en: {
    dashboard: 'Dashboard',
    forum: 'Forum',
    messages: 'Messages',
    documents: 'Documents',
    council: 'Council',
    assistance: 'Support',
    admin: 'Administration',
    profile: 'My Profile',
    settings: 'Settings',
    myPosts: 'My Posts',
    logout: 'Logout',
    welcome: 'Welcome',
    legal: 'Legal Information',
    terms: 'Terms of Service',
    privacy: 'Privacy Policy',
    rules: 'Forum Rules',
    cookies: 'Cookie Policy',
    dashboardDesc: 'Access your dashboard',
    forumDesc: 'View discussions',
    messagesDesc: 'Private messaging',
    documentsDesc: 'Access documents',
    councilDesc: 'Council members',
    assistanceDesc: 'Need help?',
    myPostsDesc: 'Manage your posts',
    adminDesc: 'Manage platform'
  },
  it: {
    dashboard: 'Dashboard',
    forum: 'Forum',
    messages: 'Messaggi',
    documents: 'Documenti',
    council: 'Consiglio',
    assistance: 'Assistenza',
    admin: 'Amministrazione',
    profile: 'Profilo',
    settings: 'Impostazioni',
    myPosts: 'I miei post',
    logout: 'Esci',
    welcome: 'Benvenuto',
    legal: 'Informazioni legali',
    terms: 'Termini di servizio',
    privacy: 'Politica sulla privacy',
    rules: 'Regole del forum',
    cookies: 'Politica dei cookie',
    dashboardDesc: 'Accedi alla tua dashboard',
    forumDesc: 'Visualizza le discussioni',
    messagesDesc: 'Messaggistica privata',
    documentsDesc: 'Accedi ai documenti',
    councilDesc: 'Membri del consiglio',
    assistanceDesc: 'Hai bisogno di aiuto?',
    myPostsDesc: 'Gestisci i tuoi post',
    adminDesc: 'Gestisci piattaforma'
  }
};