import React from 'react';
import { useAuthStore } from '../../store/authStore';
import { LegalPageLayout } from '../../components/legal/LegalPageLayout';
import { Building, Mail, Globe, Shield, Book, Link as LinkIcon } from 'lucide-react';

export function LegalMentions() {
  const { user } = useAuthStore();
  const language = user?.preferredLanguage?.toLowerCase() || 'fr';

  const content = {
    fr: {
      title: "Mentions Légales",
      sections: [
        {
          title: "Identification de l'éditeur du site",
          icon: Building,
          content: [
            {
              text: "Le présent site est édité par la société KGS KINGSTORING, société par actions simplifiée (SAS) au capital de 1 000 €, immatriculée au Registre du Commerce et des Sociétés (RCS) de Nice sous le numéro 844 454 132."
            },
            {
              subtitle: "Siège social",
              text: "20 Avenue Jean Medecin, 06000 Nice, France."
            },
            {
              subtitle: "Directeur de la publication",
              text: "La présidence du conseil de surveillance de la SCI Club Expo 1 dont le mandat a été confié à Mme Florence Geay."
            }
          ]
        },
        {
          title: "Hébergement du site",
          icon: Globe,
          content: [
            {
              text: "Le site est hébergé par HOSTINGER INTERNATIONAL LTD, dont le siège social se situe 61 Lordou Vironos Street, 6023 Larnaca, Chypre."
            }
          ]
        },
        {
          title: "Propriété intellectuelle",
          icon: Shield,
          content: [
            {
              text: "L'ensemble du contenu du site est la propriété de SAS KINGSTORING ou de ses partenaires et est protégé par les lois françaises et internationales relatives à la propriété intellectuelle."
            },
            {
              text: "Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord exprès par écrit de SAS KINGSTORING."
            }
          ]
        }
      ],
      contact: {
        title: "Contact",
        email: "contact@kingstoring.com",
        address: "20 Avenue Jean Medecin, 06000 Nice, France"
      }
    },
    en: {
      title: "Legal Notice",
      sections: [
        {
          title: "Website Publisher Information",
          icon: Building,
          content: [
            {
              text: "This website is published by KGS KINGSTORING, a simplified joint-stock company (SAS) with a capital of €1,000, registered in the Nice Trade and Companies Register (RCS) under number 844 454 132."
            },
            {
              subtitle: "Registered Office",
              text: "20 Avenue Jean Medecin, 06000 Nice, France."
            },
            {
              subtitle: "Publication Director",
              text: "The presidency of the supervisory board of SCI Club Expo 1, mandated to Mrs. Florence Geay."
            }
          ]
        },
        {
          title: "Website Hosting",
          icon: Globe,
          content: [
            {
              text: "The website is hosted by HOSTINGER INTERNATIONAL LTD, headquartered at 61 Lordou Vironos Street, 6023 Larnaca, Cyprus."
            }
          ]
        },
        {
          title: "Intellectual Property",
          icon: Shield,
          content: [
            {
              text: "All website content is the property of SAS KINGSTORING or its partners and is protected by French and international intellectual property laws."
            },
            {
              text: "Any reproduction, distribution, modification, adaptation, retransmission or publication, even partial, of these elements is strictly prohibited without the express written consent of SAS KINGSTORING."
            }
          ]
        }
      ],
      contact: {
        title: "Contact",
        email: "contact@kingstoring.com",
        address: "20 Avenue Jean Medecin, 06000 Nice, France"
      }
    },
    it: {
      title: "Note Legali",
      sections: [
        {
          title: "Informazioni sull'Editore del Sito",
          icon: Building,
          content: [
            {
              text: "Questo sito web è pubblicato da KGS KINGSTORING, società per azioni semplificata (SAS) con capitale di €1.000, registrata nel Registro del Commercio e delle Società di Nizza (RCS) con il numero 844 454 132."
            },
            {
              subtitle: "Sede Legale",
              text: "20 Avenue Jean Medecin, 06000 Nice, Francia."
            },
            {
              subtitle: "Direttore della Pubblicazione",
              text: "La presidenza del consiglio di sorveglianza di SCI Club Expo 1, affidata alla Sig.ra Florence Geay."
            }
          ]
        },
        {
          title: "Hosting del Sito",
          icon: Globe,
          content: [
            {
              text: "Il sito è ospitato da HOSTINGER INTERNATIONAL LTD, con sede legale in 61 Lordou Vironos Street, 6023 Larnaca, Cipro."
            }
          ]
        },
        {
          title: "Proprietà Intellettuale",
          icon: Shield,
          content: [
            {
              text: "Tutti i contenuti del sito sono di proprietà di SAS KINGSTORING o dei suoi partner e sono protetti dalle leggi francesi e internazionali sulla proprietà intellettuale."
            },
            {
              text: "Qualsiasi riproduzione, distribuzione, modifica, adattamento, ritrasmissione o pubblicazione, anche parziale, di questi elementi è severamente vietata senza l'espresso consenso scritto di SAS KINGSTORING."
            }
          ]
        }
      ],
      contact: {
        title: "Contatto",
        email: "contact@kingstoring.com",
        address: "20 Avenue Jean Medecin, 06000 Nice, Francia"
      }
    }
  };

  return (
    <LegalPageLayout>
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">
          {content[language].title}
        </h1>

        <div className="space-y-8">
          {content[language].sections.map((section, index) => (
            <section key={index} className="bg-white rounded-lg shadow-sm p-6">
              <div className="flex items-center mb-4">
                <div className="flex-shrink-0">
                  <section.icon className="h-6 w-6 text-blue-500" />
                </div>
                <h2 className="ml-3 text-xl font-semibold text-gray-900">
                  {section.title}
                </h2>
              </div>
              
              <div className="space-y-4">
                {section.content.map((item, itemIndex) => (
                  <div key={itemIndex} className="pl-4 border-l-4 border-gray-100">
                    {item.subtitle && (
                      <h3 className="font-medium text-gray-900 mb-1">
                        {item.subtitle}
                      </h3>
                    )}
                    <p className="text-gray-600">
                      {item.text}
                    </p>
                  </div>
                ))}
              </div>
            </section>
          ))}
        </div>

        <div className="mt-8 bg-gray-50 rounded-lg p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <Mail className="h-5 w-5 mr-2 text-blue-500" />
            {content[language].contact.title}
          </h2>
          <div className="space-y-2 text-gray-600 pl-7">
            <p>Email: {content[language].contact.email}</p>
            <p>{content[language].contact.address}</p>
          </div>
        </div>
      </div>
    </LegalPageLayout>
  );
}

export default LegalMentions;