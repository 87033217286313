import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthStore } from '../store/authStore';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { 
  FileText,
  Download,
  Upload,
  Search,
  Eye,
  FolderPlus,
  Globe,
  Edit,
  Trash2,
  File,
  Folder
} from 'lucide-react';
import { storageService } from '../utils/storageService';
import { categoryLabels, documentLabels } from '../constants/translations';
import UploadFileModal from '../components/drive/UploadFileModal';
import ViewDocumentModal from '../components/drive/ViewDocumentModal';
import CreateFolderModal from '../components/drive/CreateFolderModal';
import RenameItemModal from '../components/drive/RenameItemModal';
import { clsx } from 'clsx';

interface Document {
  id: string;
  name: string;
  url: string;
  format: string;
  size: number;
  category: string;
  language: string;
  description?: string;
  uploadedBy: string;
  uploadedAt: Date;
  folderId: string;
  storagePath: string;
}

interface Folder {
  id: string;
  name: string;
  language: string;
  createdBy: string;
  createdAt: Date;
  parentId: string;
}

export function Documents() {
  const { user, isAdmin, isCouncilMember } = useAuthStore();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [folders, setFolders] = useState<Folder[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState(user?.preferredLanguage?.toLowerCase() || 'fr');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [currentFolderId, setCurrentFolderId] = useState('root');
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

  const canModify = isAdmin() || isCouncilMember();

  useEffect(() => {
    if (!user) return;

    // Fetch folders
    const foldersQuery = query(
      collection(db, 'drive_folders'),
      where('parentId', '==', currentFolderId),
      where('language', '==', selectedLanguage),
      orderBy('name', 'asc')
    );

    const foldersUnsubscribe = onSnapshot(foldersQuery, 
      (snapshot) => {
        const foldersData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate()
        })) as Folder[];
        setFolders(foldersData);
      },
      (error) => {
        console.error('Error fetching folders:', error);
      }
    );

    // Fetch documents
    const documentsQuery = query(
      collection(db, 'drive_files'),
      where('folderId', '==', currentFolderId),
      where('language', '==', selectedLanguage),
      orderBy('name', 'asc')
    );

    const documentsUnsubscribe = onSnapshot(documentsQuery, 
      (snapshot) => {
        const docsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          uploadedAt: doc.data().uploadedAt?.toDate()
        })) as Document[];
        setDocuments(docsData);
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching documents:', error);
        setLoading(false);
      }
    );

    return () => {
      foldersUnsubscribe();
      documentsUnsubscribe();
    };
  }, [user, selectedLanguage, currentFolderId]);

  const filteredDocuments = documents.filter(doc => {
    const matchesSearch = 
      doc.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (doc.description || '').toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || doc.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  const handleDownload = async (document: Document) => {
    try {
      const url = await storageService.getFileUrl(document.id);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error downloading document:', error);
      alert('Erreur lors du téléchargement du document');
    }
  };

  const handleDelete = async (item: Document | Folder) => {
    if (!canModify) return;
    
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) return;

    try {
      if ('url' in item) { // Document
        await storageService.deleteFile(item.id);
      } else { // Folder
        // Handle folder deletion
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };

  const navigateToFolder = (folderId: string) => {
    setCurrentFolderId(folderId);
    setSearchQuery('');
    setSelectedCategory('all');
  };

  const navigateUp = () => {
    if (currentFolderId !== 'root') {
      setCurrentFolderId('root');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">
            {documentLabels[selectedLanguage].title}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {documentLabels[selectedLanguage].subtitle}
          </p>
        </div>
        {canModify && (
          <div className="mt-4 sm:mt-0 flex space-x-3">
            <button
              onClick={() => setIsCreateFolderModalOpen(true)}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <FolderPlus className="h-5 w-5 mr-2" />
              {documentLabels[selectedLanguage].newFolder}
            </button>
            <button
              onClick={() => setIsUploadModalOpen(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Upload className="h-5 w-5 mr-2" />
              {documentLabels[selectedLanguage].addDocument}
            </button>
          </div>
        )}
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Langue / Language / Lingua
            </label>
            <select
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="fr">🇫🇷 Français</option>
              <option value="en">🇬🇧 English</option>
              <option value="it">🇮🇹 Italiano</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              {categoryLabels[selectedLanguage].all}
            </label>
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="all">{categoryLabels[selectedLanguage].all}</option>
              <option value="administrative">{categoryLabels[selectedLanguage].administrative}</option>
              <option value="financial">{categoryLabels[selectedLanguage].financial}</option>
              <option value="legal">{categoryLabels[selectedLanguage].legal}</option>
              <option value="technical">{categoryLabels[selectedLanguage].technical}</option>
              <option value="other">{categoryLabels[selectedLanguage].other}</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              {documentLabels[selectedLanguage].search}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder={documentLabels[selectedLanguage].searchPlaceholder}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Breadcrumb */}
      {currentFolderId !== 'root' && (
        <nav className="flex items-center space-x-2 text-sm text-gray-500">
          <button
            onClick={navigateUp}
            className="hover:text-gray-700"
          >
            Documents
          </button>
          <span>/</span>
          <span className="text-gray-900">Current Folder</span>
        </nav>
      )}

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {folders.length + filteredDocuments.length} éléments
          </h3>
          <Globe className="h-5 w-5 text-gray-400" />
        </div>

        <ul className="divide-y divide-gray-200">
          {/* Folders */}
          {folders.map((folder) => (
            <li key={folder.id} className="px-4 py-4 sm:px-6 hover:bg-gray-50">
              <div className="flex items-center justify-between">
                <button
                  onClick={() => navigateToFolder(folder.id)}
                  className="flex items-center flex-1"
                >
                  <Folder className="h-5 w-5 text-gray-400" />
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">{folder.name}</p>
                    <p className="text-sm text-gray-500">
                      {format(folder.createdAt, 'dd MMM yyyy', { locale: fr })}
                    </p>
                  </div>
                </button>
                {canModify && (
                  <div className="flex space-x-2">
                    <button
                      onClick={() => {
                        setSelectedItem(folder);
                        setIsRenameModalOpen(true);
                      }}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <Edit className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(folder)}
                      className="text-gray-400 hover:text-red-500"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                )}
              </div>
            </li>
          ))}

          {/* Files */}
          {filteredDocuments.map((doc) => (
            <li key={doc.id} className="px-4 py-4 sm:px-6 hover:bg-gray-50">
              <div className="flex items-center justify-between">
                <div className="flex items-center flex-1">
                  <File className="h-5 w-5 text-gray-400" />
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">{doc.name}</p>
                    <p className="text-sm text-gray-500">
                      {formatFileSize(doc.size)} • {format(doc.uploadedAt, 'dd MMM yyyy', { locale: fr })}
                    </p>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      setSelectedItem(doc);
                      setIsViewModalOpen(true);
                    }}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <Eye className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleDownload(doc)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <Download className="h-5 w-5" />
                  </button>
                  {canModify && (
                    <>
                      <button
                        onClick={() => {
                          setSelectedItem(doc);
                          setIsRenameModalOpen(true);
                        }}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <Edit className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(doc)}
                        className="text-gray-400 hover:text-red-500"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </>
                  )}
                </div>
              </div>
            </li>
          ))}

          {folders.length === 0 && filteredDocuments.length === 0 && (
            <li className="px-4 py-8 text-center text-gray-500">
              {documentLabels[selectedLanguage].noDocuments}
            </li>
          )}
        </ul>
      </div>

      {/* Modals */}
      {canModify && (
        <>
          <UploadFileModal
            isOpen={isUploadModalOpen}
            onClose={() => setIsUploadModalOpen(false)}
            folderId={currentFolderId}
            language={selectedLanguage}
          />

          <CreateFolderModal
            isOpen={isCreateFolderModalOpen}
            onClose={() => setIsCreateFolderModalOpen(false)}
            parentId={currentFolderId}
          />

          {selectedItem && (
            <RenameItemModal
              isOpen={isRenameModalOpen}
              onClose={() => {
                setIsRenameModalOpen(false);
                setSelectedItem(null);
              }}
              item={selectedItem}
            />
          )}
        </>
      )}

      {selectedItem && (
        <ViewDocumentModal
          isOpen={isViewModalOpen}
          onClose={() => {
            setIsViewModalOpen(false);
            setSelectedItem(null);
          }}
          file={selectedItem}
        />
      )}
    </div>
  );
}

export default Documents;