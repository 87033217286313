import { doc, updateDoc, collection, query, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { sanitizeText } from './textUtils';

const API_KEY = 'AIzaSyD879B9-djSHWR56ZjOV2WF54W57xFvArs';
const BASE_URL = 'https://translation.googleapis.com/language/translate/v2';

export const translationService = {
  async translateText(text: string, targetLanguage: string) {
    try {
      // First sanitize any existing HTML entities
      const sanitizedText = sanitizeText(text);

      const response = await fetch(`${BASE_URL}?key=${API_KEY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          q: sanitizedText,
          target: targetLanguage.toLowerCase(),
          format: 'text',
        }),
      });

      if (!response.ok) {
        throw new Error('Translation failed');
      }

      const data = await response.json();
      const translatedText = data.data.translations[0].translatedText;

      // Sanitize the translated text before storage
      return sanitizeText(translatedText);
    } catch (error) {
      console.error('Translation error:', error);
      return text;
    }
  },

  async translateMessage(messageId: string, content: string) {
    const languages = ['fr', 'en', 'it'];
    const translations = {};

    try {
      await Promise.all(
        languages.map(async (lang) => {
          translations[lang] = await this.translateText(content, lang);
        })
      );

      const messageRef = doc(db, 'messages', messageId);
      await updateDoc(messageRef, { translations });

      return translations;
    } catch (error) {
      console.error('Error translating message:', error);
      throw error;
    }
  },

  async translatePost(postId: string, title: string, content: string) {
    const languages = ['fr', 'en', 'it'];
    const translations = {};

    try {
      await Promise.all(
        languages.map(async (lang) => {
          const translatedTitle = await this.translateText(title, lang);
          const translatedContent = await this.translateText(content, lang);
          translations[lang] = {
            title: translatedTitle,
            content: translatedContent
          };
        })
      );

      const postRef = doc(db, 'forum_posts', postId);
      await updateDoc(postRef, { translations });

      return translations;
    } catch (error) {
      console.error('Error translating post:', error);
      throw error;
    }
  },

  async translateAllExistingContent() {
    try {
      // Translate all posts
      const postsQuery = query(collection(db, 'forum_posts'));
      const postsSnapshot = await getDocs(postsQuery);
      
      for (const postDoc of postsSnapshot.docs) {
        const post = postDoc.data();
        if (!post.translations) {
          await this.translatePost(postDoc.id, post.title, post.content);
        }
      }

      // Translate all messages
      const messagesQuery = query(collection(db, 'messages'));
      const messagesSnapshot = await getDocs(messagesQuery);
      
      for (const messageDoc of messagesSnapshot.docs) {
        const message = messageDoc.data();
        if (!message.translations) {
          await this.translateMessage(messageDoc.id, message.content);
        }
      }

      // Translate prevention message
      const settingsDoc = await doc(db, 'settings', 'general').get();
      if (settingsDoc.exists()) {
        const settings = settingsDoc.data();
        if (settings.preventionMessage && !settings.translations) {
          const translations = {};
          await Promise.all(
            ['fr', 'en', 'it'].map(async (lang) => {
              translations[lang] = {
                preventionMessage: await this.translateText(settings.preventionMessage, lang)
              };
            })
          );
          await updateDoc(doc(db, 'settings', 'general'), { translations });
        }
      }

      console.log('All content translated successfully');
    } catch (error) {
      console.error('Error translating content:', error);
      throw error;
    }
  }
};