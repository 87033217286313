import React from 'react';
import { Shield, MessageCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import { useAuthStore } from '../../store/authStore';
import { dashboardTranslations } from '../../translations/dashboard';

interface CouncilMember {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  isOnline: boolean;
}

interface CouncilMembersProps {
  members: CouncilMember[];
}

export function CouncilMembers({ members }: CouncilMembersProps) {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const userLanguage = user?.preferredLanguage?.toLowerCase() || 'fr';
  const t = dashboardTranslations[userLanguage];

  return (
    <div className="bg-white shadow rounded-lg">
      <div className="p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Shield className="h-5 w-5 text-gray-400 mr-2" />
          {t.councilMembers.title}
        </h2>
        <div className="space-y-4">
          {members.map((member) => (
            <div key={member.id} className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div className="relative">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={`https://ui-avatars.com/api/?name=${member.firstName}+${member.lastName}&background=random`}
                    alt=""
                  />
                  <div className={clsx(
                    "absolute bottom-0 right-0 h-3 w-3 rounded-full ring-2 ring-white",
                    member.isOnline ? "bg-green-400" : "bg-gray-300"
                  )} />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-900">
                    {member.firstName} {member.lastName}
                  </p>
                  <p className="text-xs text-gray-500">
                    {member.role}
                  </p>
                </div>
              </div>
              <button
                onClick={() => navigate(`/messages?new=${member.id}`)}
                className="flex items-center text-sm text-blue-600 hover:text-blue-500"
              >
                <MessageCircle className="h-4 w-4 mr-1" />
                {t.councilMembers.contact}
              </button>
            </div>
          ))}
        </div>
        <div className="mt-6">
          <button
            onClick={() => navigate('/council')}
            className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            {t.councilMembers.viewAll}
          </button>
        </div>
      </div>
    </div>
  );
}