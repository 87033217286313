import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getCountFromServer } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuthStore } from '../../store/authStore';
import { 
  Users,
  MessageSquare,
  FileText,
  Activity,
  TrendingUp,
  UserCheck,
  UserPlus,
  Calendar,
  Mail,
  MessageCircle,
  Shield,
  Download,
  Building,
  Clock,
  Globe,
  AlertTriangle
} from 'lucide-react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area
} from 'recharts';
import { format, subDays, startOfMonth, endOfMonth, eachDayOfInterval } from 'date-fns';
import { fr, enGB, it } from 'date-fns/locale';

const translations = {
  fr: {
    title: "Statistiques",
    subtitle: "Analyses détaillées de l'activité de la plateforme",
    timeRanges: {
      week: "7 derniers jours",
      month: "30 derniers jours",
      year: "12 derniers mois"
    },
    charts: {
      userGrowth: "Croissance des utilisateurs",
      messageActivity: "Activité des messages",
      postTypes: "Types de publications",
      documentCategories: "Documents par catégorie"
    },
    metrics: {
      activeUsers: "Utilisateurs actifs",
      newUsers: "nouveaux ce mois",
      posts: "Publications",
      pendingPosts: "en attente",
      messages: "Messages échangés",
      tickets: "Tickets support",
      resolvedTickets: "résolus",
      responseTime: "Temps de réponse moyen",
      onlineUsers: "Utilisateurs en ligne",
      documents: "Documents partagés"
    }
  },
  en: {
    title: "Statistics",
    subtitle: "Detailed platform activity analysis",
    timeRanges: {
      week: "Last 7 days",
      month: "Last 30 days",
      year: "Last 12 months"
    },
    charts: {
      userGrowth: "User Growth",
      messageActivity: "Message Activity",
      postTypes: "Post Types",
      documentCategories: "Documents by Category"
    },
    metrics: {
      activeUsers: "Active Users",
      newUsers: "new this month",
      posts: "Posts",
      pendingPosts: "pending",
      messages: "Messages",
      tickets: "Support Tickets",
      resolvedTickets: "resolved",
      responseTime: "Average Response Time",
      onlineUsers: "Online Users",
      documents: "Shared Documents"
    }
  },
  it: {
    title: "Statistiche",
    subtitle: "Analisi dettagliata dell'attività della piattaforma",
    timeRanges: {
      week: "Ultimi 7 giorni",
      month: "Ultimi 30 giorni",
      year: "Ultimi 12 mesi"
    },
    charts: {
      userGrowth: "Crescita utenti",
      messageActivity: "Attività messaggi",
      postTypes: "Tipi di post",
      documentCategories: "Documenti per categoria"
    },
    metrics: {
      activeUsers: "Utenti attivi",
      newUsers: "nuovi questo mese",
      posts: "Pubblicazioni",
      pendingPosts: "in attesa",
      messages: "Messaggi",
      tickets: "Ticket supporto",
      resolvedTickets: "risolti",
      responseTime: "Tempo medio di risposta",
      onlineUsers: "Utenti online",
      documents: "Documenti condivisi"
    }
  }
};

const COLORS = ['#3B82F6', '#10B981', '#F59E0B', '#EF4444', '#8B5CF6'];

const getDateLocale = (language: string) => {
  switch (language) {
    case 'en': return enGB;
    case 'it': return it;
    default: return fr;
  }
};

export function Statistics() {
  const { user } = useAuthStore();
  const [timeRange, setTimeRange] = useState('month');
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    onlineUsers: 0,
    newUsers: 0,
    totalPosts: 0,
    pendingPosts: 0,
    totalMessages: 0,
    totalDocuments: 0,
    supportTickets: 0,
    resolvedTickets: 0,
    averageResponseTime: 0
  });
  const [chartData, setChartData] = useState({
    userGrowth: [],
    messageActivity: [],
    postTypes: [],
    documentCategories: []
  });

  const userLanguage = user?.preferredLanguage?.toLowerCase() || 'fr';
  const t = translations[userLanguage];
  const dateLocale = getDateLocale(userLanguage);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const now = new Date();
        const firstDayOfMonth = startOfMonth(now);
        const lastDayOfMonth = endOfMonth(now);

        // Fetch basic stats
        const [
          usersSnapshot,
          onlineUsersSnapshot,
          postsSnapshot,
          pendingPostsSnapshot,
          documentsSnapshot,
          messagesSnapshot,
          supportTicketsSnapshot,
          resolvedTicketsSnapshot,
          newUsersSnapshot
        ] = await Promise.all([
          getCountFromServer(collection(db, 'users')),
          getCountFromServer(query(collection(db, 'users'), where('isOnline', '==', true))),
          getCountFromServer(collection(db, 'forum_posts')),
          getCountFromServer(query(collection(db, 'forum_posts'), where('status', '==', 'pending'))),
          getCountFromServer(collection(db, 'drive_files')),
          getCountFromServer(collection(db, 'messages')),
          getCountFromServer(collection(db, 'support_tickets')),
          getCountFromServer(query(collection(db, 'support_tickets'), where('status', '==', 'resolved'))),
          getCountFromServer(query(
            collection(db, 'users'),
            where('createdAt', '>=', firstDayOfMonth),
            where('createdAt', '<=', lastDayOfMonth)
          ))
        ]);

        // Fetch post types distribution
        const postTypesSnapshot = await getDocs(collection(db, 'forum_posts'));
        const postTypesCounts = {
          vente_parts: 0,
          achat_parts: 0,
          echange_semaines: 0,
          question: 0,
          discussion: 0
        };
        postTypesSnapshot.forEach(doc => {
          const type = doc.data().type;
          if (type in postTypesCounts) {
            postTypesCounts[type]++;
          }
        });

        // Fetch document categories distribution
        const documentsSnapshot2 = await getDocs(collection(db, 'drive_files'));
        const categoryCounts = {
          administrative: 0,
          financial: 0,
          legal: 0,
          technical: 0,
          other: 0
        };
        documentsSnapshot2.forEach(doc => {
          const category = doc.data().category;
          if (category in categoryCounts) {
            categoryCounts[category]++;
          }
        });

        // Set stats
        setStats({
          totalUsers: usersSnapshot.data().count,
          activeUsers: onlineUsersSnapshot.data().count,
          onlineUsers: onlineUsersSnapshot.data().count,
          newUsers: newUsersSnapshot.data().count,
          totalPosts: postsSnapshot.data().count,
          pendingPosts: pendingPostsSnapshot.data().count,
          totalMessages: messagesSnapshot.data().count,
          totalDocuments: documentsSnapshot.data().count,
          supportTickets: supportTicketsSnapshot.data().count,
          resolvedTickets: resolvedTicketsSnapshot.data().count,
          averageResponseTime: Math.floor(Math.random() * 24) + 1 // This should be calculated from actual data
        });

        // Generate chart data
        const days = eachDayOfInterval({ 
          start: subDays(now, timeRange === 'week' ? 7 : 30), 
          end: now 
        });

        setChartData({
          userGrowth: days.map(day => ({
            date: format(day, 'dd/MM', { locale: dateLocale }),
            users: Math.floor(Math.random() * 10) + 1 // Replace with actual data
          })),
          messageActivity: days.map(day => ({
            date: format(day, 'dd/MM', { locale: dateLocale }),
            messages: Math.floor(Math.random() * 50) + 10 // Replace with actual data
          })),
          postTypes: Object.entries(postTypesCounts).map(([type, count]) => ({
            name: type,
            value: count
          })),
          documentCategories: Object.entries(categoryCounts).map(([category, count]) => ({
            category,
            count
          }))
        });

        setLoading(false);
      } catch (error) {
        console.error('Error fetching stats:', error);
        setLoading(false);
      }
    };

    fetchStats();
  }, [timeRange, dateLocale]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">{t.title}</h2>
          <p className="mt-1 text-sm text-gray-500">{t.subtitle}</p>
        </div>
        <div className="mt-4 sm:mt-0">
          <select
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value)}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="week">{t.timeRanges.week}</option>
            <option value="month">{t.timeRanges.month}</option>
            <option value="year">{t.timeRanges.year}</option>
          </select>
        </div>
      </div>

      {/* Quick Stats */}
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0 p-3 rounded-md bg-blue-100">
                <Users className="h-6 w-6 text-blue-600" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    {t.metrics.activeUsers}
                  </dt>
                  <dd className="flex items-baseline">
                    <div className="text-2xl font-semibold text-gray-900">
                      {stats.activeUsers}
                    </div>
                    <div className="ml-2 flex items-baseline text-sm font-semibold text-green-600">
                      +{stats.newUsers} {t.metrics.newUsers}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0 p-3 rounded-md bg-green-100">
                <MessageSquare className="h-6 w-6 text-green-600" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    {t.metrics.posts}
                  </dt>
                  <dd className="flex items-baseline">
                    <div className="text-2xl font-semibold text-gray-900">
                      {stats.totalPosts}
                    </div>
                    <div className="ml-2 flex items-baseline text-sm font-semibold text-yellow-600">
                      {stats.pendingPosts} {t.metrics.pendingPosts}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0 p-3 rounded-md bg-yellow-100">
                <MessageCircle className="h-6 w-6 text-yellow-600" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    {t.metrics.messages}
                  </dt>
                  <dd className="text-2xl font-semibold text-gray-900">
                    {stats.totalMessages}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0 p-3 rounded-md bg-purple-100">
                <AlertTriangle className="h-6 w-6 text-purple-600" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    {t.metrics.tickets}
                  </dt>
                  <dd className="flex items-baseline">
                    <div className="text-2xl font-semibold text-gray-900">
                      {stats.supportTickets}
                    </div>
                    <div className="ml-2 flex items-baseline text-sm font-semibold text-green-600">
                      {stats.resolvedTickets} {t.metrics.resolvedTickets}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
        {/* User Growth Chart */}
        <div className="bg-white shadow rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            {t.charts.userGrowth}
          </h3>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={chartData.userGrowth}>
                <defs>
                  <linearGradient id="userGrowth" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#3B82F6" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Area 
                  type="monotone" 
                  dataKey="users" 
                  stroke="#3B82F6" 
                  fillOpacity={1} 
                  fill="url(#userGrowth)" 
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Message Activity Chart */}
        <div className="bg-white shadow rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            {t.charts.messageActivity}
          </h3>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={chartData.messageActivity}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line 
                  type="monotone" 
                  dataKey="messages" 
                  stroke="#10B981" 
                  strokeWidth={2} 
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Post Types Distribution */}
        <div className="bg-white shadow rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            {t.charts.postTypes}
          </h3>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={chartData.postTypes}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {chartData.postTypes.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`} 
                      fill={COLORS[index % COLORS.length]} 
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Document Categories */}
        <div className="bg-white shadow rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            {t.charts.documentCategories}
          </h3>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={chartData.documentCategories}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="category" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" fill="#8B5CF6">
                  {chartData.documentCategories.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`} 
                      fill={COLORS[index % COLORS.length]} 
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Additional Stats */}
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center">
            <Clock className="h-8 w-8 text-blue-500" />
            <div className="ml-4">
              <h4 className="text-lg font-medium text-gray-900">
                {t.metrics.responseTime}
              </h4>
              <p className="text-2xl font-semibold text-blue-600">
                {stats.averageResponseTime}h
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center">
            <Globe className="h-8 w-8 text-green-500" />
            <div className="ml-4">
              <h4 className="text-lg font-medium text-gray-900">
                {t.metrics.onlineUsers}
              </h4>
              <p className="text-2xl font-semibold text-green-600">
                {stats.onlineUsers}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center">
            <Building className="h-8 w-8 text-purple-500" />
            <div className="ml-4">
              <h4 className="text-lg font-medium text-gray-900">
                {t.metrics.documents}
              </h4>
              <p className="text-2xl font-semibold text-purple-600">
                {stats.totalDocuments}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;