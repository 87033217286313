import React from 'react';

interface UserProfileProps {
  user: any;
  t: any;
}

export function UserProfile({ user, t }: UserProfileProps) {
  return (
    <div className="px-4 py-4 border-b border-gray-200">
      <div className="flex items-center">
        <img
          className="h-10 w-10 rounded-full"
          src={`https://ui-avatars.com/api/?name=${user?.firstName}+${user?.lastName}&background=random`}
          alt=""
        />
        <div className="ml-3">
          <p className="text-base font-medium text-gray-800">
            {user?.firstName} {user?.lastName}
          </p>
          <p className="text-sm text-gray-500">
            {t.welcome}
          </p>
        </div>
      </div>
    </div>
  );
}