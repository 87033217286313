import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ChatMessage } from './ChatMessage';
import { ChatInput } from './ChatInput';
import { useAIAssistant } from '../../hooks/useAIAssistant';
import { MessageCircle, X, Minimize2, Maximize2, Trash2 } from 'lucide-react';

export function ChatWindow() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const messageInputRef = useRef<HTMLInputElement>(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const { sendMessage, isLoading, messageHistory, clearHistory, loadMoreMessages, hasMore } = useAIAssistant();

  // Don't show chat on messages page
  if (location.pathname === '/messages') {
    return null;
  }

  // Auto scroll to bottom when new messages arrive
  const scrollToBottom = useCallback((force = false) => {
    if (messagesEndRef.current && (!isUserScrolling || force)) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isUserScrolling]);

  useEffect(() => {
    if (!isUserScrolling) {
      scrollToBottom();
    }
  }, [messageHistory, scrollToBottom, isUserScrolling]);

  // Handle infinite scroll
  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    const element = e.currentTarget;
    const isScrollingUp = element.scrollTop === 0;
    const isScrollingDown = Math.abs(
      element.scrollHeight - element.scrollTop - element.clientHeight
    ) < 1;

    setIsUserScrolling(!isScrollingDown);

    if (isScrollingUp && hasMore) {
      loadMoreMessages();
    }
  }, [hasMore, loadMoreMessages]);

  const handleSend = async (message: string) => {
    try {
      setIsUserScrolling(false);
      await sendMessage(message);
      scrollToBottom(true);
    } catch (error) {
      console.error('Error getting AI response:', error);
    }
  };

  if (!isOpen) {
    return (
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-6 right-6 p-4 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition-colors z-50"
      >
        <MessageCircle className="h-6 w-6" />
      </button>
    );
  }

  return (
    <div className={`fixed right-6 bg-white rounded-lg shadow-xl transition-all duration-200 z-50 ${
      isMinimized ? 'bottom-6 w-72' : 'bottom-6 w-96 h-[600px]'
    }`}>
      {/* Header */}
      <div className="flex items-center justify-between p-4 border-b bg-blue-50 rounded-t-lg">
        <div className="flex items-center">
          <MessageCircle className="h-5 w-5 text-blue-600 mr-2" />
          <h3 className="text-lg font-medium text-gray-900">Assistant IA</h3>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={clearHistory}
            className="p-1 hover:bg-blue-100 rounded-full transition-colors"
            title="Effacer l'historique"
          >
            <Trash2 className="h-4 w-4 text-gray-600" />
          </button>
          <button
            onClick={() => setIsMinimized(!isMinimized)}
            className="p-1 hover:bg-blue-100 rounded-full transition-colors"
          >
            {isMinimized ? (
              <Maximize2 className="h-4 w-4 text-gray-600" />
            ) : (
              <Minimize2 className="h-4 w-4 text-gray-600" />
            )}
          </button>
          <button
            onClick={() => setIsOpen(false)}
            className="p-1 hover:bg-blue-100 rounded-full transition-colors"
          >
            <X className="h-4 w-4 text-gray-600" />
          </button>
        </div>
      </div>

      {!isMinimized && (
        <>
          {/* Messages */}
          <div 
            ref={messageContainerRef}
            onScroll={handleScroll}
            className="flex-1 overflow-y-auto p-4 space-y-4 h-[480px] bg-gray-50"
          >
            {messageHistory.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full text-center text-gray-500">
                <MessageCircle className="h-12 w-12 mb-4" />
                <p className="text-lg font-medium">Bienvenue !</p>
                <p className="mt-2">
                  Je suis votre assistant virtuel. Comment puis-je vous aider aujourd'hui ?
                </p>
              </div>
            ) : (
              messageHistory.map((message) => (
                <ChatMessage
                  key={message.id}
                  message={message}
                  isLoading={isLoading}
                />
              ))
            )}
            {isLoading && (
              <ChatMessage
                message={{
                  id: 'loading',
                  role: 'assistant',
                  content: 'En train de répondre...',
                  timestamp: new Date(),
                  userId: ''
                }}
                isLoading
              />
            )}
            <div ref={messagesEndRef} />
          </div>

          {/* Input */}
          <div className="p-4 border-t bg-white rounded-b-lg">
            <ChatInput 
              onSend={handleSend} 
              isLoading={isLoading}
              ref={messageInputRef}
            />
          </div>
        </>
      )}
    </div>
  );
}