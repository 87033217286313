import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence, browserSessionPersistence } from 'firebase/auth';
import { auth } from '../lib/firebase';
import { useNavigate, Link } from 'react-router-dom';
import { Mail, Lock, LogIn, UserPlus, Shield, Globe } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { clsx } from 'clsx';

const loginSchema = z.object({
  email: z.string().email('Email invalide'),
  password: z.string().min(6, 'Mot de passe trop court'),
  rememberMe: z.boolean().optional()
});

type LoginForm = z.infer<typeof loginSchema>;

const translations = {
  FR: {
    title: 'Portail du conseil de surveillance',
    subtitle: 'SCI Club Expo 1',
    description: 'Connectez-vous pour accéder à votre espace membre',
    login: 'Se connecter',
    register: 'S\'inscrire',
    email: 'Email',
    password: 'Mot de passe',
    rememberMe: 'Se souvenir de moi',
    forgotPassword: 'Mot de passe oublié ?',
    newMember: 'Nouveau membre ?',
    createAccount: 'Créer un compte',
    loading: 'Connexion en cours...',
    legalLinks: {
      terms: "Conditions générales",
      privacy: "Politique de confidentialité", 
      mentions: "Mentions légales",
      cookies: "Politique des cookies",
      rules: "Règlement du forum"
    }
  },
  EN: {
    title: 'Supervisory Board Portal',
    subtitle: 'SCI Club Expo 1',
    description: 'Sign in to access your member area',
    login: 'Sign in',
    register: 'Sign up',
    email: 'Email',
    password: 'Password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password?',
    newMember: 'New member?',
    createAccount: 'Create account',
    loading: 'Signing in...',
    legalLinks: {
      terms: "Terms of Service",
      privacy: "Privacy Policy",
      mentions: "Legal Notice",
      cookies: "Cookie Policy",
      rules: "Forum Rules"
    }
  },
  IT: {
    title: 'Portale del consiglio di sorveglianza',
    subtitle: 'SCI Club Expo 1',
    description: 'Accedi per accedere alla tua area membri',
    login: 'Accedi',
    register: 'Registrati',
    email: 'Email',
    password: 'Password',
    rememberMe: 'Ricordami',
    forgotPassword: 'Password dimenticata?',
    newMember: 'Nuovo membro?',
    createAccount: 'Crea account',
    loading: 'Accesso in corso...',
    legalLinks: {
      terms: "Termini di servizio",
      privacy: "Politica sulla privacy",
      mentions: "Note legali",
      cookies: "Politica dei cookie",
      rules: "Regole del forum"
    }
  }
};

export function AuthPage() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [language, setLanguage] = useState('FR');
  const [error, setError] = useState<string | null>(null);
  const t = translations[language];

  useEffect(() => {
    if (user) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<LoginForm>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      rememberMe: false
    }
  });

  const onSubmit = async (data: LoginForm) => {
    try {
      setError(null);
      await setPersistence(auth, 
        data.rememberMe ? browserLocalPersistence : browserSessionPersistence
      );
      await signInWithEmailAndPassword(auth, data.email, data.password);
    } catch (error: any) {
      console.error('Login error:', error);
      setError('Email ou mot de passe incorrect');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      {/* Language Selector */}
      <div className="absolute top-4 right-4">
        <select
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          className="appearance-none pl-10 pr-8 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
        >
          <option value="FR">🇫🇷 Français</option>
          <option value="EN">🇬🇧 English</option>
          <option value="IT">🇮🇹 Italiano</option>
        </select>
        <Globe className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Shield className="mx-auto h-12 w-12 text-blue-600" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {t.title}
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          {t.subtitle}
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && (
            <div className="mb-4 rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="ml-3">
                  <p className="text-sm font-medium text-red-800">{error}</p>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t.email}
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  {...register('email')}
                  type="email"
                  className={clsx(
                    "pl-10 block w-full rounded-md shadow-sm sm:text-sm",
                    errors.email ? "border-red-300" : "border-gray-300"
                  )}
                />
              </div>
              {errors.email && (
                <p className="mt-2 text-sm text-red-600">{errors.email.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t.password}
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  {...register('password')}
                  type="password"
                  className={clsx(
                    "pl-10 block w-full rounded-md shadow-sm sm:text-sm",
                    errors.password ? "border-red-300" : "border-gray-300"
                  )}
                />
              </div>
              {errors.password && (
                <p className="mt-2 text-sm text-red-600">{errors.password.message}</p>
              )}
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  {...register('rememberMe')}
                  type="checkbox"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label className="ml-2 block text-sm text-gray-900">
                  {t.rememberMe}
                </label>
              </div>

              <div className="text-sm">
                <button
                  type="button"
                  onClick={() => navigate('/auth/reset-password')}
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  {t.forgotPassword}
                </button>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isSubmitting ? (
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                ) : (
                  <>
                    <LogIn className="h-5 w-5 mr-2" />
                    {t.login}
                  </>
                )}
              </button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  {t.newMember}
                </span>
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={() => navigate('/auth/register')}
                className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <UserPlus className="h-5 w-5 mr-2" />
                {t.register}
              </button>
            </div>
          </div>
        </div>

        {/* Legal Links */}
        <div className="mt-6 grid grid-cols-2 gap-4 text-sm text-center">
          <Link to="/legal/mentions" className="text-gray-500 hover:text-gray-900">
            {t.legalLinks.mentions}
          </Link>
          <Link to="/legal/cgu" className="text-gray-500 hover:text-gray-900">
            {t.legalLinks.terms}
          </Link>
          <Link to="/legal/privacy" className="text-gray-500 hover:text-gray-900">
            {t.legalLinks.privacy}
          </Link>
          <Link to="/legal/cookies" className="text-gray-500 hover:text-gray-900">
            {t.legalLinks.cookies}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AuthPage;