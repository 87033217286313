import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { 
  Users, 
  MessageSquare, 
  FileText,
  Bell,
  Building,
  Calendar,
  Shield,
  Clock,
  Plus
} from 'lucide-react';
import { StatsOverview } from '../components/dashboard/StatsOverview';
import { RecentPosts } from '../components/dashboard/RecentPosts';
import { CouncilMembers } from '../components/dashboard/CouncilMembers';
import { RecentDocuments } from '../components/dashboard/RecentDocuments';
import { AlertBanner } from '../components/dashboard/AlertBanner';
import { ChatWindow } from '../components/chat/ChatWindow';
import { useAuthStore } from '../store/authStore';
import { useNavigate } from 'react-router-dom';
import { dashboardTranslations } from '../translations/dashboard';

export function Dashboard() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [stats, setStats] = useState({
    totalUsers: 0,
    activePosts: 0,
    totalDocuments: 0,
    onlineUsers: 0
  });
  const [recentPosts, setRecentPosts] = useState([]);
  const [recentDocuments, setRecentDocuments] = useState([]);
  const [councilMembers, setCouncilMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');

  const userLanguage = user?.preferredLanguage?.toLowerCase() || 'fr';
  const t = dashboardTranslations[userLanguage];

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Fetch stats
        const [usersCount, postsCount, documentsCount, onlineCount] = await Promise.all([
          getDocs(collection(db, 'users')),
          getDocs(query(collection(db, 'forum_posts'), where('status', '==', 'approved'))),
          getDocs(collection(db, 'drive_files')),
          getDocs(query(collection(db, 'users'), where('isOnline', '==', true)))
        ]);

        setStats({
          totalUsers: usersCount.size,
          activePosts: postsCount.size,
          totalDocuments: documentsCount.size,
          onlineUsers: onlineCount.size
        });

        // Fetch recent posts
        const postsQuery = query(
          collection(db, 'forum_posts'),
          where('status', '==', 'approved'),
          orderBy('createdAt', 'desc'),
          limit(5)
        );
        const postsSnapshot = await getDocs(postsQuery);
        const postsData = postsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date()
        }));
        setRecentPosts(postsData);

        // Fetch recent documents
        const docsQuery = query(
          collection(db, 'drive_files'),
          orderBy('uploadedAt', 'desc'),
          limit(5)
        );
        const docsSnapshot = await getDocs(docsQuery);
        const docsData = docsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          uploadedAt: doc.data().uploadedAt?.toDate() || new Date()
        }));
        setRecentDocuments(docsData);

        // Fetch council members
        const councilQuery = query(
          collection(db, 'users'),
          where('role', '==', 'council')
        );
        const councilSnapshot = await getDocs(councilQuery);
        const councilData = councilSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCouncilMembers(councilData);

        // Fetch alert message
        const settingsDoc = await getDoc(doc(db, 'settings', 'general'));
        if (settingsDoc.exists()) {
          const message = "Ce site web est exclusivement destiné aux associés de la SCI Club Expo 1 afin de renforcer la communication et de faciliter l'échange d'informations entre eux. Il a été conçu pour encourager une collaboration efficace concernant les activités internes de la société.\n\nImportant : Vous pouvez utiliser la messagerie interne pour échanger avec le conseil de surveillance ou pour discuter avec les associés à propos d'une publication déposée sur le forum. Si vous souhaitez remonter une information, ou encore trouver quelqu'un pour acheter, vendre ou échanger votre semaine, cet espace est dédié à simplifier les interactions entre associés.\n\nCependant, il est essentiel de noter qu'aucune transaction de vente, d'achat ou d'échange ne doit être effectuée sans l'accord explicite de Clubhotel Multivacances. Pour toute opération de ce type, veuillez contacter Clubhotel Multivacances à l'adresse mail suivante : ventes@clubhotelmultivacances.com";
          setAlertMessage(message);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Alert Banner */}
      {alertMessage && <AlertBanner message={alertMessage} />}

      {/* Stats Overview */}
      <StatsOverview
        stats={[
          {
            title: t.stats.activeMembers,
            value: stats.onlineUsers,
            icon: Users,
            color: 'green',
            trend: { value: '+12%', positive: true }
          },
          {
            title: t.stats.posts,
            value: stats.activePosts,
            icon: MessageSquare,
            color: 'blue',
            trend: { value: '+5%', positive: true }
          },
          {
            title: t.stats.documents,
            value: stats.totalDocuments,
            icon: FileText,
            color: 'purple',
            trend: { value: '+8%', positive: true }
          },
          {
            title: t.stats.responseTime,
            value: '24',
            suffix: t.stats.minutes,
            icon: Clock,
            color: 'yellow',
            trend: { value: '-10%', positive: true }
          }
        ]}
      />

      {/* Main Content Grid */}
      <div className="grid grid-cols-12 gap-6">
        {/* Left Column */}
        <div className="col-span-12 lg:col-span-8 space-y-6">
          {/* Recent Posts */}
          <RecentPosts posts={recentPosts} />
          
          {/* Recent Documents */}
          <RecentDocuments documents={recentDocuments} />
        </div>

        {/* Right Column */}
        <div className="col-span-12 lg:col-span-4 space-y-6">
          {/* Council Members */}
          <CouncilMembers members={councilMembers} />

          {/* Quick Actions */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">
              {t.quickActions.title}
            </h2>
            <div className="space-y-3">
              <button
                onClick={() => navigate('/forum?new=true')}
                className="w-full flex items-center justify-between p-3 text-left rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="flex items-center">
                  <Plus className="h-5 w-5 text-blue-500 mr-3" />
                  <span>{t.quickActions.newPost}</span>
                </span>
              </button>
              <button
                onClick={() => navigate('/documents?upload=true')}
                className="w-full flex items-center justify-between p-3 text-left rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="flex items-center">
                  <FileText className="h-5 w-5 text-green-500 mr-3" />
                  <span>{t.quickActions.addDocument}</span>
                </span>
              </button>
              <button
                onClick={() => navigate('/messages')}
                className="w-full flex items-center justify-between p-3 text-left rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="flex items-center">
                  <MessageSquare className="h-5 w-5 text-purple-500 mr-3" />
                  <span>{t.quickActions.sendMessage}</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* AI Assistant */}
      <ChatWindow />
    </div>
  );
}

export default Dashboard;