import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { fr, enGB, it } from 'date-fns/locale';
import { 
  Shield,
  MessageCircle,
  Mail,
  Phone,
  MapPin,
  Clock,
  Crown,
  Award,
  FileText,
  Briefcase
} from 'lucide-react';
import { clsx } from 'clsx';
import { useAuthStore } from '../store/authStore';
import { councilTranslations } from '../translations/council';

interface CouncilMember {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  councilRole: string;
  isOnline: boolean;
  lastSeen: Date | null;
}

const roleIcons = {
  'president': Crown,
  'vice-president': Award,
  'secretary': FileText,
  'treasurer': Briefcase,
  'member': Shield
};

const defaultMembers = [
  {
    id: '1',
    firstName: 'Multire',
    lastName: 'SRL',
    email: 'booking.multire@gmail.com',
    phone: '+393483393678',
    role: 'council',
    councilRole: 'member',
    isOnline: false,
    lastSeen: new Date('2023-11-29T00:05:00')
  },
  {
    id: '2',
    firstName: 'Florence',
    lastName: 'Geay',
    email: 'florence.geay3@orange.fr',
    phone: '',
    role: 'council',
    councilRole: 'president',
    isOnline: false,
    lastSeen: new Date('2023-11-29T00:05:00')
  },
  {
    id: '3',
    firstName: 'Alain',
    lastName: 'Gross',
    email: 'algross38@gmail.com',
    phone: '',
    role: 'council',
    councilRole: 'secretary',
    isOnline: false,
    lastSeen: new Date('2023-11-29T00:05:00')
  },
  {
    id: '4',
    firstName: 'Willy',
    lastName: 'Cavallo Renou',
    email: 'CAVALLO@LIVE.FR',
    phone: '0682345009',
    role: 'council',
    councilRole: 'treasurer',
    isOnline: false,
    lastSeen: new Date('2023-11-29T00:05:00')
  }
];

const getDateLocale = (language: string) => {
  switch (language) {
    case 'en':
      return enGB;
    case 'it':
      return it;
    default:
      return fr;
  }
};

export function Council() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [members, setMembers] = useState<CouncilMember[]>(defaultMembers);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const userLanguage = user?.preferredLanguage?.toLowerCase() || 'fr';
  const t = councilTranslations[userLanguage];
  const dateLocale = getDateLocale(userLanguage);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const q = query(
          collection(db, 'users'),
          where('role', '==', 'council')
        );
        
        const querySnapshot = await getDocs(q);
        const membersData = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            lastSeen: data.lastSeen?.toDate() || null
          };
        }) as CouncilMember[];

        // If no members found in DB, use default members
        setMembers(membersData.length > 0 ? membersData : defaultMembers);
      } catch (error) {
        console.error('Error fetching council members:', error);
        setError('Une erreur est survenue lors du chargement des membres du conseil');
        setMembers(defaultMembers);
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, []);

  const formatLastSeen = (date: Date | null): string => {
    if (!date) return '-';
    return format(date, 'dd MMMM à HH:mm', { locale: dateLocale });
  };

  const handleContact = (memberId: string) => {
    navigate(`/messages?new=${memberId}`);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12 bg-white shadow rounded-lg">
        <Shield className="mx-auto h-12 w-12 text-red-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          {error}
        </h3>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-semibold text-gray-900">{t.title}</h1>
        <p className="mt-2 text-sm text-gray-700">{t.subtitle}</p>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {members.map((member) => {
          const RoleIcon = roleIcons[member.councilRole] || Shield;
          return (
            <div
              key={member.id}
              className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200"
            >
              <div className="px-6 py-5">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-12 w-12 rounded-full"
                      src={`https://ui-avatars.com/api/?name=${member.firstName}+${member.lastName}&background=random`}
                      alt=""
                    />
                    <div className="ml-4">
                      <h3 className="text-lg font-medium text-gray-900">
                        {member.firstName} {member.lastName}
                      </h3>
                      <div className="flex items-center mt-1">
                        <RoleIcon className="h-4 w-4 text-blue-500 mr-1" />
                        <p className="text-sm text-gray-500">
                          {t.roles[member.councilRole]}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={clsx(
                    'flex-shrink-0 w-3 h-3 rounded-full',
                    member.isOnline ? 'bg-green-400' : 'bg-gray-300'
                  )} />
                </div>
              </div>

              <div className="px-6 py-4 space-y-3">
                <div className="flex items-center text-sm text-gray-500">
                  <Mail className="h-4 w-4 mr-2" />
                  {member.email}
                </div>
                {member.phone && (
                  <div className="flex items-center text-sm text-gray-500">
                    <Phone className="h-4 w-4 mr-2" />
                    {member.phone}
                  </div>
                )}
                {!member.isOnline && member.lastSeen && (
                  <div className="flex items-center text-sm text-gray-500">
                    <Clock className="h-4 w-4 mr-2" />
                    {t.lastSeen} {formatLastSeen(member.lastSeen)}
                  </div>
                )}
              </div>

              <div className="px-6 py-4">
                <button
                  onClick={() => handleContact(member.id)}
                  className="w-full inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <MessageCircle className="h-5 w-5 mr-2 text-gray-400" />
                  {t.contact}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Council;