import React from 'react';
import { Link } from 'react-router-dom';
import { Shield, Scale, Lock, BookOpen, Cookie } from 'lucide-react';
import { useAuthStore } from '../store/authStore';

export function LegalHub() {
  const { user } = useAuthStore();
  const language = user?.preferredLanguage?.toLowerCase() || 'fr';

  const translations = {
    fr: {
      title: "Informations Légales",
      subtitle: "Consultez tous nos documents légaux",
      sections: [
        {
          title: "Mentions Légales",
          description: "Informations légales sur la société et le site",
          icon: Scale,
          path: "/legal/mentions"
        },
        {
          title: "Conditions Générales d'Utilisation",
          description: "Règles d'utilisation de la plateforme",
          icon: Shield,
          path: "/legal/cgu"
        },
        {
          title: "Politique de Confidentialité",
          description: "Comment nous protégeons vos données",
          icon: Lock,
          path: "/legal/privacy"
        },
        {
          title: "Règlement du Forum",
          description: "Règles de conduite sur le forum",
          icon: BookOpen,
          path: "/legal/forum-rules"
        },
        {
          title: "Politique des Cookies",
          description: "Utilisation des cookies sur le site",
          icon: Cookie,
          path: "/legal/cookies"
        }
      ]
    },
    en: {
      title: "Legal Information",
      subtitle: "View all our legal documents",
      sections: [
        {
          title: "Legal Notice",
          description: "Legal information about the company and website",
          icon: Scale,
          path: "/legal/mentions"
        },
        {
          title: "Terms of Service",
          description: "Platform usage rules",
          icon: Shield,
          path: "/legal/cgu"
        },
        {
          title: "Privacy Policy",
          description: "How we protect your data",
          icon: Lock,
          path: "/legal/privacy"
        },
        {
          title: "Forum Rules",
          description: "Forum conduct rules",
          icon: BookOpen,
          path: "/legal/forum-rules"
        },
        {
          title: "Cookie Policy",
          description: "Cookie usage on the site",
          icon: Cookie,
          path: "/legal/cookies"
        }
      ]
    },
    it: {
      title: "Informazioni Legali",
      subtitle: "Consulta tutti i nostri documenti legali",
      sections: [
        {
          title: "Note Legali",
          description: "Informazioni legali sulla società e il sito",
          icon: Scale,
          path: "/legal/mentions"
        },
        {
          title: "Termini di Servizio",
          description: "Regole di utilizzo della piattaforma",
          icon: Shield,
          path: "/legal/cgu"
        },
        {
          title: "Politica sulla Privacy",
          description: "Come proteggiamo i tuoi dati",
          icon: Lock,
          path: "/legal/privacy"
        },
        {
          title: "Regole del Forum",
          description: "Regole di condotta sul forum",
          icon: BookOpen,
          path: "/legal/forum-rules"
        },
        {
          title: "Politica dei Cookie",
          description: "Utilizzo dei cookie sul sito",
          icon: Cookie,
          path: "/legal/cookies"
        }
      ]
    }
  };

  const t = translations[language];

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-900">{t.title}</h1>
          <p className="mt-4 text-lg text-gray-500">{t.subtitle}</p>
        </div>

        <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {t.sections.map((section, index) => (
            <Link
              key={index}
              to={section.path}
              className="relative group bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  <section.icon className="h-8 w-8 text-blue-500" />
                </div>
                <div>
                  <h3 className="text-lg font-medium text-gray-900 group-hover:text-blue-600 transition-colors">
                    {section.title}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {section.description}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LegalHub;