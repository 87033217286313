import React from 'react';
import { Link } from 'react-router-dom';
import { X, Building, LogOut } from 'lucide-react';
import { clsx } from 'clsx';
import { LanguageSelector } from './LanguageSelector';
import { UserProfile } from './UserProfile';
import { LegalLinks } from './LegalLinks';
import type { NavigationItem } from './types';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  navigation: NavigationItem[];
  user: any;
  isLegalMenuOpen: boolean;
  setIsLegalMenuOpen: (open: boolean) => void;
  handleLogout: () => void;
  t: any;
}

export function MobileMenu({
  isOpen,
  onClose,
  navigation,
  user,
  isLegalMenuOpen,
  setIsLegalMenuOpen,
  handleLogout,
  t
}: MobileMenuProps) {
  return (
    <div
      className={clsx(
        "fixed inset-0 z-40 md:hidden transition-opacity duration-300",
        isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      )}
    >
      {/* Backdrop */}
      <div 
        className="fixed inset-0 bg-gray-600 bg-opacity-75"
        onClick={onClose}
      />

      {/* Menu panel */}
      <div
        className={clsx(
          "fixed inset-y-0 left-0 flex flex-col w-full max-w-xs bg-white transform transition-transform duration-300 ease-in-out",
          isOpen ? "translate-x-0" : "-translate-x-full"
        )}
      >
        {/* Header */}
        <div className="flex items-center justify-between px-4 py-6 border-b border-gray-200">
          <div className="flex items-center">
            <Building className="h-8 w-8 text-blue-600" />
            <span className="ml-3 text-xl font-semibold text-gray-900">SCI Club Expo</span>
          </div>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-600 focus:outline-none"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {/* User profile */}
        <UserProfile user={user} t={t} />

        {/* Language selector */}
        <LanguageSelector user={user} />

        {/* Navigation */}
        <nav className="flex-1 overflow-y-auto px-2 py-4">
          {navigation.map((item) => {
            const isActive = location.pathname === item.path ||
              (item.path === '/admin' && location.pathname.startsWith('/admin'));
            
            return (
              <Link
                key={item.path}
                to={item.path}
                onClick={onClose}
                className={clsx(
                  "flex items-center px-4 py-3 text-base font-medium rounded-md mb-1",
                  isActive
                    ? "bg-blue-50 text-blue-600"
                    : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                )}
              >
                <item.icon className={clsx(
                  "h-5 w-5 mr-3",
                  isActive ? "text-blue-600" : "text-gray-400"
                )} />
                {item.name}
              </Link>
            );
          })}
        </nav>

        {/* Legal links */}
        <LegalLinks 
          isOpen={isLegalMenuOpen} 
          setIsOpen={setIsLegalMenuOpen}
          onClose={onClose}
          t={t}
        />

        {/* Logout */}
        <div className="px-4 py-4 border-t border-gray-200">
          <button
            onClick={handleLogout}
            className="flex items-center w-full px-4 py-3 text-base font-medium text-red-600 hover:bg-red-50 rounded-md"
          >
            <LogOut className="mr-3 h-5 w-5" />
            {t.logout}
          </button>
        </div>
      </div>
    </div>
  );
}