import React from 'react';
import { Bot, User } from 'lucide-react';
import { clsx } from 'clsx';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { Message } from '../../types/chat';

interface ChatMessageProps {
  message: Message;
  isLoading?: boolean;
}

export function ChatMessage({ message, isLoading }: ChatMessageProps) {
  const isAssistant = message.role === 'assistant';

  return (
    <div className={clsx(
      "flex items-start space-x-3 p-4 rounded-lg transition-colors",
      isAssistant ? "bg-blue-50 hover:bg-blue-100" : "bg-gray-50 hover:bg-gray-100"
    )}>
      <div className={clsx(
        "flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center",
        isAssistant ? "bg-blue-100" : "bg-gray-200"
      )}>
        {isAssistant ? (
          <Bot className="h-5 w-5 text-blue-600" />
        ) : (
          <User className="h-5 w-5 text-gray-600" />
        )}
      </div>
      <div className="flex-1 space-y-1">
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium text-gray-900">
            {isAssistant ? 'Assistant' : 'Vous'}
          </span>
          <span className="text-xs text-gray-500">
            {format(message.timestamp, 'HH:mm', { locale: fr })}
          </span>
        </div>
        <div className={clsx(
          "text-sm text-gray-700 whitespace-pre-wrap",
          isLoading && "animate-pulse"
        )}>
          {message.content}
        </div>
      </div>
    </div>
  );
}