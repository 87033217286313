import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { PrivateRoute } from './components/PrivateRoute';
import { LoadingScreen } from './components/LoadingScreen';
import { AuthPage } from './pages/AuthPage';
import { Register } from './pages/Register';
import { Dashboard } from './pages/Dashboard';
import { Messages } from './pages/Messages';
import { Documents } from './pages/Documents';
import { Forum } from './pages/Forum';
import { MyPosts } from './pages/MyPosts';
import { Council } from './pages/Council';
import { Profile } from './pages/Profile';
import { Admin } from './pages/Admin';
import { Assistance } from './pages/Assistance';
import { LegalHub } from './pages/LegalHub';
import { LegalMentions } from './pages/legal/LegalMentions';
import { TermsOfService } from './pages/legal/TermsOfService';
import { PrivacyPolicy } from './pages/legal/PrivacyPolicy';
import { ForumRules } from './pages/legal/ForumRules';
import { CookiePolicy } from './pages/legal/CookiePolicy';
import { useAuthStore } from './store/authStore';
import { useAuth } from './hooks/useAuth';

function App() {
  const { isLoading } = useAuthStore();
  useAuth();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/auth/register" element={<Register />} />
        <Route path="/legal" element={<LegalHub />} />
        <Route element={<Layout />}>
          <Route path="/" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } />
          <Route path="/forum" element={
            <PrivateRoute>
              <Forum />
            </PrivateRoute>
          } />
          <Route path="/my-posts" element={
            <PrivateRoute>
              <MyPosts />
            </PrivateRoute>
          } />
          <Route path="/messages" element={
            <PrivateRoute>
              <Messages />
            </PrivateRoute>
          } />
          <Route path="/documents" element={
            <PrivateRoute>
              <Documents />
            </PrivateRoute>
          } />
          <Route path="/council" element={
            <PrivateRoute>
              <Council />
            </PrivateRoute>
          } />
          <Route path="/assistance" element={
            <PrivateRoute>
              <Assistance />
            </PrivateRoute>
          } />
          <Route path="/profile" element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          } />
          <Route path="/admin/*" element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          } />
          {/* Legal Routes */}
          <Route path="/legal/mentions" element={<LegalMentions />} />
          <Route path="/legal/cgu" element={<TermsOfService />} />
          <Route path="/legal/privacy" element={<PrivacyPolicy />} />
          <Route path="/legal/forum-rules" element={<ForumRules />} />
          <Route path="/legal/cookies" element={<CookiePolicy />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;