import React from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { 
  Check, 
  MessageCircle, 
  FileText, 
  Bell,
  Eye,
  AlertCircle
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import { useNotifications } from '../hooks/useNotifications';
import type { Notification } from '../types/notification';

interface NotificationDropdownProps {
  onClose: () => void;
}

export function NotificationDropdown({ onClose }: NotificationDropdownProps) {
  const { notifications, unreadCount, markAsRead, markAllAsRead } = useNotifications();
  const navigate = useNavigate();

  const handleNotificationClick = async (notification: Notification) => {
    if (!notification.read) {
      await markAsRead(notification.id);
    }

    onClose();

    // Navigate based on notification type
    switch (notification.type) {
      case 'document_added':
        navigate(`/documents?id=${notification.data?.documentId}`);
        break;
      case 'post_approved':
      case 'post_rejected':
      case 'new_post':
        navigate(`/forum?post=${notification.data?.postId}`);
        break;
      case 'new_message':
        if (notification.data?.conversationId) {
          navigate(`/messages?id=${notification.data.conversationId}`);
        }
        break;
      default:
        break;
    }
  };

  const getNotificationIcon = (type: string, read: boolean) => {
    const className = clsx(
      "h-5 w-5",
      read ? "text-gray-400" : "text-blue-500"
    );

    switch (type) {
      case 'document_added':
        return <FileText className={className} />;
      case 'new_message':
        return <MessageCircle className={className} />;
      case 'post_approved':
        return <Check className={className} />;
      case 'post_rejected':
        return <AlertCircle className={className} />;
      case 'new_post':
        return <Eye className={className} />;
      default:
        return <Bell className={className} />;
    }
  };

  return (
    <div className="max-h-96 overflow-y-auto">
      <div className="px-4 py-3 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-medium text-gray-900">Notifications</h3>
          {unreadCount > 0 && (
            <button
              onClick={markAllAsRead}
              className="text-xs text-blue-600 hover:text-blue-500"
            >
              Tout marquer comme lu
            </button>
          )}
        </div>
      </div>

      {notifications.length === 0 ? (
        <div className="px-4 py-6 text-center text-sm text-gray-500">
          Aucune notification
        </div>
      ) : (
        <div className="divide-y divide-gray-200">
          {notifications.map((notification) => (
            <button
              key={notification.id}
              onClick={() => handleNotificationClick(notification)}
              className={clsx(
                "w-full px-4 py-3 flex items-start hover:bg-gray-50 transition-colors",
                !notification.read && "bg-blue-50"
              )}
            >
              <div className={clsx(
                "flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center",
                !notification.read ? "bg-blue-100" : "bg-gray-100"
              )}>
                {getNotificationIcon(notification.type, notification.read)}
              </div>
              <div className="ml-3 flex-1 text-left">
                <p className={clsx(
                  "text-sm",
                  !notification.read ? "font-medium text-gray-900" : "text-gray-600"
                )}>
                  {notification.title}
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  {notification.message}
                </p>
                <p className="mt-1 text-xs text-gray-400">
                  {format(notification.createdAt, 'dd MMMM à HH:mm', { locale: fr })}
                </p>
              </div>
              {!notification.read && (
                <div className="ml-3 flex-shrink-0">
                  <div className="h-2 w-2 rounded-full bg-blue-600"></div>
                </div>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}