import React, { useState } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Menu, Transition } from '@headlessui/react';
import { MoreVertical, Trash2, Globe } from 'lucide-react';
import { clsx } from 'clsx';
import { useTranslation } from '../hooks/useTranslation';
import { decodeHtmlEntities } from '../utils/textUtils';
import type { Message } from '../types/message';

interface MessageBubbleProps {
  message: Message;
  isOwnMessage: boolean;
  onDelete: (messageId: string) => void;
  userLanguage: string;
}

export function MessageBubble({ message, isOwnMessage, onDelete, userLanguage }: MessageBubbleProps) {
  const [showOriginal, setShowOriginal] = useState(false);
  const { getTranslatedContent } = useTranslation();

  const translatedContent = getTranslatedContent(message);
  const messageContent = showOriginal ? decodeHtmlEntities(message.content) : translatedContent.content;

  const hasTranslation = message.translations && 
    Object.keys(message.translations).length > 0 && 
    message.translations[userLanguage] !== message.content;

  const formatMessageDate = (date: Date | null | undefined): string => {
    if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
      return '';
    }
    return format(date, 'HH:mm', { locale: fr });
  };

  return (
    <div className={clsx(
      "group flex",
      isOwnMessage ? "justify-end" : "justify-start"
    )}>
      <div className="relative max-w-[70%]">
        <div className={clsx(
          "rounded-lg px-4 py-2 shadow",
          isOwnMessage
            ? "bg-blue-600 text-white rounded-br-none"
            : "bg-white rounded-bl-none"
        )}>
          <p className="whitespace-pre-wrap break-words">{messageContent}</p>
          <div className="flex items-center justify-end space-x-2 mt-1">
            <p className={clsx(
              "text-xs",
              isOwnMessage ? "text-blue-100" : "text-gray-400"
            )}>
              {formatMessageDate(message.createdAt)}
            </p>
            {message.isRead && isOwnMessage && (
              <span className="text-xs text-blue-100">✓</span>
            )}
          </div>
        </div>

        {hasTranslation && (
          <button
            onClick={() => setShowOriginal(!showOriginal)}
            className={clsx(
              "absolute -left-6 top-0 p-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity",
              isOwnMessage ? "text-white" : "text-gray-400 hover:text-gray-600 hover:bg-gray-100"
            )}
            title={showOriginal ? "Voir la traduction" : "Voir le message original"}
          >
            <Globe className="h-4 w-4" />
          </button>
        )}

        {isOwnMessage && (
          <Menu as="div" className="absolute top-0 right-0 -mr-8 opacity-0 group-hover:opacity-100 transition-opacity">
            <Menu.Button className="p-1 rounded-full text-gray-400 hover:text-gray-600 hover:bg-gray-100">
              <MoreVertical className="h-4 w-4" />
            </Menu.Button>
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => onDelete(message.id)}
                      className={clsx(
                        active ? 'bg-red-50 text-red-700' : 'text-red-600',
                        'flex w-full items-center px-4 py-2 text-sm'
                      )}
                    >
                      <Trash2 className="h-4 w-4 mr-2" />
                      Supprimer le message
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
    </div>
  );
}