import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthStore } from '../store/authStore';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Edit, AlertCircle, CheckCircle, XCircle, Clock, Trash2, Plus } from 'lucide-react';
import { EditPostModal } from '../components/EditPostModal';
import { DeletePostModal } from '../components/DeletePostModal';
import { MarketplacePostModal } from '../components/MarketplacePostModal';
import { CreatePostModal } from '../components/forum/CreatePostModal';
import { PostTypeSelector } from '../components/forum/PostTypeSelector';
import { useTranslation } from '../hooks/useTranslation';
import type { Post, PostType } from '../types/post';

const translations = {
  fr: {
    title: "Mes publications",
    subtitle: "Gérez vos publications sur le forum",
    newPost: "Nouvelle publication",
    noPosts: {
      title: "Aucune publication",
      description: "Vous n'avez pas encore créé de publication."
    },
    status: {
      pending: "En attente",
      approved: "Approuvé",
      rejected: "Rejeté"
    },
    actions: {
      edit: "Modifier",
      delete: "Supprimer",
      create: "Créer une publication"
    },
    rejection: {
      title: "Motif du rejet :",
    },
    postType: {
      vente_parts: "Vente de parts",
      achat_parts: "Achat de parts",
      echange_semaines: "Échange de semaines",
      question: "Question",
      discussion: "Discussion générale"
    }
  },
  en: {
    title: "My Posts",
    subtitle: "Manage your forum posts",
    newPost: "New Post",
    noPosts: {
      title: "No posts",
      description: "You haven't created any posts yet."
    },
    status: {
      pending: "Pending",
      approved: "Approved",
      rejected: "Rejected"
    },
    actions: {
      edit: "Edit",
      delete: "Delete",
      create: "Create post"
    },
    rejection: {
      title: "Rejection reason:",
    },
    postType: {
      vente_parts: "Share sale",
      achat_parts: "Share purchase",
      echange_semaines: "Week exchange",
      question: "Question",
      discussion: "General discussion"
    }
  },
  it: {
    title: "Le mie pubblicazioni",
    subtitle: "Gestisci le tue pubblicazioni sul forum",
    newPost: "Nuova pubblicazione",
    noPosts: {
      title: "Nessuna pubblicazione",
      description: "Non hai ancora creato pubblicazioni."
    },
    status: {
      pending: "In attesa",
      approved: "Approvato",
      rejected: "Rifiutato"
    },
    actions: {
      edit: "Modifica",
      delete: "Elimina",
      create: "Crea pubblicazione"
    },
    rejection: {
      title: "Motivo del rifiuto:",
    },
    postType: {
      vente_parts: "Vendita quote",
      achat_parts: "Acquisto quote",
      echange_semaines: "Scambio settimane",
      question: "Domanda",
      discussion: "Discussione generale"
    }
  }
};

export function MyPosts() {
  const { user } = useAuthStore();
  const { getTranslatedContent } = useTranslation();
  const [posts, setPosts] = useState<Post[]>([]);
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPostTypeSelectorOpen, setIsPostTypeSelectorOpen] = useState(false);
  const [isMarketplaceModalOpen, setIsMarketplaceModalOpen] = useState(false);
  const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
  const [selectedPostType, setSelectedPostType] = useState<PostType | null>(null);
  const [loading, setLoading] = useState(true);

  const userLanguage = user?.preferredLanguage?.toLowerCase() || 'fr';
  const t = translations[userLanguage];

  useEffect(() => {
    if (!user?.id) return;

    const q = query(
      collection(db, 'forum_posts'),
      where('authorId', '==', user.id),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const postsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      })) as Post[];
      setPosts(postsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user?.id]);

  const handlePostTypeSelect = (type: PostType) => {
    setSelectedPostType(type);
    setIsPostTypeSelectorOpen(false);
    
    if (['vente_parts', 'achat_parts', 'echange_semaines'].includes(type)) {
      setIsMarketplaceModalOpen(true);
    } else {
      setIsCreatePostModalOpen(true);
    }
  };

  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'pending':
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            <Clock className="h-4 w-4 mr-1" />
            {t.status.pending}
          </span>
        );
      case 'approved':
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            <CheckCircle className="h-4 w-4 mr-1" />
            {t.status.approved}
          </span>
        );
      case 'rejected':
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
            <XCircle className="h-4 w-4 mr-1" />
            {t.status.rejected}
          </span>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">{t.title}</h1>
          <p className="mt-2 text-sm text-gray-700">{t.subtitle}</p>
        </div>
        <button
          onClick={() => setIsPostTypeSelectorOpen(true)}
          className="mt-4 sm:mt-0 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <Plus className="h-5 w-5 mr-2" />
          {t.newPost}
        </button>
      </div>

      {posts.length === 0 ? (
        <div className="text-center py-12 bg-white shadow rounded-lg">
          <AlertCircle className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            {t.noPosts.title}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {t.noPosts.description}
          </p>
          <button
            onClick={() => setIsPostTypeSelectorOpen(true)}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Plus className="h-5 w-5 mr-2" />
            {t.actions.create}
          </button>
        </div>
      ) : (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <ul className="divide-y divide-gray-200">
            {posts.map((post) => {
              const translatedContent = getTranslatedContent(post);
              return (
                <li key={post.id} className="p-6">
                  <div className="flex flex-col space-y-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <h3 className="text-lg font-medium text-gray-900">
                          {translatedContent.title}
                        </h3>
                        {getStatusBadge(post.status)}
                      </div>
                      <div className="flex items-center space-x-2">
                        <button
                          onClick={() => {
                            setSelectedPost(post);
                            setIsEditModalOpen(true);
                          }}
                          className="inline-flex items-center px-3 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                          <Edit className="h-4 w-4 mr-1" />
                          {t.actions.edit}
                        </button>
                        <button
                          onClick={() => {
                            setSelectedPost(post);
                            setIsDeleteModalOpen(true);
                          }}
                          className="inline-flex items-center px-3 py-1 border border-red-300 shadow-sm text-sm leading-4 font-medium rounded-md text-red-700 bg-white hover:bg-red-50"
                        >
                          <Trash2 className="h-4 w-4 mr-1" />
                          {t.actions.delete}
                        </button>
                      </div>
                    </div>

                    <p className="text-gray-600 whitespace-pre-line">{translatedContent.content}</p>

                    <div className="flex items-center justify-between text-sm text-gray-500">
                      <div className="flex items-center space-x-4">
                        <span>Type: {t.postType[post.type]}</span>
                      </div>
                      <span>
                        {format(post.createdAt, 'dd MMMM yyyy à HH:mm', { locale: fr })}
                      </span>
                    </div>

                    {post.status === 'rejected' && post.rejectionReason && (
                      <div className="mt-2 p-3 bg-red-50 rounded-md">
                        <p className="text-sm text-red-700">
                          <strong>{t.rejection.title}</strong> {post.rejectionReason}
                        </p>
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {isPostTypeSelectorOpen && (
        <PostTypeSelector
          onSelect={handlePostTypeSelect}
          onClose={() => setIsPostTypeSelectorOpen(false)}
        />
      )}

      {selectedPostType && (
        <>
          {['vente_parts', 'achat_parts', 'echange_semaines'].includes(selectedPostType) ? (
            <MarketplacePostModal
              isOpen={isMarketplaceModalOpen}
              onClose={() => {
                setIsMarketplaceModalOpen(false);
                setSelectedPostType(null);
              }}
              type={selectedPostType}
            />
          ) : (
            <CreatePostModal
              isOpen={isCreatePostModalOpen}
              onClose={() => {
                setIsCreatePostModalOpen(false);
                setSelectedPostType(null);
              }}
              type={selectedPostType}
            />
          )}
        </>
      )}

      {selectedPost && (
        <>
          <EditPostModal
            isOpen={isEditModalOpen}
            onClose={() => {
              setIsEditModalOpen(false);
              setSelectedPost(null);
            }}
            post={selectedPost}
          />
          <DeletePostModal
            isOpen={isDeleteModalOpen}
            onClose={() => {
              setIsDeleteModalOpen(false);
              setSelectedPost(null);
            }}
            post={selectedPost}
          />
        </>
      )}
    </div>
  );
}

export default MyPosts;