import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import { 
  Users, 
  MessageSquare, 
  BarChart, 
  Key, 
  FileText, 
  Settings, 
  HelpCircle 
} from 'lucide-react';
import { clsx } from 'clsx';

export function AdminNav() {
  const location = useLocation();
  const { isAdmin } = useAuthStore();
  const currentPath = location.pathname.split('/')[2] || 'forum';

  // Define all nav items
  const allNavItems = [
    { path: 'forum', label: 'Modération', icon: MessageSquare },
    { path: 'users', label: 'Utilisateurs', icon: Users },
    { path: 'stats', label: 'Statistiques', icon: BarChart },
    { path: 'support', label: 'Assistance', icon: HelpCircle },
    { path: 'posts', label: 'Publications', icon: FileText, adminOnly: true },
    { path: 'invitations', label: 'Codes d\'invitation', icon: Key, adminOnly: true },
    { path: 'settings', label: 'Paramètres', icon: Settings, adminOnly: true }
  ];

  // Filter nav items based on user role
  const navItems = allNavItems.filter(item => !item.adminOnly || isAdmin());

  return (
    <nav className="bg-white shadow rounded-lg">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex space-x-8">
          {navItems.map((item) => (
            <Link
              key={item.path}
              to={`/admin/${item.path}`}
              className={clsx(
                'inline-flex items-center px-3 py-4 text-sm font-medium border-b-2',
                currentPath === item.path
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              )}
            >
              <item.icon className="h-5 w-5 mr-2" />
              {item.label}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
}

export default AdminNav;