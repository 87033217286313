import React from 'react';
import { Link } from 'react-router-dom';
import { Building, LogOut } from 'lucide-react';
import { clsx } from 'clsx';
import { LanguageSelector } from './LanguageSelector';
import { UserProfile } from './UserProfile';
import { LegalLinks } from './LegalLinks';
import type { NavigationItem } from './types';

interface DesktopSidebarProps {
  navigation: NavigationItem[];
  user: any;
  isLegalMenuOpen: boolean;
  setIsLegalMenuOpen: (open: boolean) => void;
  handleLogout: () => void;
  t: any;
}

export function DesktopSidebar({
  navigation,
  user,
  isLegalMenuOpen,
  setIsLegalMenuOpen,
  handleLogout,
  t
}: DesktopSidebarProps) {
  return (
    <div className="hidden md:flex md:flex-col md:w-64 md:fixed md:inset-y-0">
      <div className="flex flex-col flex-grow bg-white border-r border-gray-200">
        {/* Logo */}
        <div className="flex items-center flex-shrink-0 px-4 py-5">
          <Building className="h-8 w-8 text-blue-600" />
          <span className="ml-2 text-xl font-semibold text-gray-900">SCI Club Expo</span>
        </div>

        {/* User info */}
        <UserProfile user={user} t={t} />

        {/* Language selector */}
        <LanguageSelector user={user} />

        {/* Navigation */}
        <nav className="flex-1 px-4 space-y-1 overflow-y-auto">
          {navigation.map((item) => {
            const isActive = location.pathname === item.path ||
              (item.path === '/admin' && location.pathname.startsWith('/admin'));
            
            return (
              <Link
                key={item.path}
                to={item.path}
                className={clsx(
                  "group flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors",
                  isActive
                    ? "bg-blue-50 text-blue-600"
                    : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                )}
              >
                <item.icon
                  className={clsx(
                    "mr-3 flex-shrink-0 h-5 w-5",
                    isActive ? "text-blue-600" : "text-gray-400 group-hover:text-gray-500"
                  )}
                />
                <div>
                  <span>{item.name}</span>
                  <p className="text-xs text-gray-500">{item.description}</p>
                </div>
              </Link>
            );
          })}
        </nav>

        {/* Legal links */}
        <LegalLinks 
          isOpen={isLegalMenuOpen} 
          setIsOpen={setIsLegalMenuOpen}
          t={t}
        />

        {/* Logout */}
        <div className="px-4 py-4 border-t border-gray-200">
          <button
            onClick={handleLogout}
            className="flex items-center w-full px-3 py-2 text-sm font-medium text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md"
          >
            <LogOut className="mr-3 h-5 w-5" />
            {t.logout}
          </button>
        </div>
      </div>
    </div>
  );
}