import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthStore } from '../store/authStore';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { fr, enGB, it } from 'date-fns/locale';
import { Plus, AlertCircle } from 'lucide-react';
import { MarketplacePostModal } from '../components/MarketplacePostModal';
import { CreatePostModal } from '../components/forum/CreatePostModal';
import { ViewPostModal } from '../components/ViewPostModal';
import { PostCard } from '../components/forum/PostCard';
import { PostFilters } from '../components/forum/PostFilters';
import { Pagination } from '../components/forum/Pagination';
import { PostTypeSelector } from '../components/forum/PostTypeSelector';
import { forumTranslations } from '../translations/forum';
import type { Post, PostType } from '../types/post';

const POSTS_PER_PAGE = 9;

const getDateLocale = (language: string) => {
  switch (language) {
    case 'en': return enGB;
    case 'it': return it;
    default: return fr;
  }
};

export function Forum() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [posts, setPosts] = useState<Post[]>([]);
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const [isViewPostModalOpen, setIsViewPostModalOpen] = useState(false);
  const [isMarketplaceModalOpen, setIsMarketplaceModalOpen] = useState(false);
  const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
  const [isPostTypeSelectorOpen, setIsPostTypeSelectorOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<string>('');
  const [postType, setPostType] = useState<PostType | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const userLanguage = user?.preferredLanguage?.toLowerCase() || 'fr';
  const t = forumTranslations[userLanguage];
  const dateLocale = getDateLocale(userLanguage);

  useEffect(() => {
    const q = query(
      collection(db, 'forum_posts'),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const postsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      })) as Post[];
      setPosts(postsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleContact = (authorId: string) => {
    navigate(`/messages?new=${authorId}`);
  };

  const handlePostTypeSelect = (type: PostType) => {
    setPostType(type);
    setIsPostTypeSelectorOpen(false);
    
    if (['vente_parts', 'achat_parts', 'echange_semaines'].includes(type)) {
      setIsMarketplaceModalOpen(true);
    } else {
      setIsCreatePostModalOpen(true);
    }
  };

  const filteredPosts = selectedType 
    ? posts.filter(post => post.type === selectedType)
    : posts;

  const totalPages = Math.ceil(filteredPosts.length / POSTS_PER_PAGE);
  const startIndex = (currentPage - 1) * POSTS_PER_PAGE;
  const paginatedPosts = filteredPosts.slice(startIndex, startIndex + POSTS_PER_PAGE);

  const postCounts = posts.reduce((acc, post) => {
    acc[post.type] = (acc[post.type] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">{t.title}</h1>
          <p className="mt-2 text-sm text-gray-700">{t.subtitle}</p>
        </div>
        <button
          onClick={() => setIsPostTypeSelectorOpen(true)}
          className="mt-4 sm:mt-0 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <Plus className="h-5 w-5 mr-2" />
          {t.newPost}
        </button>
      </div>

      <PostFilters
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        postCounts={postCounts}
        totalPosts={posts.length}
        t={t}
      />

      {posts.length === 0 ? (
        <div className="text-center py-12 bg-white shadow rounded-lg">
          <AlertCircle className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            {t.noPost}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {t.beFirst}
          </p>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {paginatedPosts.map((post) => (
              <PostCard
                key={post.id}
                post={post}
                onView={(post) => {
                  setSelectedPost(post);
                  setIsViewPostModalOpen(true);
                }}
                onContact={handleContact}
                currentUserId={user?.id || ''}
                t={t}
                dateLocale={dateLocale}
              />
            ))}
          </div>

          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
              t={t}
              itemsPerPage={POSTS_PER_PAGE}
              totalItems={filteredPosts.length}
              startIndex={startIndex}
              endIndex={startIndex + POSTS_PER_PAGE}
            />
          )}
        </>
      )}

      {isPostTypeSelectorOpen && (
        <PostTypeSelector
          onSelect={handlePostTypeSelect}
          onClose={() => setIsPostTypeSelectorOpen(false)}
        />
      )}

      {postType && (
        <>
          {['vente_parts', 'achat_parts', 'echange_semaines'].includes(postType) ? (
            <MarketplacePostModal
              isOpen={isMarketplaceModalOpen}
              onClose={() => {
                setIsMarketplaceModalOpen(false);
                setPostType(null);
              }}
              type={postType}
            />
          ) : (
            <CreatePostModal
              isOpen={isCreatePostModalOpen}
              onClose={() => {
                setIsCreatePostModalOpen(false);
                setPostType(null);
              }}
              type={postType}
            />
          )}
        </>
      )}

      {selectedPost && (
        <ViewPostModal
          isOpen={isViewPostModalOpen}
          onClose={() => {
            setIsViewPostModalOpen(false);
            setSelectedPost(null);
          }}
          post={selectedPost}
        />
      )}
    </div>
  );
}

export default Forum;