import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';

export async function verifyInviteCode(code: string) {
  try {
    const codesRef = collection(db, 'invitation_codes');
    const q = query(
      codesRef,
      where('code', '==', code.toUpperCase()),
      where('used', '==', false)
    );
    
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      return { valid: false, error: 'Code d\'invitation invalide ou déjà utilisé' };
    }

    const codeDoc = querySnapshot.docs[0];
    const codeData = codeDoc.data();
    const now = new Date();
    const expiryDate = codeData.expiryDate.toDate();

    if (now > expiryDate) {
      return { valid: false, error: 'Code d\'invitation expiré' };
    }

    return {
      valid: true,
      docId: codeDoc.id
    };
  } catch (error) {
    console.error('Error verifying invite code:', error);
    return { valid: false, error: 'Erreur lors de la validation du code' };
  }
}