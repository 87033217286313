import { addDoc, collection, getDocs, query, serverTimestamp, where } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { translationService } from './translationService';
import type { NotificationType } from '../types/notification';

export async function createNotification({
  type,
  title,
  message,
  recipientId,
  data = {}
}: {
  type: NotificationType;
  title: string;
  message: string;
  recipientId: string;
  data?: Record<string, any>;
}) {
  try {
    // Validate required fields
    if (!type || !title || !message || !recipientId) {
      throw new Error('Missing required notification fields');
    }

    // Create translations for all supported languages
    const translations = {};
    const languages = ['fr', 'en', 'it'];
    
    await Promise.all(
      languages.map(async (lang) => {
        translations[lang] = {
          title: await translationService.translateText(title, lang),
          message: await translationService.translateText(message, lang)
        };
      })
    );

    // Create notification document with proper structure
    const notificationData = {
      type,
      title,
      message,
      recipientId,
      read: false,
      createdAt: serverTimestamp(),
      translations,
      data: JSON.parse(JSON.stringify(data)) // Ensure data is serializable
    };

    await addDoc(collection(db, 'notifications'), notificationData);
  } catch (error) {
    console.error('Error creating notification:', error);
    throw error;
  }
}

export async function notifyNewMessage(
  recipientId: string,
  senderName: string,
  conversationId: string,
  messageContent: string
) {
  if (!recipientId || !senderName || !conversationId) {
    console.error('Missing required fields for message notification');
    return;
  }

  await createNotification({
    type: 'new_message',
    title: `Nouveau message de ${senderName}`,
    message: messageContent.length > 100 
      ? `${messageContent.substring(0, 100)}...` 
      : messageContent,
    recipientId,
    data: { 
      conversationId,
      senderName,
      messageContent: messageContent.substring(0, 200) // Limit content length
    }
  });
}

export async function notifyNewPost(
  authorId: string,
  authorName: string,
  postTitle: string,
  postId: string
) {
  if (!authorId || !authorName || !postTitle || !postId) {
    console.error('Missing required fields for post notification');
    return;
  }

  // Get all users
  const usersSnapshot = await getDocs(collection(db, 'users'));
  
  // Create notification for all users
  const notificationPromises = usersSnapshot.docs.map(doc => 
    createNotification({
      type: 'new_post',
      title: 'Nouvelle publication',
      message: `${authorName} a publié "${postTitle}"`,
      recipientId: doc.id,
      data: { 
        postId,
        authorId,
        authorName,
        postTitle
      }
    })
  );

  await Promise.all(notificationPromises);
}

export async function notifyPostStatus(
  recipientId: string,
  postTitle: string,
  postId: string,
  isApproved: boolean,
  reason?: string,
  authorName?: string
) {
  if (!recipientId || !postTitle || !postId) {
    console.error('Missing required fields for post status notification');
    return;
  }

  try {
    // First notify the author
    const authorTitle = isApproved ? 'Publication approuvée' : 'Publication refusée';
    const authorMessage = isApproved
      ? `Votre publication "${postTitle}" a été approuvée`
      : `Votre publication "${postTitle}" a été refusée${reason ? `. Motif : ${reason}` : ''}`;

    await createNotification({
      type: isApproved ? 'post_approved' : 'post_rejected',
      title: authorTitle,
      message: authorMessage,
      recipientId,
      data: { 
        postId,
        postTitle,
        reason,
        status: isApproved ? 'approved' : 'rejected'
      }
    });

    // If post is approved, notify all other users
    if (isApproved) {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      
      const notificationPromises = usersSnapshot.docs
        .filter(doc => doc.id !== recipientId) // Exclude the author
        .map(doc => 
          createNotification({
            type: 'new_post',
            title: 'Nouvelle publication disponible',
            message: `Une nouvelle publication "${postTitle}" par ${authorName || 'un membre'} est maintenant disponible`,
            recipientId: doc.id,
            data: { 
              postId,
              postTitle,
              authorId: recipientId,
              authorName
            }
          })
        );

      await Promise.all(notificationPromises);
    }
  } catch (error) {
    console.error('Error sending post status notifications:', error);
  }
}

export async function notifyNewDocument(
  recipientId: string,
  uploaderName: string,
  documentName: string,
  documentId: string
) {
  if (!recipientId || !uploaderName || !documentName || !documentId) {
    console.error('Missing required fields for document notification');
    return;
  }

  await createNotification({
    type: 'document_added',
    title: 'Nouveau document ajouté',
    message: `${uploaderName} a ajouté le document "${documentName}"`,
    recipientId,
    data: { 
      documentId,
      uploaderName,
      documentName
    }
  });
}