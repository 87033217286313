import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import { clsx } from 'clsx';

interface LegalLinksProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onClose?: () => void;
  t: any;
}

export function LegalLinks({ isOpen, setIsOpen, onClose, t }: LegalLinksProps) {
  const legalLinks = [
    { path: '/legal/mentions', name: t.legal },
    { path: '/legal/cgu', name: t.terms },
    { path: '/legal/privacy', name: t.privacy },
    { path: '/legal/forum-rules', name: t.rules },
    { path: '/legal/cookies', name: t.cookies }
  ];

  return (
    <div className="px-4 py-4 border-t border-gray-200">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full text-sm text-gray-600 hover:text-gray-900"
      >
        <span>{t.legal}</span>
        <ChevronDown className={clsx(
          "h-5 w-5 transform transition-transform",
          isOpen && "rotate-180"
        )} />
      </button>
      {isOpen && (
        <div className="mt-2 space-y-1">
          {legalLinks.map((link) => (
            <Link
              key={link.path}
              to={link.path}
              onClick={onClose}
              className="block px-3 py-2 text-sm text-gray-500 hover:text-gray-900 rounded-md hover:bg-gray-50"
            >
              {link.name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}