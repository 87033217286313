import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage, db } from '../lib/firebase';
import { collection, addDoc, deleteDoc, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { notifyNewDocument } from './notifications';

export const storageService = {
  async uploadFile(file: File, language: string, category: string, folderId: string, uploaderId: string, uploaderName: string) {
    try {
      // Create unique path for file
      const path = `documents/${language}/${category}/${Date.now()}_${file.name}`;
      const storageRef = ref(storage, path);
      
      // Upload file
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Create Firestore entry
      const fileDoc = await addDoc(collection(db, 'drive_files'), {
        name: file.name,
        url: downloadURL,
        storagePath: path,
        format: file.name.split('.').pop()?.toLowerCase(),
        size: file.size,
        category,
        language,
        folderId,
        uploadedBy: uploaderId,
        uploaderName: uploaderName,
        uploadedAt: new Date()
      });

      // Notify all council members and admins
      const usersQuery = query(
        collection(db, 'users'),
        where('role', 'in', ['council', 'admin', 'superadmin'])
      );
      
      const usersSnapshot = await getDocs(usersQuery);
      const notifyPromises = usersSnapshot.docs
        .filter(doc => doc.id !== uploaderId) // Don't notify the uploader
        .map(doc => 
          notifyNewDocument(doc.id, uploaderName, file.name, fileDoc.id)
        );
      
      await Promise.all(notifyPromises);

      return fileDoc.id;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  },

  async getFileUrl(fileId: string): Promise<string> {
    try {
      const fileRef = doc(db, 'drive_files', fileId);
      const fileDoc = await getDoc(fileRef);
      
      if (!fileDoc.exists()) {
        throw new Error('File not found');
      }

      const data = fileDoc.data();
      return data.url;
    } catch (error) {
      console.error('Error getting file URL:', error);
      throw error;
    }
  },

  async deleteFile(fileId: string) {
    try {
      const fileRef = doc(db, 'drive_files', fileId);
      const fileDoc = await getDoc(fileRef);
      
      if (fileDoc.exists()) {
        const data = fileDoc.data();
        // Delete from storage
        const storageRef = ref(storage, data.storagePath);
        await deleteObject(storageRef);
        
        // Delete Firestore document
        await deleteDoc(fileRef);
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      throw error;
    }
  }
};