import React from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { X, Calendar, Tag, User } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { useTranslation } from '../hooks/useTranslation';

interface Post {
  id: string;
  title: string;
  content: string;
  type: string;
  category: string;
  authorId: string;
  authorName: string;
  createdAt: Date;
  translations?: {
    [key: string]: {
      title: string;
      content: string;
    }
  };
}

interface ViewPostModalProps {
  isOpen: boolean;
  onClose: () => void;
  post: Post;
}

export function ViewPostModal({ isOpen, onClose, post }: ViewPostModalProps) {
  const { user } = useAuthStore();
  const { getTranslatedContent } = useTranslation();
  
  if (!isOpen) return null;

  // Get translated content based on user's language preference
  const translatedContent = getTranslatedContent(post);

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" onClick={onClose} />

        <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900">
              {translatedContent.title}
            </h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="space-y-4">
            <div className="flex items-center space-x-4 text-sm text-gray-500">
              <div className="flex items-center">
                <Calendar className="h-4 w-4 mr-1" />
                {format(post.createdAt, 'dd MMMM yyyy à HH:mm', { locale: fr })}
              </div>
              <div className="flex items-center">
                <Tag className="h-4 w-4 mr-1" />
                {post.category}
              </div>
              <div className="flex items-center">
                <User className="h-4 w-4 mr-1" />
                {post.authorName}
              </div>
            </div>

            <div className="mt-2">
              <p className="text-gray-700 whitespace-pre-wrap">
                {translatedContent.content}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewPostModal;