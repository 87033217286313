import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import { ForumModeration } from './Admin/ForumModeration';
import { UserManagement } from './Admin/UserManagement';
import { Statistics } from './Admin/Statistics';
import { InvitationCodes } from './Admin/InvitationCodes';
import { PostsManagement } from './Admin/PostsManagement';
import { Settings } from './Admin/Settings';
import { SupportTickets } from './Admin/SupportTickets';
import { AdminNav } from '../components/AdminNav';

export function Admin() {
  const { isAdmin } = useAuthStore();

  return (
    <div className="space-y-6">
      <AdminNav />
      <Routes>
        <Route path="/" element={<Navigate to="forum" replace />} />
        <Route path="forum" element={<ForumModeration />} />
        <Route path="users" element={<UserManagement />} />
        <Route path="stats" element={<Statistics />} />
        <Route path="support" element={<SupportTickets />} />
        
        {/* Admin-only routes */}
        {isAdmin() && (
          <>
            <Route path="posts" element={<PostsManagement />} />
            <Route path="invitations" element={<InvitationCodes />} />
            <Route path="settings" element={<Settings />} />
          </>
        )}

        {/* Redirect to forum if trying to access restricted routes */}
        <Route path="*" element={<Navigate to="forum" replace />} />
      </Routes>
    </div>
  );
}

export default Admin;